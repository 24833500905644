import { PartialDeep } from 'type-fest';

import { createTheme, Indicator, MantineTheme } from '@mantine/core';

export const ThemedMantineIndicator: PartialDeep<MantineTheme> = createTheme({
  components: {
    Indicator: Indicator.extend({
      defaultProps: {
        color: 'coral',
      },
    }),
  },
});
