import { useState } from 'react';
import { GetTemplatesParams, PlanType } from 'api/models';
import { RECOVER_ACTION_TYPE } from 'app/Private/Templates/EditDebtCollectionCustomization/EditDebtCollectionCustomization';
import RecoverAction from 'app/Private/Templates/EditDebtCollectionCustomization/RecoverAction';
import TemplateList from 'app/Private/Templates/TemplateList';
import TemplateFilters from 'app/Private/Templates/TemplateList/TemplateFilters';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { IconName } from 'shared/components/Icon';
import Tabs from 'shared/components/Tabs';
import FiltersSideMenu from 'shared/forms/FiltersSideMenu';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { settingsActions } from 'store/settings/settings.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';

import styleIdentifiers from './TemplatesSettings.module.scss';
import { ActionIcon, Group, Text, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

const styles = classNames.bind(styleIdentifiers);

export const TemplatesSettings = () => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const history = useHistory();

  const canAddTemplate = company.package.can_create_template;

  const [filters, setFilters] = useState<GetTemplatesParams>({});

  const tabItems = [
    {
      title: t(i18nKeys.BATCH.CATEGORIES.RECOVERY),
      planType: PlanType.recovery,
      children: <TemplateList key={0} plan_type={PlanType.recovery} filters={filters} />,
    },
    {
      title: t(i18nKeys.PAYMENT_PLAN.TITLE.ONE),
      planType: PlanType.payment,
      children: <TemplateList key={1} plan_type={PlanType.payment} filters={filters} />,
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);

  const addDocument = (planType) => {
    sideMenuShow({
      unmount: true,
      content: (
        <RecoverAction
          planType={planType}
          onSelectAction={(step_type: RECOVER_ACTION_TYPE, with_fees) => {
            settingsActions.addTemplate({
              data: {
                name: t(i18nKeys.NEW_TEMPLATE),
                step_type,
                plan_type: planType,
                with_fees,
              },
              callback: ({ data }) => {
                sideMenuHide();
                history.push(`/settings/document-customization/edit/${data.id}`);
              },
            });
          }}
        />
      ),
    });
  };

  const openFilterForm = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <FiltersSideMenu
          onRemoveFilter={() => {
            setFilters({});
          }}
        >
          <TemplateFilters
            filterObject={{}}
            initialValues={filters}
            onSubmit={({ name, step_type }: GetTemplatesParams) => {
              setFilters({
                ...(name ? { name } : null),
                ...(step_type ? { step_type } : null),
              });
            }}
          />
        </FiltersSideMenu>
      ),
    });
  };

  return (
    <div className={styles('templates')}>
      <PageTitle>
        {t(i18nKeys.DOCUMENT_CUSTOMIZATION)}
        <PageTitle.Actions>
          <Button
            className={styles('filter')}
            label={t(i18nKeys.FILTER)}
            noMargin
            small
            iconSize="16px"
            color={ButtonColor.WHITE}
            iconLeft={IconName.FILTER}
            onClick={openFilterForm}
          />
        </PageTitle.Actions>
      </PageTitle>
      <Tabs
        className={styles('templates__tabs')}
        tabIndex={tabIndex}
        items={tabItems.map((e, i) => (
          <div className={styles('tab-item')}>
            <Title order={4}>{e.title}</Title>
            {tabIndex === i && (
              <Group>
                <Text>{!canAddTemplate && t(i18nKeys.NOT_INCLUDED)}</Text>
                <ActionIcon disabled={!canAddTemplate} onClick={() => canAddTemplate && addDocument(e.planType)}>
                  <IconPlus />
                </ActionIcon>
              </Group>
            )}
          </div>
        ))}
        noBody
        onChange={setTabIndex}
      />
      {tabItems[tabIndex].children}
    </div>
  );
};
