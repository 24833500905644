import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';

import { css } from '@emotion/css';
import { Avatar, Button, MantineTheme, Space, Text, useMantineTheme } from '@mantine/core';

const styles = {
  profileButtonRoot: (isOpen: boolean, theme: MantineTheme) => css`
    background-color: ${isOpen ? `${theme.colors.purple[1]}` : 'transparent'} !important;
    border-color: ${isOpen ? `${theme.colors.purple[2]}` : 'transparent'};
    color: ${theme.colors.gray[8]} !important;
    transition: background-color 100ms ease;

    &:hover {
      background-color: ${theme.colors.purple[1]} !important;
    }
  `,
  label: css`
    font-family: 'DM Sans';
  `,
};

interface ProfileButtonProps extends ComponentPropsWithoutRef<'button'> {
  isOpened: boolean;
}

export const ProfileButton = forwardRef<HTMLButtonElement, ProfileButtonProps>(
  ({ isOpened, ...others }: ProfileButtonProps, ref) => {
    const { company } = useLoadCompanyConfiguration();
    const profile = useProfile();
    const theme = useMantineTheme();

    return (
      <Button
        pl="space1"
        justify="space-between"
        w="100%"
        ref={ref}
        radius={8}
        classNames={{ root: styles.profileButtonRoot(isOpened, theme) }}
        {...others}
      >
        <Avatar
          size="sm"
          radius={6}
          src={company?.invoice_customization.logo_url}
          name={company?.name}
        />
        <Space w="space3" />
        <Text
          classNames={{
            root: styles.label,
          }}
          c="gray.10"
        >
          {profile.name.full}
        </Text>
      </Button>
    );
  },
);
