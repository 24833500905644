import Amount from 'shared/components/Amount';
import { DebtorBalancePayment } from 'shared/hooks';

import { Divider, Text } from '@mantine/core';

import { PaymentLabel } from './Labels';

interface PaymentRowProps {
  payment: DebtorBalancePayment;
}

export const PaymentRow = ({ payment }: PaymentRowProps) => {
  const { communication, issueDate, remainingBalance } = payment;

  return (
    <>
      <tr>
        <td>
          <PaymentLabel />
        </td>
        <td>
          <Text fz="0.9rem" ta="left" flex={1}>
            {communication}
          </Text>
        </td>
        <td>
          <Text fz="0.9rem">{status || '-'}</Text>
        </td>
        <td style={{ minWidth: '100px' }}>
          <Text fz="0.9rem"> {issueDate ? issueDate.format('DD/MM/YY') : '-'}</Text>
        </td>
        <td style={{ minWidth: '100px' }} />
        <td>
          <Text fz="0.9rem" c="purple" />
        </td>
        <td>
          <Text fz="0.9rem" c="red">
            <Amount value={remainingBalance} />
          </Text>
        </td>
        <td>
          <Text fz="0.9rem">
            <Amount value={remainingBalance * -1} />
          </Text>
        </td>
      </tr>
      <tr>
        <td colSpan={8} style={{ padding: '0' }}>
          <Divider p={0} />
        </td>
      </tr>
    </>
  );
};
