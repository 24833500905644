import { useMemo, useState } from 'react';
import { ExportDebtorCsv } from 'app/Private/Clients';
import { i18nKeys, useTranslation } from 'locales';
import { cloneDeep, mergeWith, subtract } from 'lodash-es';
import { Link } from 'react-router-dom';
import { chunk, isDefined, times } from 'remeda';
import { AgedBalance, Balance, useAgedBalanceColors, useProfile } from 'shared/hooks';
import { formatAmount } from 'shared/utils';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import {
  Anchor,
  Button,
  Card,
  Group,
  LoadingOverlay,
  Pagination,
  Skeleton,
  Table,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronDown, IconTableExport } from '@tabler/icons-react';

function computeNetAgedBalancePerDebtor(agedBalance: AgedBalance['data']) {
  return agedBalance.map(({ debits, credits, debtorId, debtorName }) => {
    const merged = mergeWith(cloneDeep(debits), credits, subtract);
    return {
      debtorId,
      debtorName,
      ...merged,
      totalBalance: Object.values(merged).reduce((acc, curr) => acc + curr, 0),
    };
  });
}

function formatAmountWithColor(value, color) {
  if (value === 0) return formatAmount(value, '.', ' ', 0);

  return (
    <Text fw={600} c={color}>
      {formatAmount(value, '.', ' ')}
    </Text>
  );
}

function isKeyOfBalance(input: string): input is keyof Balance {
  return ['0', '30', '60', '90', 'totalBalance', 'notDue'].includes(input);
}

interface HeadCellProps {
  columnKey: string;
  onClick?: (columnName: keyof Balance) => void;
  isSelected?: boolean;
}

const HeadCell = ({ columnKey, onClick, isSelected = false }: HeadCellProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const isClickable = isKeyOfBalance(columnKey) && onClick != null;

  return (
    <Group
      pb={6}
      gap={4}
      onClick={isClickable ? () => onClick(columnKey) : () => {}}
      style={{ cursor: isClickable ? 'pointer' : undefined, userSelect: 'none' }}
    >
      <Text
        fz={16}
        fw={isSelected ? 900 : 600}
        c={isSelected ? theme.colors.purple[5] : theme.colors.gray[7]}
      >
        {t(i18nKeys.ANALYTICS.AGED_BALANCE[columnKey])}
      </Text>
      {isClickable ? (
        <IconChevronDown
          size={15}
          stroke={isSelected ? 2 : 1.5}
          color={isSelected ? theme.colors.purple[4] : theme.colors.gray[7]}
        />
      ) : null}
    </Group>
  );
};

const skeletonRow = () => [
  <Skeleton height={20} w="80%" />,
  <Skeleton height={20} w="80%" />,
  <Skeleton height={20} w="80%" />,
  <Skeleton height={20} w="80%" />,
  <Skeleton height={20} w="80%" />,
  <Skeleton height={20} w="80%" />,
];

interface AgedBalanceTableProps {
  agedBalance?: AgedBalance;
  isLoading: boolean;
}

export const AgedBalanceTable = ({ agedBalance, isLoading }: AgedBalanceTableProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const colors = useAgedBalanceColors();
  const {
    preferences: { itemsPerPage },
  } = useProfile();

  const [pageIndex, setPageIndex] = useState(1);
  const [sortingColumn, setSortingColumn] = useState<keyof Balance | 'totalBalance'>(
    'totalBalance',
  );

  const loadingRows = times(7, () => skeletonRow());

  const chunkedRows = useMemo(() => {
    if (isLoading && !isDefined(agedBalance)) return [];

    const netAgedBalancePerDebtor = isDefined(agedBalance)
      ? computeNetAgedBalancePerDebtor(agedBalance.data)
      : [];

    const sorted = netAgedBalancePerDebtor
      .slice()
      .sort((a, b) => b[sortingColumn] - a[sortingColumn]);

    // Cant't destructure bare numbers used as keys
    const netAgedBalancePerDebtorRows = sorted.map(
      ({
        debtorName,
        debtorId,
        0: zero,
        30: thirty,
        60: sixty,
        90: ninety,
        totalBalance,
        notDue,
      }) => [
        <Anchor
          underline="always"
          c={theme.colors.gray[9]}
          fw={400}
          component={Link}
          to={`/clients/${debtorId}/balance`}
        >
          {debtorName}
        </Anchor>,
        formatAmountWithColor(notDue, theme.colors.gray[9]),
        formatAmountWithColor(zero, colors[0]),
        formatAmountWithColor(thirty, colors[30]),
        formatAmountWithColor(sixty, colors[60]),
        formatAmountWithColor(ninety, colors[90]),
        formatAmountWithColor(totalBalance, theme.colors.gray[9]),
      ],
    );

    return chunk(netAgedBalancePerDebtorRows, itemsPerPage);
  }, [agedBalance, itemsPerPage, sortingColumn, theme.colors.gray, isLoading, colors]);

  const isPaginated = chunkedRows.length > 1;

  const onClickColumnHead = (columnName: keyof Balance) => {
    setSortingColumn(columnName);
    setPageIndex(1);
  };

  const handleExport = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.EXPORT),
      children: (
        <ExportDebtorCsv
          overrideDefaultSelection={[
            'aged_balance_not_due',
            'aged_balance_within_30',
            'aged_balance_between_30_and_60',
            'aged_balance_between_60_and_90',
            'aged_balance_above_90',
            'debtor_remaining_balance',
            'full_name',
          ]}
        />
      ),
    });
  };

  return (
    <Card radius="md" shadow="sm" mt="md">
      <Card.Section inheritPadding withBorder py="xs" mb="md">
        <LoadingOverlay visible={isLoading && isDefined(agedBalance)} />
        <Group justify="space-between">
          <Title order={4}>{t(i18nKeys.DETAILS)}</Title>

          <Button
            onClick={handleExport}
            variant="light"
            size="xs"
            leftSection={<IconTableExport stroke="1.5" size={14} />}
          >
            {t(i18nKeys.EXPORT)}
          </Button>
        </Group>
      </Card.Section>
      <Table
        c={theme.colors.gray[6]}
        striped
        data={{
          head: [
            <HeadCell columnKey="DEBTOR" />,
            ...['notDue', '0', '30', '60', '90', 'totalBalance'].map((key) => (
              <HeadCell
                columnKey={key}
                isSelected={key === sortingColumn}
                onClick={onClickColumnHead}
              />
            )),
          ],
          // todo: Better distinguish between loading (initial load) and fetching (view was changed)
          body: isLoading && !isDefined(agedBalance) ? loadingRows : chunkedRows[pageIndex - 1],
        }}
      />
      {isPaginated && (
        <Card.Section withBorder inheritPadding pb="md">
          <Group w="100%" justify="flex-end" mt="md">
            <Pagination
              size="sm"
              total={chunkedRows.length}
              value={pageIndex}
              onChange={setPageIndex}
            />
          </Group>
        </Card.Section>
      )}
    </Card>
  );
};
