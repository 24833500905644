import classNames from 'classnames/bind';

import Logo_ from './assets/logo.svg';
import LogoFull from './assets/logo-full.svg';
import LogoFullVertical from './assets/logo-full-vertical.svg';

import styleIdentifiers from './logo.module.scss';

type LogoVariants = 'logo' | 'logoFull' | 'logoFullVertical';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  variant?: LogoVariants;
  width?: string | number;
  height?: string | number;
};

const logoImg: Record<LogoVariants, any> = {
  logo: Logo_,
  logoFull: LogoFull,
  logoFullVertical: LogoFullVertical,
};

export default function Logo({ variant = 'logo', width, height }: Props) {
  const style = {
    width,
    height,
  };

  return <img src={logoImg[variant]} style={style} alt="recovr logo" />;
}
