import { Pagination } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconName } from 'shared/components/Icon';
import { Dropdown } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';

import { Box, Group, Pagination as MantinePagination, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

import styleIdentifiers from './TableFooter.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  pagination: Pagination;
  itemsLength: number;
  className?: string;
  noShadow?: boolean;
  noPageLimit?: boolean;
  currentPage?: number;
  currentPageLimit?: number;
  setPage: (selectedItem: { selected: number }) => void;
  setPageLimit?: Function;
};

export default function TableFooter({
  pagination,
  itemsLength,
  className,
  noShadow,
  noPageLimit,
  currentPage,
  currentPageLimit,
  setPage,
  setPageLimit,
}: Props) {
  const { t } = useTranslation();

  const PAGES_LIMITS = [10, 20, 50, 100];

  const { total_objects, page_limit, current_page, last_page } = pagination ?? {};

  const pageCurrent = currentPage || current_page;
  const pageLimit = currentPageLimit || page_limit;

  const isLastPage = last_page === pageCurrent;

  const hasPagination = last_page > 1;

  return (
    <div className={styles('table-footer', noShadow && 'no-shadow', className)}>
      <div>
        <Text size="sm" pr="space2">
          {`${isLastPage ? total_objects - itemsLength + 1 : (pageCurrent - 1) * pageLimit + 1} - ${
            isLastPage ? total_objects : pageCurrent * pageLimit
          } ${t(i18nKeys.OF)} ${total_objects}`}
        </Text>
        {!noPageLimit && setPageLimit && (
          <Box mt={-2}>
            <Dropdown
              direction={DropdownDirection.TOP}
              selectorContent={
                <Group gap={0}>
                  <Text size="sm" c="dimmed">
                    {pageLimit} {t(i18nKeys.PER_PAGE)}
                  </Text>
                  <IconChevronDown stroke={1} />
                </Group>
              }
              toggleContent
            >
              {PAGES_LIMITS.map((limit) => (
                <div key={`limit${limit}`} className="dropdown-item" onClick={setPageLimit(limit)}>
                  {limit}
                </div>
              ))}
            </Dropdown>
          </Box>
        )}
      </div>

      {hasPagination && (
        <MantinePagination
          total={last_page}
          onChange={(selected) => setPage({ selected: selected - 1 })}
          value={pageCurrent}
          size="sm"
        />
      )}
    </div>
  );
}
