import React from 'react';
import { LightUser } from 'shared/hooks';

import { Avatar } from '@mantine/core';

type UserAvatarProps = {
  user: LightUser;
};

export function UserAvatar({ user }: UserAvatarProps) {
  if (user.profilePicture != null) {
    return <Avatar src={user.profilePicture} alt={user.name.full} />;
  }

  return <Avatar>{user.name.initials}</Avatar>;
}
