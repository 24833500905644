import { useEffect, useMemo } from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { DebtorBalanceCreditNote } from 'shared/hooks';
import { formattedDate } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { ActionIcon, Divider, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconExternalLink, IconMinus, IconPlus } from '@tabler/icons-react';

import { CreditNoteLabel } from './Labels';

interface CreditNoteRowProps {
  creditNote: DebtorBalanceCreditNote;
  isForceUnfoldActive: boolean;
}

export const CreditNoteRow = ({ creditNote, isForceUnfoldActive }: CreditNoteRowProps) => {
  const { t, currentLang } = useTranslation();
  const [isUnfolded, foldHandlers] = useDisclosure(false);

  // FoldHandlers is not stable and would cause the effect to run too often
  const { open, close } = foldHandlers;
  const foldHandlersMemo = useMemo(
    () => ({
      open,
      close,
    }),
    [open, close],
  );

  useEffect(() => {
    if (isForceUnfoldActive) foldHandlersMemo.open();
    else foldHandlersMemo.close();
  }, [isForceUnfoldActive, foldHandlersMemo]);

  const {
    id,
    reference,
    status,
    issueDate,
    dueDate,
    totalTvac,
    remainingBalance,
    invoices,
    payments,
  } = creditNote;

  const Icon = isUnfolded ? IconMinus : IconPlus;

  const openCreditNote = () => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail id={id} isCreditNote modal />,
    });
  };

  return (
    <>
      <tr>
        <td>
          <CreditNoteLabel />
        </td>
        <td onClick={foldHandlers.toggle} style={{ cursor: 'pointer' }}>
          <Group gap={0}>
            <ActionIcon variant="transparent" size={20}>
              <Icon color="black" stroke={1.5} />
            </ActionIcon>
            <Text ml="xs" fz="0.9rem" ta="left" flex={1}>
              {reference}
            </Text>
            <ActionIcon variant="transparent" size={20} onClick={openCreditNote} color="purple">
              <IconExternalLink stroke={1.5} />
            </ActionIcon>
          </Group>
        </td>
        <td>
          <Text fz="0.9rem">{status || '-'}</Text>
        </td>
        <td style={{ minWidth: '100px' }}>
          <Text fz="0.9rem"> {issueDate ? issueDate.format('DD/MM/YY') : '-'}</Text>
        </td>
        <td style={{ minWidth: '100px' }}>
          <Text fz="0.9rem">{dueDate ? formattedDate(dueDate, currentLang, 'DD/MM/YY') : '-'}</Text>
        </td>
        <td>
          <Text fz="0.9rem" c="purple">
            <Amount value={(totalTvac - remainingBalance) * -1} />
          </Text>
        </td>
        <td>
          <Text fz="0.9rem" c="red">
            <Amount value={totalTvac * -1} />
          </Text>
        </td>
        <td>
          <Text fz="0.9rem">
            <Amount value={remainingBalance} />
          </Text>
        </td>
      </tr>
      {isUnfolded && (
        <>
          <tr style={{ border: 'none' }}>
            <td />
            <td>
              <Text ml={20} fz="0.9rem">
                {t(i18nKeys.AMOUNT_CREDITED)}
              </Text>
            </td>
            <td colSpan={4} />
            <td>
              <Text fz="0.9rem" c="red">
                <Amount value={totalTvac * -1} />
              </Text>
            </td>
          </tr>
          {invoices.map((invoice) => (
            <tr key={invoice.reference} style={{ border: 'none' }}>
              <td />
              <td>
                <Text ml={20} fz="0.9rem">
                  {t(i18nKeys.INVOICE.LINK_INVOICE)} - {invoice.reference}
                </Text>
              </td>
              <td colSpan={1} />
              <td>
                <Text fz="0.9rem">{formattedDate(invoice.issueDate, currentLang, 'DD/MM/YY')}</Text>
              </td>
              <td />
              <td>
                <Text fz="0.9rem" c="purple">
                  <Amount value={invoice.amount} />
                </Text>
              </td>
            </tr>
          ))}
          {payments?.map((payment) => (
            <tr key={payment.amount} style={{ border: 'none' }}>
              <td />
              <td>
                <Text ml={20} fz="0.9rem">
                  {t(i18nKeys.LINKED_PAYMENT)} - {payment.communication}
                </Text>
              </td>
              <td colSpan={1} />
              <td>
                <Text fz="0.9rem">
                  {payment.issueDate?.format('DD/MM/YY') ?? t(i18nKeys.MISSING_DATA)}
                </Text>
              </td>
              <td />
              <td>
                <Text fz="0.9rem" c="purple">
                  <Amount value={payment.amount * -1} />
                </Text>
              </td>
              <td />
            </tr>
          ))}
        </>
      )}
      <tr>
        <td colSpan={8} style={{ padding: '0' }}>
          <Divider p={0} />
        </td>
      </tr>
    </>
  );
};
