import { PartialDeep } from 'type-fest';

import { createTheme, MantineTheme, Table } from '@mantine/core';

export const ThemedMantineTable: PartialDeep<MantineTheme> = createTheme({
  components: {
    Table: Table.extend({
      styles: {
        td: {
          verticalAlign: 'middle', // Our reset sets this to baseline, which causes misalignment in tables
        },
      },
    }),
  },
});
