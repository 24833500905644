import React from 'react';
import { LightUser } from 'shared/hooks';

import { Box, Group, Stack, Text } from '@mantine/core';

import { UserAvatar } from './UserAvatar';

type Props = {
  inline: boolean;
  // optional because customSelect is broken
  item?: LightUser;
  withPicture?: boolean;
};

export function UserInfo({ inline, item, withPicture }: Props) {
  if (item == null) return null;

  return (
    <Group>
      {withPicture && item != null && (
        <Box mr={12}>
          <UserAvatar user={item} />
        </Box>
      )}
      <Stack gap={2}>
        <Text fz={14} fw={600}>
          {item.name.full}
        </Text>
        {!inline && (
          <Text fz={12} c="dimmed">
            {item.email}
          </Text>
        )}
      </Stack>
    </Group>
  );
}
