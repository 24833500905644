import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, InputFile, Textarea } from 'shared/io';
import { OnClickFunction } from 'types/html-type';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './CommentForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CommentFormProps = {
  label?: string;
  oneLine?: boolean;
  labelText?: string;
  rows?: number;
  dismiss?: OnClickFunction;
  dismissLabel?: string;
  onSubmit: onSubmitFunction;
  withFile?: boolean;
};

export const CommentForm = ({
  onSubmit,
  label,
  oneLine,
  labelText,
  rows,
  dismiss,
  dismissLabel,
  withFile,
}: CommentFormProps) => {
  const { t } = useTranslation();

  const form = useForm();

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = form;

  const [file, setFile] = useState<any | undefined>();

  const submit = (values) => {
    onSubmit({ ...values, attachment: file });
    setValue('comment', '');
    setFile(undefined);
  };

  const onAddFile = (newFile: any) => {
    setFile(newFile);
    return newFile;
  };

  const removeIcon = () => {
    setFile(undefined);
  };

  if (dismiss) {
    return (
      <form
        className={styles('CommentForm', oneLine && 'one-line')}
        onSubmit={handleSubmit(submit)}
      >
        <Textarea
          register={register('comment', { required: true })}
          error={get(errors, 'comment')}
          label={labelText}
          className="border"
          rows={rows || 3}
          withBorder
          oneLine={oneLine}
        />
        <div className={styles('buttons-wrapper')}>
          <Button
            label={dismissLabel || t(i18nKeys.CANCEL)}
            color={ButtonColor.TRANSPARENT}
            onClick={dismiss}
          />
          <Button label={label} type="submit" />
        </div>
      </form>
    );
  }

  if (withFile) {
    return (
      <form
        className={styles('CommentForm', oneLine && 'one-line')}
        onSubmit={handleSubmit(submit)}
      >
        <Textarea
          register={register('comment', { required: true })}
          error={get(errors, 'comment')}
          label={labelText}
          className="border"
          rows={rows || 3}
          withBorder
          oneLine={oneLine}
        />
        <div className={styles('comment-actions')}>
          <span>
            {file && (
              <span className={styles('file')}>
                <Icon name={IconName.FILE} />
                <span>{file!.name}</span>
                <Icon
                  name={IconName.SIMPLE_REMOVE}
                  className={styles('remove-icon')}
                  onClick={removeIcon}
                />
              </span>
            )}
          </span>
          <div className={styles('buttons-container')}>
            <InputFile
              accept=""
              onChange={onAddFile}
              color={ButtonColor.WHITE}
              name="attachment"
              label={t(i18nKeys.ADD_FILE)}
            />
            <Button label={label || t(i18nKeys.FORM.ADD_COMMENT)} type="submit" noMargin />
          </div>
        </div>
      </form>
    );
  }

  return (
    <form className={styles('CommentForm', oneLine && 'one-line')} onSubmit={handleSubmit(submit)}>
      <Textarea
        register={register('comment', { required: true })}
        error={get(errors, 'comment')}
        label={labelText}
        className="border"
        rows={rows || 3}
        withBorder
        oneLine={oneLine}
      />
      <Button
        label={label || t(i18nKeys.FORM.ADD_COMMENT)}
        type="submit"
        color={ButtonColor.GREY}
      />
    </form>
  );
};
