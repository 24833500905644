import { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { useIsMobile } from 'shared/hooks/utils';

import { useAuth0 } from '@auth0/auth0-react';
import { css } from '@emotion/css';
import { MantineTheme, Menu, Text, useMantineTheme } from '@mantine/core';
import { IconLogout, IconPencilMinus } from '@tabler/icons-react';

import { ProfileButton } from './ProfileButton';
import { TenantSwitcher } from './TenantSwitcher';

const dropdownStyles = (theme: MantineTheme) => ({
  dropdown: {
    borderRadius: 8,
    borderColor: theme.colors.gray[2],
    borderWidth: 1,
    marginLeft: theme.spacing.space5,
    boxShadow: '4px 8px 12px -2px rgba(0, 0, 0, 0.12)',
  },
});

const hover = (theme: MantineTheme) => css`
  &:hover {
    background-color: ${theme.colors.purple[1]} !important;
  }
`;

export default function UserMenuButton({}) {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const theme = useMantineTheme();

  const { logout } = useAuth0();

  const [isOpened, setIsOpened] = useState(false);

  return (
    <Menu
      shadow="md"
      position={isMobile ? 'top' : 'right-end'}
      opened={isOpened}
      onChange={setIsOpened}
      classNames={{ item: hover(theme) }}
    >
      <Menu.Target>
        <ProfileButton isOpened={isOpened} />
      </Menu.Target>
      <Menu.Dropdown w={300} styles={dropdownStyles(theme)}>
        <Menu.Label>{t(i18nKeys.HEADER.USER_ACTIONS.SPACES)}</Menu.Label>
        <TenantSwitcher />
        <Menu.Divider c="purple.1" />
        <Menu.Item
          onClick={() => history.push('/settings/user')}
          leftSection={<IconPencilMinus stroke="1.5" size={16} color={theme.colors.gray[7]} />}
        >
          <Text>{t(i18nKeys.HEADER.USER_ACTIONS.EDIT_PROFILE)}</Text>
        </Menu.Item>
        <Menu.Item
          onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })}
          leftSection={<IconLogout stroke="1.5" size={16} />}
          color={theme.colors.error[5]}
        >
          <Text>{t(i18nKeys.HEADER.USER_ACTIONS.LOGOUT)}</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
