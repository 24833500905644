import { useEffect } from 'react';
import { useCreateWorkflow, useDeleteWorkflow, useGetWorkflow, useUpdateWorkflow } from 'api';
import { CreateWorkflowBody, PlanType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useRouteMatch } from 'react-router';

import { LoadingOverlay } from '@mantine/core';

import { WorkflowForm } from './WorkflowForm';
import { formatValues, getDefaultValues, removeStepId } from './WorkflowForm.utils';

import styleIdentifiers from './WorkflowForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const WorkflowFormEdit = () => {
  const {
    params: { planType, id },
  } = useRouteMatch<{ planType: PlanType; id: string }>();
  const history = useHistory();

  const { t } = useTranslation();

  const {
    data: workflowResponse,
    isLoading,
    isError,
    refetch,
  } = useGetWorkflow(id, { query: { enabled: false } });

  const { mutate: updateWorkflow, isPending: isLoadingUpdate } = useUpdateWorkflow();
  const { mutate: createWorkflow, isPending: isLoadingCreate } = useCreateWorkflow();
  const { mutate: deleteWorkflow, isPending: isLoadingDelete } = useDeleteWorkflow();

  const isProcessing = isLoadingUpdate || isLoadingCreate || isLoadingDelete;

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  if (isLoading && !workflowResponse) {
    return <LoadingOverlay visible />;
  }

  if (isError || !workflowResponse) {
    return <span>Error</span>;
  }

  const { data: workflow } = workflowResponse;

  const onSubmit = (data: CreateWorkflowBody) =>
    updateWorkflow(
      { id, data: formatValues(data) },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );

  const onDuplicate = () => {
    const { name, plan_type, before_due_date_steps, after_due_date_steps } = formatValues({
      ...getDefaultValues(planType, workflow),
    });

    createWorkflow(
      {
        data: {
          name: `${name}_${t(i18nKeys.COPY).toLowerCase()}`,
          plan_type: plan_type || planType,
          before_due_date_steps: before_due_date_steps.map(removeStepId),
          after_due_date_steps: after_due_date_steps.map(removeStepId),
        },
      },
      {
        onSuccess: (response) => {
          history.push(`/settings/workflows/${planType}/edit/${response.data.id}`);
        },
      },
    );
  };

  const onDelete = () =>
    deleteWorkflow(
      { id },
      {
        onSuccess: () => {
          history.push(`/settings/workflows/${planType}`);
        },
      },
    );

  return (
    <div className={styles('workflows-edit')}>
      <WorkflowForm
        planType={planType}
        onSubmit={onSubmit}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        workflow={workflow}
        isLoading={isProcessing}
      />
    </div>
  );
};
