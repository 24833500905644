import { ActionIcon, Center, Space, Text } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';

interface FilterItemProps {
  label: string;
  value: string;
  onClick: VoidFunction;
  onClickDelete: VoidFunction;
}

export const FilterItem = ({ label, value, onClick, onClickDelete }: FilterItemProps) => (
  <Center
    bg="purple.1"
    py="space1"
    px="space2"
    onClick={onClick}
    style={(theme) => ({
      borderRadius: theme.spacing.space2,
      userSelect: 'none',
      cursor: 'pointer',
    })}
  >
    <Text size="sm" c="purple.5">{`${upperFirst(label)}:`}</Text>
    <Space w="space1" />
    <Text size="sm" c="purple.6" fw={500}>
      {upperFirst(value)}
    </Text>
    <Space w="space2" />
    <ActionIcon
      onClick={(e: Event) => {
        e.stopPropagation();
        onClickDelete();
      }}
      color="purple.3"
      variant="transparent"
      size="space5"
    >
      <IconX stroke={2} size={16} />
    </ActionIcon>
  </Center>
);
