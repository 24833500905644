import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect, Input, SwitchInput } from 'shared/io';

import {
  CGV,
  InterestCalculationType,
  SalePreferencesCGVFieldsName,
  SalePreferencesFields,
} from '../PreferencesForm.constants';

import {
  getCalculationTypeOptions,
  getDefaultInterest,
  getFieldErrors,
  getInterestCalculationTypeOptions,
} from './SalePreferencesPartialForm.utils';

import styleIdentifiers from './SalePreferencesPartialForm.module.scss';
import { Title } from '@mantine/core';

const styles = classNames.bind(styleIdentifiers);
type Props = {
  parent: number;
};

export const SalePreferencesPartialFormItems = ({ parent }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<SalePreferencesFields>();

  const values = watch(`${CGV}.${parent}`);

  const { fix_fee, interest, penalty_clause } = values;

  const deleteProp = (el: SalePreferencesCGVFieldsName) => {
    setValue(`${CGV}.${parent}`, omit(values, el));
  };

  const fieldErrors = getFieldErrors(errors, parent);

  const onSwitchChange = (
    target:
      | SalePreferencesCGVFieldsName.INTEREST
      | SalePreferencesCGVFieldsName.FIX_FEE
      | SalePreferencesCGVFieldsName.PENALTY_CLAUSE,
    value: boolean,
  ) => {
    if (value) {
      setValue(`${CGV}.${parent}.${target}`, getDefaultInterest(), {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      deleteProp(target);
    }
  };

  const isPenaltyClausePercentage = penalty_clause?.type === InterestCalculationType.PERCENTAGE;

  return (
    <div className={styles('sale-conditions-partial-form-fee')}>
      <Title order={5} mt="md">{t(i18nKeys.LATE_FEES)}</Title>
      <div className={styles('grid-row', 'space')}>
        <div className={styles('col-3')}>
          <SwitchInput
            className={styles('switch')}
            label={t(i18nKeys.FORM.PREFERENCES.INTEREST)}
            value={!!interest}
            onChange={(newValue: boolean) => {
              onSwitchChange(SalePreferencesCGVFieldsName.INTEREST, newValue);
            }}
          />
        </div>
        {interest && (
          <>
            <div className={styles('col-3')}>
              <Controller
                name={`${CGV}.${parent}.${SalePreferencesCGVFieldsName.INTEREST_PERIOD}`}
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name={`${CGV}.${parent}.${SalePreferencesCGVFieldsName.INTEREST_PERIOD}`}
                    label={t(i18nKeys.FORM.PREFERENCES.PERIODICITY)}
                    items={getCalculationTypeOptions(t)}
                    keyText="description"
                    keyValue="value"
                    canUnselect={false}
                    noMargin
                  />
                )}
              />
            </div>
            <div className={styles('col-3')}>
              <Input
                type="number"
                min="0"
                max="100"
                label={t(i18nKeys.FORM.PAYMENT_DELAY.INTEREST_RATE)}
                errorMessage={fieldErrors?.interest?.value}
                noMargin
                register={register(
                  `${CGV}.${parent}.${SalePreferencesCGVFieldsName.INTEREST_VALUE}`,
                  {
                    required: true,
                    min: 0,
                    max: 100,
                  },
                )}
              />
            </div>
          </>
        )}
      </div>
      <div className={styles('grid-row', 'space')}>
        <div className={styles('col-3')}>
          <SwitchInput
            label={t(i18nKeys.FORM.PREFERENCES.FIX_FEE)}
            value={!!fix_fee}
            onChange={(value: boolean) =>
              onSwitchChange(SalePreferencesCGVFieldsName.FIX_FEE, value)
            }
          />
        </div>
        {fix_fee && (
          <div className={styles('col-3')}>
            <Input
              type="number"
              min="0"
              step="0.01"
              label={t(i18nKeys.FORM.PREFERENCES.AMOUNT_EURO)}
              errorMessage={fieldErrors?.fix_fee?.value}
              noMargin
              register={register(`${CGV}.${parent}.${SalePreferencesCGVFieldsName.FIX_FEE_VALUE}`, {
                required: true,
                min: 0,
              })}
            />
          </div>
        )}
      </div>
      <div className={styles('grid-row')}>
        <div className={styles('col-3')}>
          <SwitchInput
            value={!!penalty_clause}
            label={t(i18nKeys.PENALTY_CLAUSE)}
            onChange={(newValue: boolean) => {
              onSwitchChange(SalePreferencesCGVFieldsName.PENALTY_CLAUSE, newValue);
            }}
          />
        </div>
        {penalty_clause && (
          <>
            <div className={styles('col-3')}>
              <Controller
                name={`${CGV}.${parent}.${SalePreferencesCGVFieldsName.PENALTY_CLAUSE_TYPE}`}
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name={`${CGV}.${parent}.${SalePreferencesCGVFieldsName.PENALTY_CLAUSE_TYPE}`}
                    label={t(i18nKeys.FORM.PREFERENCES.CLAUSE_TYPE)}
                    items={getInterestCalculationTypeOptions(t)}
                    keyText="description"
                    keyValue="value"
                    canUnselect={false}
                    noMargin
                  />
                )}
              />
            </div>
            {isPenaltyClausePercentage && (
              <div className={styles('col-3')}>
                <Input
                  type="number"
                  min="0"
                  step="0.1"
                  label={t(i18nKeys.FORM.PREFERENCES.PENALTY_CLAUSE_MIN, {
                    text: '€',
                  })}
                  errorMessage={fieldErrors?.penalty_clause?.minimum}
                  noMargin
                  register={register(
                    `${CGV}.${parent}.${SalePreferencesCGVFieldsName.PENALTY_CLAUSE_MINIMUM}`,
                    {
                      required: true,
                      min: 0,
                    },
                  )}
                />
              </div>
            )}
            <div className={styles('col-3')}>
              <Input
                type="number"
                min="0"
                step="0.1"
                max={isPenaltyClausePercentage ? '100' : undefined}
                label={t(i18nKeys.FORM.PREFERENCES.PENALTY_CLAUSE_VALUE, {
                  text: isPenaltyClausePercentage ? '%' : '€',
                })}
                errorMessage={fieldErrors?.penalty_clause?.value}
                noMargin
                register={register(
                  `${CGV}.${parent}.${SalePreferencesCGVFieldsName.PENALTY_CLAUSE_VALUE}`,
                  {
                    required: true,
                    min: 0,
                    max: isPenaltyClausePercentage ? 100 : undefined,
                  },
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
