import { PartialDeep } from 'type-fest';

import { css } from '@emotion/css';
import { createTheme, MantineTheme, NavLink } from '@mantine/core';

export const styles = {
  navLinkHover: (hoverColor: string) => css`
    &:hover {
      background-color: ${hoverColor} !important;
    }
  `,
};

export const ThemedMantineNavLink: PartialDeep<MantineTheme> = createTheme({
  components: {
    NavLink: NavLink.extend({
      styles: (theme, props) => ({
        root: {
          boxSizing: 'border-box',
          borderRadius: 8,
          border: props.active ? `1px solid ${theme.colors.purple[2]}` : '1px solid transparent',
          backgroundColor: props.active ? theme.colors.purple[1] : 'unset',
          marginBottom: theme.spacing.space1,
        },
        label: {
          color: theme.colors.gray[8],
          fontWeight: props.active ? 500 : undefined,
          fontSize: 16,
        },
      }),
      classNames: (theme) => ({
        root: styles.navLinkHover(theme.colors.purple[1]),
      }),
    }),
  },
});
