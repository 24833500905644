import { useGetWorkflows } from 'api';
import { PlanType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';

import { LoadingOverlay } from '@mantine/core';

import { WorkflowsListItem } from './WorkflowsListItem';

import styleIdentifiers from './WorkflowsList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowsListProps = {
  planType: PlanType;
};

export const WorkflowsList = ({ planType }: WorkflowsListProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: workflowsListResponse, isLoading, isError } = useGetWorkflows();

  if (isLoading && !workflowsListResponse) {
    return <LoadingOverlay visible />;
  }

  if (isError || !workflowsListResponse) {
    return <span>Error</span>;
  }

  const { data: workflows, metadata } = workflowsListResponse;

  const createWorkflow = () => {
    history.push(`/settings/workflows/${planType}/create`);
  };

  return (
    <div className={styles('workflows-list')}>
      <ul>
        {workflows.map((workflow) => (
          <li key={workflow.id}>
            <WorkflowsListItem planType={planType} workflow={workflow} />
          </li>
        ))}
        <li onClick={() => createWorkflow()}>
          <div className={styles('workflows-list-item', 'card', 'add-item')}>
            <div>
              <Icon name={IconName.PLUS} />
              <span>{t(i18nKeys.ACTIONS.ADD)}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
