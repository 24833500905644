import { ContactPerson } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AddressBlock from 'shared/components/AddressBlock';
import { AVAILABLE_LANGUAGES } from 'types';

import { Badge } from '@mantine/core';

import styleIdentifiers from './ContactPersonDetails.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ContactPersonDetailsProps = {
  contactPerson: ContactPerson;
};

export const ContactPersonDetails = ({ contactPerson }: ContactPersonDetailsProps) => {
  const { t } = useTranslation();

  const {
    attributes: { main_contact, job_title, locale, emails, phones, address, notifications },
  } = contactPerson;

  return (
    <div className={styles('contact-person')}>
      {main_contact && <Badge>{t(i18nKeys.MAIN)}</Badge>}
      <div className={styles('grid-row')}>
        <div className={styles('col-6')}>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.FORM.JOB_TITLE)}</span>
            <div className={styles('value')}>{job_title || '-'}</div>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.FORM.CLIENT.CONTACT_LANG)}</span>
            <span className={styles('value')}>
              {AVAILABLE_LANGUAGES.find((lang) => lang.value === locale)?.description || '-'}
            </span>
          </div>
        </div>
        <div className={styles('col-6')}>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}> {t(i18nKeys.ADDRESS)}</span>
            {address ? (
              <AddressBlock
                className={styles('value')}
                address={{
                  id: Number(address.id),
                  type: address.type,
                  ...address.attributes,
                }}
              />
            ) : (
              <div className={styles('value')}>-</div>
            )}
          </div>
        </div>
        <div className={styles('col-6')}>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.EMAILS)}</span>
            {emails?.length ? (
              <div>
                {emails.map((email) => (
                  <a key={email} href={`mailto:${email}`} className={styles('value', 'block')}>
                    {email}
                  </a>
                ))}
              </div>
            ) : (
              <div className={styles('value')}>-</div>
            )}
          </div>
        </div>
        <div className={styles('col-6')}>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.PHONES)}</span>
            {phones?.length ? (
              <div>
                {phones.map((phone) => (
                  <a
                    key={phone.id}
                    href={`tel:${phone.attributes.number}`}
                    className={styles('value', 'block')}
                  >
                    {phone.attributes.number}
                  </a>
                ))}
              </div>
            ) : (
              <div className={styles('value')}>-</div>
            )}
          </div>
        </div>
        <div className={styles('col-12')}>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.FORM.NOTIFICATIONS)}</span>
            {notifications?.length
              ? notifications.map((notification) => (
                  <Badge key={notification}>
                    {t(i18nKeys.CONTACT_PERSON.NOTIFICATIONS[notification.toUpperCase()])}
                  </Badge>
                ))
              : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};
