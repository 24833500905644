import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { clone } from 'remeda';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import ExportInvoices from 'shared/components/ExportInvoices';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import BatchActions from './BatchActions';

import styleIdentifiers from './InvoicesList.module.scss';

const styles = classNames.bind(styleIdentifiers);

export type ListingProps = {
  invoicesData: Object;
  loadInvoices: Function;
};

export default function InvoicesList() {
  const { t } = useTranslation();
  const history = useHistory();
  const { company } = useLoadCompanyConfiguration();

  const invoice = useSelector((state: StoreState) => state.invoice);
  const invoicesData = invoice.list;
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const [areAllItemsAcrossPagesSelected, setAreAllItemsAcrossPagesSelected] = useState(false);

  const handleFilters = (filters) => {
    const newFilters = clone(filters);

    // need to be linked
    if (
      (newFilters.total_tvac_operator && !newFilters.total_tvac) ||
      (!newFilters.total_tvac_operator && newFilters.total_tvac)
    )
      return false;

    // need to be linked
    if (
      (newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
      (!newFilters.remaining_balance_operator && newFilters.remaining_balance)
    )
      return false;

    return newFilters;
  };

  const exportInvoices = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.EXPORT),
      children: <ExportInvoices />,
    });
  };

  const showInvoice = (item) => () => {
    history.push(`/invoices/${item.id}`);
  };

  const openBatchActionsModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.FORM.ACTIONS),
      children: (
        <BatchActions
          selectedInvoices={selectedInvoices}
          isSelectedAll={areAllItemsAcrossPagesSelected}
        />
      ),
    });
  };

  const newInvoice = () => {
    invoiceActions.detailReset();
    history.push('/invoices/create');
  };

  const renderButton = () => {
    if (selectedInvoices.length > 0) {
      return <Button noMargin label={t(i18nKeys.FORM.ACTIONS)} onClick={openBatchActionsModal} />;
    }

    return (
      <>
        {company.unvalidated_invoices_count > 0 && (
          <Button noMargin onClick={() => history.push('/invoices/to-confirm')}>
            {t(i18nKeys.IMPORT_TO_CONFIRM)}
            <div className={styles('text-circle', 'absolute', 'border', 'blue')}>
              {company.unvalidated_invoices_count}
            </div>
          </Button>
        )}
        <Button noMargin label={t(i18nKeys.EXPORT)} onClick={exportInvoices} />
        <Button noMargin label={t(i18nKeys.NEW_INVOICE)} onClick={newInvoice} />
      </>
    );
  };

  return (
    <CustomTableViews
      title={t(i18nKeys.INVOICING.INVOICES_BOOK)}
      callbackAction={invoiceActions.listPageRes}
      onClickRow={showInvoice}
      actions={renderButton()}
      isLoading={!invoicesData.loaded}
      pagination={invoicesData.metadata?.pagination}
      handleFilters={handleFilters}
      items={invoicesData.pages || []}
      onChangeSelectionCallback={(values) => setSelectedInvoices(values as Array<Invoice>)}
      onChangeSelectItemsAcrossAllPagesCallback={setAreAllItemsAcrossPagesSelected}
      tableName="invoices"
    />
  );
}
