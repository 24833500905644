import { PartialDeep } from 'type-fest';

import { css } from '@emotion/css';
import { createTheme, MantineTheme, Select } from '@mantine/core';

const selectStyles = {
  input: (theme: MantineTheme) => css`
    &:focus {
      border-color: ${theme.colors.purple[5]} !important;
    }
  `,
  dropdown: (theme: MantineTheme) => css`
		&:hover {
			background-color: ${theme.colors.purple[1]};
	`,
};

export const ThemedMantineSelect: PartialDeep<MantineTheme> = createTheme({
  components: {
    Select: Select.extend({
      styles: (theme) => ({
        input: {
          paddingVertical: theme.spacing.space2,
          borderRadius: 8,
          borderColor: theme.colors.gray[3],
          backgroundColor: theme.colors.gray[0],
          color: theme.colors.gray[5],
        },
        section: {
          color: theme.colors.gray[7],
        },
        dropdown: {
          borderRadius: 8,
          borderColor: theme.colors.gray[2],
          borderWidth: 1,
        },
        option: {
          color: theme.colors.gray[10],
        },
      }),
      classNames: (theme) => ({
        input: selectStyles.input(theme),
        option: selectStyles.dropdown(theme),
      }),
    }),
  },
});
