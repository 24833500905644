import { useEffect, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';

export function useScrollShadow(dependency: unknown, theme = 'light', bgColor = '#fff') {
  const scrollableRef = useRef<HTMLDivElement>(null);

  const [shouldShowTopShadow, setShowTopShadow] = useState(false);
  const [shouldShowBottomShadow, setShowBottomShadow] = useState(true);

  useEffect(() => {
    const scrollableElement = scrollableRef.current;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollableElement!;
      setShowTopShadow(scrollTop > 0);
      // The scroll sometimes does not bottom out full and lands on a decimal value so we ceil
      setShowBottomShadow(Math.ceil(scrollTop) < scrollHeight - clientHeight);
    };

    handleScroll(); // To avoid first render glitch when content is not scrollable

    scrollableElement?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableElement?.removeEventListener('scroll', handleScroll);
    };
  }, [dependency]);

  const topShadow = (
    <div
      className={cx(styles(bgColor).shadow.base, {
        [styles().shadow.hidden]: !shouldShowTopShadow,
        [styles().shadow.darkShadow]: theme === 'dark',
      })}
    />
  );

  const bottomShadow = (
    <div
      className={cx(styles(bgColor).shadow.base, styles().shadow.bottom, {
        [styles().shadow.hidden]: !shouldShowBottomShadow,
        [styles().shadow.darkShadow]: theme === 'dark',
      })}
    />
  );

  return { topShadow, bottomShadow, scrollableRef };
}

const styles = (bgColor?: string) => ({
  shadow: {
    base: css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 60px;
      background: linear-gradient(180deg, ${bgColor} 20%, rgba(255, 255, 255, 0.5), transparent);
      transition: opacity 0.3s;
      pointer-events: none;
    `,
    darkShadow: css`
      background: linear-gradient(180deg, rgba(200, 200, 200, 0.3), transparent);
    `,
    bottom: css`
      top: unset;
      bottom: 0;
      transform: rotate(180deg);
    `,
    hidden: css`
      opacity: 0;
    `,
  },
});
