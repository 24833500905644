import { DEFAULT_THEME, mergeMantineTheme, mergeThemeOverrides } from '@mantine/core';

import { baseTheme } from './base';
import { breakpointsTheme } from './breakpoints';
import { colorsTheme } from './colors';
import { componentsTheme } from './components';
import { spacingTheme } from './spacing';
import { typographyTheme } from './typography';

const overrides = mergeThemeOverrides(
  baseTheme,
  typographyTheme,
  breakpointsTheme,
  colorsTheme,
  componentsTheme,
  spacingTheme,
);

export const mantineTheme = mergeMantineTheme(DEFAULT_THEME, overrides);
