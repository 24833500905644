import { useState } from 'react';
import { CreatePaymentStepCalculationBody, DebtorBasic, Id, Workflow } from 'api/models';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Card from 'shared/components/Card';
import { Button, DateSelector, Input, RadioButton, SwitchInput } from 'shared/io';

import { StepsCalculationDebtor } from './StepsCalculationDebtor';
import { StepsCalculationInvoices } from './StepsCalculationInvoices';
import { StepsCalculationWorkflow } from './StepsCalculationWorkflow';

import styleIdentifiers from './StepsCalculation.module.scss';

const styles = classNames.bind(styleIdentifiers);

type StepsCalculationProps = {
  onSubmit: SubmitHandler<CreatePaymentStepCalculationBody>;
  initialValues?: CreatePaymentStepCalculationBody;
  debtorId?: Id;
  invoiceIds?: Id[];
};

export const StepsCalculation = ({
  onSubmit,
  initialValues,
  debtorId,
  invoiceIds,
}: StepsCalculationProps) => {
  const { t } = useTranslation();

  const defaultValues = {
    ...initialValues,
    debtor_id: debtorId,
    invoice_ids: invoiceIds,
  };

  const [currentDebtor, setCurrentDebtor] = useState<DebtorBasic>();
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow>();

  const form = useForm<CreatePaymentStepCalculationBody>({
    shouldUnregister: true,
    defaultValues,
  });

  const { register, formState, handleSubmit, control, setValue, watch, trigger } = form;

  const [deadlineType, setDeadlineType] = useState<'amount' | 'count'>(
    initialValues?.deadline_amount ? 'amount' : 'count',
  );

  const today = dayjs();
  const tomorrow = today.add(1, 'day');

  return (
    <FormProvider {...form}>
      <form className={styles('step-calculation-form')} onSubmit={handleSubmit(onSubmit)}>
        <Card title={t(i18nKeys.DETAILS)}>
          <div className={styles('grid-row')}>
            <div className={styles('content', 'col-12')}>
              <RadioButton
                className={styles('radio-buttons')}
                noMargin
                onChange={(value) => {
                  setDeadlineType(value);
                }}
                value={deadlineType}
                name="deadline_type"
                items={[
                  {
                    value: 'count',
                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT),
                  },
                  {
                    value: 'amount',
                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT),
                  },
                ]}
              />
            </div>
            {deadlineType === 'count' && (
              <div className={styles('content', 'col-4')}>
                <Input
                  register={register('deadline_count', {
                    required: true,
                    min: 1,
                  })}
                  errorMessage={get(formState.errors, 'deadline_count')}
                  noMargin
                  withBorder
                  label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT)}
                  type="number"
                  min={1}
                  step="1"
                />
              </div>
            )}
            {deadlineType === 'amount' && (
              <div className={styles('content', 'col-4')}>
                <Input
                  register={register('deadline_amount', {
                    required: true,
                    min: 0,
                  })}
                  errorMessage={get(formState.errors, 'deadline_amount')}
                  noMargin
                  withBorder
                  label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT)}
                  type="number"
                  step="0.01"
                />
              </div>
            )}
            <div className={styles('col-4')}>
              <DateSelector
                required
                name="due_date"
                className={styles('input')}
                withBorder
                minDate={tomorrow}
                placeholder={tomorrow.format('DD/MM/YY')}
                noMargin
                label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DUE_DATE)}
                handleChange={(value) => {
                  setValue('due_date', value);
                  trigger('due_date');
                }}
              />
            </div>
            <div className={styles('content', 'col-4')}>
              <Input
                register={register('delay', { required: true, min: 1 })}
                errorMessage={get(formState.errors, 'delay')}
                noMargin
                min={1}
                withBorder
                label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DELAY)}
                type="number"
                step="1"
                placeholder={t(i18nKeys.DAYS, { count: 30 })}
              />
            </div>
            <div className={styles('content', 'col-6')}>
              <StepsCalculationDebtor
                setCurrentDebtor={(d) => {
                  setCurrentDebtor(d);
                  trigger('debtor_id');
                }}
              />
            </div>
            <div className={styles('content', 'col-6')}>
              <StepsCalculationWorkflow
                currentWorkflow={currentWorkflow}
                setCurrentWorkflow={setCurrentWorkflow}
              />
            </div>
          </div>
          {watch('debtor_id') && (
            <div className={styles('content', 'col-12')}>
              <StepsCalculationInvoices currentDebtor={currentDebtor} />
            </div>
          )}
          <div className={styles('content', 'col-12')}>
            <Controller
              control={control}
              name="with_fees"
              render={({ field: { ref, ...values } }) => (
                <SwitchInput
                  {...values}
                  className={styles('switch-input')}
                  noMargin
                  label={t(i18nKeys.WITH_FEES)}
                  noShadow
                />
              )}
            />
          </div>
          <div className={styles('content', 'col-12', 'button-wrapper')}>
            <Button
              type="submit"
              noMargin
              label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.CALCULATE)}
            />
          </div>
        </Card>
      </form>
    </FormProvider>
  );
};
