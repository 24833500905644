import { useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider as RawJotaiProvider } from 'jotai';
import { DevTools } from 'jotai-devtools';
import { useTranslation } from 'locales';
import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { useLocation } from 'react-router';
import { isDefined, isNonNullish, isNullish } from 'remeda';
import { useEnforceProfile } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import { isProduction, isPublicEnvironment } from 'shared/utils/environment';
import { config } from 'store/constants';
import { rootSagas } from 'store/root.sagas';

import { Auth0Provider as _Auth0Provider } from '@auth0/auth0-react';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { useHotkeys } from '@mantine/hooks';
import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { captureException } from '@sentry/react';
import { styled } from '@stitches/react';
import {
  QueryCache,
  QueryClient as QueryClientV5,
  QueryClientProvider as QueryClientProviderV5,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { jotaiStore } from './jotai';
import { mantineTheme } from './mantine';
import { browserHistory, sagaMiddleware, store } from './redux-store';

const queryClient = new QueryClient();
export const queryClientV5 = new QueryClientV5({
  queryCache: new QueryCache({
    onError: (error) => {
      console.error(error);
      captureException(error);
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});

const DevtoolStyleFixes = styled('span', {
  '.jotai-devtools-trigger-button': {
    position: 'absolute',
    bottom: '60px',
    right: '5px',
    left: 'unset',
    transform: 'scale(0.73)',
  },
});

const DevToolsWrapper = () => {
  const [areDevtoolsVisible, setAreDevtoolsVisible] = useState(false);
  useHotkeys([['shift+mod+D', () => setAreDevtoolsVisible((state) => !state)]]);

  if (isPublicEnvironment()) return null;

  if (!areDevtoolsVisible) return null;

  return (
    <DevtoolStyleFixes>
      <ReactQueryDevtools initialIsOpen={false} />
      <DevTools />
    </DevtoolStyleFixes>
  );
};

export const Providers = ({ children }) => {
  sagaMiddleware.run(rootSagas);

  return (
    <Auth0Provider>
      <ReduxProviders store={store} history={browserHistory}>
        <JotaiProvider>
          <ReactQueryProviders>
            <PostHogProvider>
              <MantineProviders>
                {children}
                <DevToolsWrapper />
                <Notifications zIndex={1000} position="bottom-right" />
              </MantineProviders>
            </PostHogProvider>
          </ReactQueryProviders>
        </JotaiProvider>
      </ReduxProviders>
    </Auth0Provider>
  );
};

const Auth0Provider = ({ children }) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <_Auth0Provider
    domain={config.auth0.domain}
    clientId={config.auth0.clientId}
    cacheLocation="localstorage"
    useRefreshTokens
    authorizationParams={{
      audience: config.auth0.audience,
      redirect_uri: `${window.location.origin}/dashboard`,
      scope: 'openid profile email offline_access',
    }}
  >
    {children}
  </_Auth0Provider>
);

const ReduxProviders = ({ store: _store, history, children }) => (
  <ReduxProvider store={_store}>
    <ConnectedRouter history={history}>{children}</ConnectedRouter>
  </ReduxProvider>
);

const JotaiProvider = ({ children }) => (
  <RawJotaiProvider store={jotaiStore}>{children}</RawJotaiProvider>
);

const ReactQueryProviders = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <QueryClientProviderV5 client={queryClientV5}>{children}</QueryClientProviderV5>
  </QueryClientProvider>
);

const MantineProviders = ({ children }) => {
  const { currentLang } = useTranslation();

  return (
    <MantineProvider theme={mantineTheme}>
      <DatesProvider settings={{ locale: currentLang }}>
        <MantineModalsProvider>{children}</MantineModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
};

const PostHogProvider = ({ children }) => {
  const location = useLocation();
  const { profile } = useEnforceProfile();

  useEffect(() => {
    if (isProduction() && isDefined(profile)) {
      posthog.init('phc_f3vuQblHJAnPKJA0qKZan0PmalMguSQJdPFSEw0Upsc', {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
        capture_pageview: false,
      });

      posthog.identify(profile.id.toString(), {
        tenant: apiService.tenant,
        accessibleTenants: profile.accessibleTenants,
        locale: profile.locale,
        isAdmin: profile.isAdmin,
        roles: profile.roles,
      });
    }
  }, [isNullish(profile)]);

  useEffect(() => {
    if (isNonNullish(posthog)) {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
      });
    }
  }, [location]);

  return <PHProvider client={posthog}>{children}</PHProvider>;
};
