import React from 'react';
import { TaskStatus } from 'api/models';
import classNames from 'classnames/bind';

import styleIdentifiers from './TasksListItemStatus.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TasksListItemStatusProps = {
  className?: string;
  statusKey: TaskStatus;
  status: string;
};

export const TasksListItemStatus = ({ className, statusKey, status }: TasksListItemStatusProps) => (
  <div className={styles('task-status-item', className)}>
    <div className={styles('label')}>{status}</div>
    <div className={styles('indicator')}>
      <span className={styles(`step-${statusKey}`)} />
      {statusKey === TaskStatus.pending ? (
        <span />
      ) : (
        <span className={styles(`step-${statusKey}`)} />
      )}
    </div>
  </div>
);
