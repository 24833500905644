import { useEffect, useState } from 'react';
import { useGetPaymentPlan, useUpdatePaymentPlan, useUpdatePaymentStepCalculation } from 'api';
import { PaymentStepsAttributes, UpdatePaymentStepCalculationBody } from 'api/models';
import { omit } from 'lodash-es';
import { useHistory, useParams } from 'react-router';

import { LoadingOverlay } from '@mantine/core';

import { PaymentPlanFormWrapper } from '../PaymentPlanForm/PaymentPlanFormWrapper';

import { AddInvoicesForm } from './AddInvoicesForm/AddInvoicesForm';
import {
  newPropertiesObject,
  PartialUpdatePaymentPlanBody,
  PaymentPlanEditFormSteps,
} from './PaymentPlanEditFormSteps';

export const PaymentPlanEditForm = () => {
  const { id: paymentPlanId } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: paymentPlanResponse, isLoading, isError } = useGetPaymentPlan(paymentPlanId);

  const { mutate: updatePaymentPlan } = useUpdatePaymentPlan();
  const { mutate: updatePaymentStepCalculation } = useUpdatePaymentStepCalculation();

  const [addInvoices, setAddInvoices] = useState(false);

  const [paymentSteps, setPaymentSteps] = useState<PaymentStepsAttributes[]>([]);
  const [newProperties, setNewProperties] = useState<Object>();

  useEffect(() => {
    if (paymentPlanResponse) {
      const {
        data: {
          attributes: { payment_steps_attributes },
        },
      } = paymentPlanResponse;

      setPaymentSteps(
        payment_steps_attributes.map((e) => omit({ id: e.id, ...e.attributes }, ['days_late'])),
      );
    }
  }, [paymentPlanResponse]);

  if (isLoading && !paymentPlanResponse) {
    return <LoadingOverlay visible />;
  }

  if (isError || !paymentPlanResponse) {
    return <span>Error</span>;
  }

  if (addInvoices) {
    return (
      <PaymentPlanFormWrapper edit>
        <AddInvoicesForm
          debtor={paymentPlanResponse.data.attributes.debtor}
          onCancel={() => setAddInvoices(false)}
          onSubmit={(data: UpdatePaymentStepCalculationBody) => {
            updatePaymentStepCalculation(
              { id: paymentPlanId, data },
              {
                onSuccess: (response) => {
                  setPaymentSteps(
                    data.new_steps
                      ? [...paymentSteps, ...response.payment_steps_attributes]
                      : response.payment_steps_attributes,
                  );
                  setAddInvoices(false);
                  setNewProperties({
                    addedInvoiceIds: data.invoice_ids,
                    newBalance: response.new_remaining_balance,
                  });
                },
              },
            );
          }}
        />
      </PaymentPlanFormWrapper>
    );
  }

  const { data: paymentPlan } = paymentPlanResponse;

  return (
    <PaymentPlanEditFormSteps
      paymentPlan={paymentPlan}
      paymentSteps={paymentSteps}
      newProperties={newProperties as newPropertiesObject}
      onButtonClick={() => setAddInvoices(true)}
      onSubmit={(data: PartialUpdatePaymentPlanBody) => {
        updatePaymentPlan(
          { id: paymentPlanId, data },
          {
            onSuccess: () => {
              history.push(`/payment-plan/${paymentPlanId}`);
            },
          },
        );
      }}
    />
  );
};
