import React from 'react';
import { i18nKeys, useTranslation } from 'locales/index';
import { Link } from 'react-router-dom';

import { ActionIcon, Button, Group, useMantineTheme } from '@mantine/core';
import { IconAlarm as TablerIcon, IconArrowRight } from '@tabler/icons-react';

interface ActivityItemprops
  extends React.PropsWithChildren<{
    url: string;
    hasInvoicesToTreat?: boolean;
    Icon: React.ComponentType<React.ComponentProps<typeof TablerIcon>>;
  }> {}

export const ActivityItem = ({ url, hasInvoicesToTreat, Icon, children }: ActivityItemprops) => {
  const { t } = useTranslation();
  return (
    <Group justify="space-between">
      <Group gap="space2">
        <ActionIcon
          component="span"
          style={{ pointerEvents: 'none' }}
          color="gray.6"
          variant="subtle"
        >
          <Icon size={24} stroke={1.5} />
        </ActionIcon>
        {children}
      </Group>
      <Link to={url}>
        <Button
          justify="space-between"
          variant={hasInvoicesToTreat ? 'filled' : 'default'}
          bd={hasInvoicesToTreat ? 'none' : '1px solid gray.4'}
          leftSection={<IconArrowRight size={18} />}
        >
          {t(i18nKeys.PROCESS)}
        </Button>
      </Link>
    </Group>
  );
};
