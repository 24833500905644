import { useRef } from 'react';
import { Id } from 'api/models';
import { i18nKeys, useTranslation } from 'locales';
import { clone, mergeWith, subtract, upperFirst } from 'lodash-es';
import { useHistory } from 'react-router';
import Amount from 'shared/components/Amount';
import { AgedBalanceCompact, useAgedBalanceColors } from 'shared/hooks';
import { Panel } from 'shared/layout';
import { formatAmount } from 'shared/utils';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Badge, Box, Button, Group, Text, useMantineTheme } from '@mantine/core';
import { ResponsivePie } from '@nivo/pie';
import { animated } from '@react-spring/web';

import { DetailedBalance } from '../DetailedBalance';

import { useIsWidthBelowThreshold } from './useIsWidthBelowTreshold';

type OutstandingGraphicProps = {
  agedBalanceCompact: AgedBalanceCompact;
  accountManagerId?: Id;
};

const ArcLabelsComponent = ({ label, style }) => (
  <animated.g transform={style.transform}>
    <foreignObject width="100" height="25" overflow="visible" pointerEvents="none">
      <Badge
        style={{
          transform: 'translateX(-50%) translateY(-100%)',
          padding: '4px 8px',
        }}
        radius={8}
        color="gray.10"
      >
        <Text fz={14} c="gray.0">
          {formatAmount(label, '.', ' ')}
        </Text>
      </Badge>
    </foreignObject>
  </animated.g>
);

export function OutstandingGraphic({
  agedBalanceCompact,
  accountManagerId,
}: OutstandingGraphicProps) {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const colors = useAgedBalanceColors();
  const { push } = useHistory();

  const containerRef = useRef<HTMLDivElement>(null);
  const isSmall = useIsWidthBelowThreshold(containerRef, 450);

  const netBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const overdueAmount = netBalance['0'] + netBalance['30'] + netBalance['60'] + netBalance['90'];
  const notDueAmount = netBalance.notDue;
  const totalEngaged = overdueAmount + notDueAmount;

  const showBalanceDetailed = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.DASHBOARD.BALANCE_DETAIL),
      children: <DetailedBalance accountManagerId={accountManagerId} />,
    });
  };

  const pieData = [
    {
      id: 'overdue',
      label: t(i18nKeys.ANALYTICS.OVERDUE),
      value: overdueAmount,
      color: colors.totalBalance,
      mantineColor: 'rgb(40, 40, 40, 0.8)',
      query: '/invoices/listing?page=1&status=late_unpaid,not_lost',
    },
    {
      id: 'not_due_yet',
      label: t(i18nKeys.ANALYTICS.NOT_DUE_YET),
      value: notDueAmount,
      color: colors.notDue,
      mantineColor: 'rgb(40, 40, 40, 0.8)',
      query: '/invoices/listing?status=unpaid,not_lost&max_days_late=0',
    },
  ];

  return (
    <Panel
      title={t(i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TITLE)}
      topRight={
        <Button variant="default" size="compact-sm" onClick={showBalanceDetailed}>
          {t(i18nKeys.INVOICING.SEE_DETAIL)}
        </Button>
      }
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        w="100%"
        h="100px"
        ref={containerRef}
        style={{
          containerType: 'inline-size',
          flexGrow: 1,
        }}
      >
        <ResponsivePie
          onClick={(d) => push(d.data.query)}
          onMouseEnter={(_datum, event) => {
            event.currentTarget.style.cursor = 'pointer';
          }}
          tooltip={() => null}
          margin={{ top: 10, bottom: 30, right: 40 }}
          data={pieData}
          innerRadius={0.65}
          padAngle={1}
          colors={{ datum: 'data.color' }}
          enableArcLinkLabels={false}
          arcLabelsComponent={ArcLabelsComponent}
          legends={[
            {
              anchor: isSmall ? 'center' : 'right',
              direction: 'column',
              translateX: 10,
              itemWidth: 80,
              itemHeight: 25,
              symbolSize: 8,
              symbolShape: 'circle',
              itemTextColor: theme.colors.gray[8],
            },
          ]}
        />
      </Box>
      <Group
        justify="space-between"
        bg="gray.1"
        style={{ borderRadius: 8, padding: 16, marginTop: 'auto' }}
      >
        <Text fw={500}>
          {upperFirst(t(i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TOTAL_COMMITTED))}
        </Text>
        <Text fw={500}>
          <Amount value={totalEngaged} />
        </Text>
      </Group>
    </Panel>
  );
}
