import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  CreateExternalMailboxVariables,
  ExternalMailboxConfiguration,
  useCreateExternalMailbox,
} from 'shared/hooks';
import { Button, ButtonColor, Checkbox, Input } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { useDisclosure } from '@mantine/hooks';

import logoMicrosoft from '../assets/logo-microsoft.svg';
import SignatureEditorModal from '../SignatureEditorModal';

import OAauthModal from './OAuthModal';

import styleIdentifiers from './ExternalMailboxSettingsForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailboxSettingsFormProps = {
  onSubmit: SubmitHandler<CreateExternalMailboxVariables>;
  externalMailboxConfiguration?: ExternalMailboxConfiguration;
  edit?: boolean;
};

export const ExternalMailboxSettingsForm = ({
  onSubmit,
  externalMailboxConfiguration,
  edit,
}: ExternalMailboxSettingsFormProps) => {
  const { t } = useTranslation();
  const [isSignatureModalOpen, { open: openSignatureModal, close: closeSignatureModal }] =
    useDisclosure(false);

  const form = useForm<CreateExternalMailboxVariables>({
    shouldUnregister: true,
    defaultValues: {
      ...externalMailboxConfiguration?.attributes,
      is_microsoft: externalMailboxConfiguration?.attributes?.is_microsoft ?? undefined,
    },
  });

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = form;

  const signature = form.getValues('signature');

  const microsoftLogo = (
    <img className={styles('microsoft-logo')} src={logoMicrosoft} alt="microsoft-logo" />
  );

  const { createExternalMailbox } = useCreateExternalMailbox();

  const openConfirmationModal = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <OAauthModal
          confirmButtonColor={ButtonColor.WHITE}
          onConfirm={(data) => {
            createExternalMailbox(data, {
              onSuccess: ({ data: { redirect_uri } }) => {
                window.location.replace(redirect_uri!);
              },
            });
          }}
        >
          {microsoftLogo}
        </OAauthModal>
      ),
    });
  };

  return (
    <FormProvider {...form}>
      <form className={styles('external-mailbox-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('head')}>
          {t(
            externalMailboxConfiguration
              ? i18nKeys.SETTINGS.EXTERNAL_MAILBOX.EDIT
              : i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ADD,
          )}
        </div>
        {!edit && (
          <div className={styles('quick-setup')}>
            <h3>{t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.QUICK_SETUP)}</h3>
            <Button
              label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.SIGN_IN_WITH.MICROSOFT)}
              color={ButtonColor.WHITE}
              noShadow
              childrenBefore
              onClick={openConfirmationModal}
            >
              {microsoftLogo}
            </Button>
          </div>
        )}
        <div className={styles('content')}>
          {!edit && <h3>{t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.MANUAL_SETUP)}</h3>}
          <Input
            register={register('host', {
              required: true,
            })}
            errorMessage={get(errors, 'host')}
            label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.HOST)}
            withBorder
            noMargin
          />
          <Input
            type="number"
            register={register('port', {
              required: true,
            })}
            errorMessage={get(errors, 'port')}
            label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.PORT)}
            withBorder
          />
          <Checkbox
            register={register('ssl')}
            watch={watch}
            label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.SSL).toUpperCase()}
            name="ssl"
          />
          <Input
            type="text"
            register={register('login', {
              required: true,
            })}
            errorMessage={get(errors, 'login')}
            label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LOGIN)}
            withBorder
          />
          <Input
            type="password"
            register={register('password', {
              required: !edit,
            })}
            errorMessage={get(errors, 'password')}
            label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.PASSWORD)}
            withBorder
          />
          <div style={{ marginTop: '30px' }} />
          <Button onClick={openSignatureModal}>{t(i18nKeys.EDIT_SIGNATURE)}</Button>
        </div>
        <div className={styles('button-wrapper')}>
          <Button type="submit" label={t(i18nKeys.SAVE)} noShadow noMargin />
        </div>
      </form>
      <SignatureEditorModal
        isOpen={isSignatureModalOpen}
        onClose={closeSignatureModal}
        onSave={(htmlVal) => form.setValue('signature', htmlVal)}
        signature={signature ?? null}
      />
    </FormProvider>
  );
};
