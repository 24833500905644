import { PropsWithChildren, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import { LoadingPlaceholder } from '../LoadingPlaceholder';

import styleIdentifiers from './InfiniteScroll.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  items: any[];
  className?: string;
  scrollThreshold?: string;
  hasMore: boolean;
  loading: boolean;
  fwRef?: any;
  load: Function;
};

export function InfiniteScroll({
  fwRef,
  children,
  items,
  className,
  scrollThreshold,
  hasMore,
  load,
  loading,
}: PropsWithChildren<Props>) {
  const listRef = fwRef || useRef(null);
  let actionTriggered = false;
  let prevScrollValue = 0;
  let timeout = false;

  const isElementAtBottom = (target: HTMLElement, scrollThreshold: string | number = 0.8) => {
    const { clientHeight, scrollTop, scrollHeight } = target;

    if (prevScrollValue < scrollTop + clientHeight) {
      prevScrollValue = scrollTop + clientHeight;
      return typeof scrollThreshold === 'number'
        ? scrollTop + clientHeight >= scrollThreshold * scrollHeight
        : scrollTop + clientHeight >= scrollHeight - parseFloat(scrollThreshold);
    }
  };
  const setScrollTop = (target: HTMLElement) => {
    if (!actionTriggered) {
      if (isElementAtBottom(target, scrollThreshold) && hasMore) {
        actionTriggered = true;
        load?.();
      }
    }
    timeout = false;
  };

  const throttledOnScrollListener = (event) => {
    if (!timeout) {
      timeout = true;
      setTimeout(() => setScrollTop(event.target), 1000);
    }
  };
  useEffect(() => {
    if (actionTriggered) actionTriggered = false;

    if (hasMore) {
      listRef.current.addEventListener(
        'scroll',
        throttledOnScrollListener as EventListenerOrEventListenerObject,
      );
    } else {
      listRef.current.removeEventListener(
        'scroll',
        throttledOnScrollListener as EventListenerOrEventListenerObject,
      );
    }

    return () =>
      listRef.current?.removeEventListener(
        'scroll',
        throttledOnScrollListener as EventListenerOrEventListenerObject,
      );
  }, [items]);

  return (
    <div ref={listRef} className={styles(className, 'infinite-scroll')}>
      {children}
      <LoadingPlaceholder isActive={loading} size="small" />
    </div>
  );
}
