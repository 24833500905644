import { useEffect, useState } from 'react';
import { QuickActionMenu } from 'app/Private/QuickActionMenu';
import UserMenuButton from 'modules/Navigation/components/UserMenuButton';
import { useHistory, useLocation } from 'react-router';
import { useScrollShadow } from 'shared/hooks/ui/use-scroll-shadow';
import { useIsMobile } from 'shared/hooks/utils';

import { ActionIcon, Group, ScrollArea, Space, Stack, useMantineTheme } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import Logo from '../../shared/components/Logo';

import { ActionsSpotlight } from './components/ActionsSpotlight';
import { MobileMenuButton } from './components/MobileMenuButton';
import { NavBackButton } from './components/NavBackButton';
import { NavigationSpotlight } from './components/NavigationSpotlight';
import { NavMenu } from './components/NavMenu';
import { NavMenuItem } from './components/NavMenuItem';
import { SearchSpotlight } from './components/SearchSpotlight';
import { useBuildMenuItems } from './hooks/use-build-menu-items';
import { Menu } from './types';
import { findMenuForLocation, isMenu } from './utils';

interface NavBarProps {
  closeNavbarOnMobile: () => void;
  onClickOpenNavbar: () => void;
}

export const NavBar = ({ closeNavbarOnMobile, onClickOpenNavbar }: NavBarProps) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useMantineTheme();
  const isMobile = useIsMobile();

  const rootMenu = useBuildMenuItems();
  const [currentMenu, setCurrentMenu] = useState<Menu>(
    findMenuForLocation(rootMenu, location.pathname) || rootMenu,
  );

  const { topShadow, bottomShadow, scrollableRef } = useScrollShadow(
    currentMenu.label,
    'light',
    theme.colors.purple[0],
  );

  useEffect(() => {
    setCurrentMenu(findMenuForLocation(rootMenu, location.pathname) ?? rootMenu);
  }, [location.pathname, rootMenu]);

  const { parent: parentMenu } = currentMenu;

  return (
    <>
      <NavigationSpotlight />
      <ActionsSpotlight />
      <SearchSpotlight />
      <Stack bg="purple.0" py="space6" px="space3" h="100%" gap={0} pos="relative">
        <MobileMenuButton onClickOpenNavbar={onClickOpenNavbar} />
        <Group ml="space1" mb="space5">
          <Group
            flex="1"
            onClick={() => {
              history.push('/');
              closeNavbarOnMobile();
            }}
            style={{ cursor: 'pointer', userSelect: 'none' }}
          >
            <Logo variant="logoFull" height={32} />
          </Group>
          <ActionIcon
            hiddenFrom="mobile"
            color="gray.6"
            variant="transparent"
            onClick={closeNavbarOnMobile}
          >
            <IconX />
          </ActionIcon>
        </Group>
        {parentMenu && (
          <>
            <NavBackButton onClick={() => setCurrentMenu(parentMenu)} label={currentMenu.label} />
            <Space h="space3" />
          </>
        )}
        <ScrollArea flex={1} viewportRef={scrollableRef}>
          {topShadow}
          {bottomShadow}
          {currentMenu.items.map((item) =>
            isMenu(item) ? (
              <NavMenu key={item.label} {...item} menu={item} setMenu={setCurrentMenu} />
            ) : (
              <NavMenuItem
                key={item.label}
                {...item}
                onNavigate={isMobile ? closeNavbarOnMobile : () => {}}
              />
            ),
          )}
        </ScrollArea>
        <div id="variants-portal" />
        <Space h="space4" />
        <QuickActionMenu />
        <Space h="space6" />
        <UserMenuButton />
      </Stack>
    </>
  );
};
