import { nice, ticks } from 'd3-array';
import { i18nKeys, useTranslation } from 'locales';
import { upperFirst } from 'lodash-es';
import { firstBy, identity, pipe } from 'remeda';
import { BarChartTooltip } from 'shared/charts';
import { Dso, useProfile } from 'shared/hooks';
import { roundToNearestMultipleOfN } from 'shared/utils';

import { Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line';

const Y_AXIS_PADDING = 10;
const DESIRED_Y_AXIS_TICKS = 8;

interface DsoChartProps {
  dso: Dso;
}

export const DsoChart = ({ dso }: DsoChartProps) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const theme = useMantineTheme();

  const paddedMaxValue = pipe(
    dso,
    Object.values,
    firstBy([identity(), 'desc']),
    (max) => max + Y_AXIS_PADDING,
    (maxPadded) => roundToNearestMultipleOfN(maxPadded, 10),
  );

  const [, yAxisUpperBound] = nice(0, paddedMaxValue, DESIRED_Y_AXIS_TICKS);
  const yValues = ticks(0, yAxisUpperBound, DESIRED_Y_AXIS_TICKS);

  const dsoSliceTooltip = ({ slice }: SliceTooltipProps) => {
    setTimeout(() => {
      const tooltip = document.getElementById('tooltip-fix');
      if (tooltip) tooltip.style.opacity = '1';
    }, 50);

    return (
      <BarChartTooltip style={{ opacity: 0, transition: 'all 0.1s ease-in-out' }} id="tooltip-fix">
        <Text fw={700} mr={7}>
          {`${upperFirst(
            (slice.points[0].data.x as Date).toLocaleDateString(profile.locale, {
              month: 'long',
            }),
          )}:`}
        </Text>
        <Text>{t(i18nKeys.DAYS, { count: slice.points[0].data.y as number })}</Text>
      </BarChartTooltip>
    );
  };

  return (
    <ResponsiveLine
      isInteractive
      enableSlices="x"
      enableArea
      defs={[
        {
          colors: [
            {
              color: 'inherit',
              offset: 0,
            },
            {
              color: 'inherit',
              offset: 100,
              opacity: 0,
            },
          ],
          id: 'gradientA',
          type: 'linearGradient',
        },
      ]}
      fill={[
        {
          id: 'gradientA',
          match: '*',
        },
      ]}
      useMesh
      curve="monotoneX"
      enableCrosshair
      margin={{ top: 10, right: 15, bottom: 35, left: 55 }}
      axisBottom={{
        tickPadding: 15,
        tickSize: 0,
        format: (date: Date) => date.toLocaleDateString(profile.locale, { month: 'short' }),
      }}
      axisLeft={{
        tickPadding: 15,
        tickSize: 0,
        tickValues: yValues,
      }}
      gridYValues={yValues}
      sliceTooltip={dsoSliceTooltip}
      yScale={{ type: 'linear', min: 0, max: yAxisUpperBound }}
      xScale={{ type: 'time' }}
      enableGridX={false}
      pointSize={8}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      colors={[theme.colors.purple[5]]}
      pointColor="white"
      theme={{
        grid: {
          line: {
            stroke: theme.colors.gray[3],
            strokeDasharray: 7,
            strokeDashoffset: 15,
          },
        },
        labels: {
          text: {
            fontSize: 14,
            fill: theme.colors.gray[6],
          },
        },
        text: {
          fontSize: 12,
          fill: theme.colors.gray[6],
        },
      }}
      data={[
        {
          id: 'dso',
          color: theme.colors.cyan[4],
          data: Object.entries(dso).map(([key, value]) => ({ x: new Date(key), y: value })),
        },
      ]}
    />
  );
};
