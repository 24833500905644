import { PartialDeep } from 'type-fest';

import { Card, createTheme, MantineTheme } from '@mantine/core';

export const ThemedMantineCard: PartialDeep<MantineTheme> = createTheme({
  components: {
    Card: Card.extend({
      defaultProps: {
        withBorder: true,
      },
      styles: (theme) => ({
        root: {
          borderColor: theme.colors.gray[2],
        },
      }),
    }),
  },
});
