import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UserInfo } from 'shared/components';
import { useLoadLightUsers, useProfile } from 'shared/hooks';
import { Button, CustomSelect, DateSelector } from 'shared/io';
import { usersToDescVal } from 'shared/utils';
import { useFilterForm } from 'shared/utils/hooks';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './activityFiltersForm.module.scss';

const styles = classNames.bind(styleIdentifiers);
type Props = {
  onSubmit: onSubmitFunction;
  initialValues: any;
  filterObject: any;
};

export default function ActivityFiltersForm({ onSubmit, initialValues, filterObject }: Props) {
  const { t } = useTranslation();
  const profile = useProfile();
  const { users = [] } = useLoadLightUsers();
  const { activity_categories, activity_authors } = useSelector(
    (state: StoreState) => state.app.constants,
  );

  const usersFormatted = usersToDescVal(users);
  const usersAndAuthors = [...activity_authors, ...usersFormatted];

  const { form, submit, resetForm } = useFilterForm({
    onSubmit,
    initialValues,
    fieldToRegister: ['start_date', 'end_date'],
  });
  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (filterObject) {
      filterObject.reset = resetForm;
    }
  }, [filterObject, resetForm]);

  return (
    <FormProvider {...form}>
      <form className={styles('ActivityFiltersForm')} onSubmit={handleSubmit(submit)}>
        <div className={styles('fields')}>
          <DateSelector
            className={styles('input', 'date-selector')}
            name="start_date"
            endName="end_date"
            placeholder={t(i18nKeys.OF)}
            endPlaceholder={t(i18nKeys.FORM.TO)}
            withBorder
            shadow
            noMinDate
            label={t(i18nKeys.DATE)}
            handleChange={(value) => {
              setValue('start_date', value);
              submit();
            }}
            handleEndChange={(value) => {
              setValue('end_date', value);
              submit();
            }}
          />
          <Controller
            defaultValue=""
            name="category"
            render={() => (
              <CustomSelect
                selectClassName={styles('large')}
                keyText="description"
                keyValue="value"
                name="category"
                noMargin
                withBorder
                shadow
                items={activity_categories}
                label={t(i18nKeys.FORM.CATEGORY)}
                onValueChanged={submit}
              />
            )}
          />
          <Controller
            defaultValue=""
            name="author"
            render={() => (
              <CustomSelect
                selectClassName={styles('large')}
                keyText="description"
                keyValue="value"
                name="author"
                noMargin
                withBorder
                shadow
                listClassName={styles('author-suggestions')}
                items={usersAndAuthors}
                label={t(i18nKeys.ACTIVITIES.AUTHOR)}
                onValueChanged={submit}
              />
            )}
          />
          {(profile.isAdmin || profile.roles.includes('all_invoices')) && (
            <Controller
              defaultValue=""
              name="account_manager_id"
              render={() => (
                <CustomSelect
                  noMargin
                  selectClassName={styles('xl')}
                  keyValue="id"
                  onValueChanged={submit}
                  withBorder
                  shadow
                  itemRendering={(props) => <UserInfo withPicture {...props} />}
                  valueRendering={(props) => <UserInfo inline {...props} />}
                  name="account_manager_id"
                  items={users}
                  label={t(i18nKeys.ACCOUNT_MANAGER)}
                />
              )}
            />
          )}
          <Button noShadow noMargin type="submit" className={styles('invisible')} />
        </div>
      </form>
    </FormProvider>
  );
}
