import { PartialDeep } from 'type-fest';

import { createTheme, MantineTheme, Modal } from '@mantine/core';

export const ThemedMantineModal: PartialDeep<MantineTheme> = createTheme({
  components: {
    Modal: Modal.extend({
      styles: {
        title: {
          fontWeight: 600,
          fontSize: '1.2rem',
        },
        header: {
          borderBottom: '1px solid lightgrey',
          paddingBottom: '12px',
          paddingTop: '12px',
        },
        body: {
          paddingTop: 'var(--mantine-spacing-md)',
        },
      },
    }),
  },
});
