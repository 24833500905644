import { useLayoutEffect, useState } from 'react';
import { useDeleteUnvalidatedImports, useGetInvoicesToConfirm } from 'api';
import { GetInvoicesToConfirmParams } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import CustomTable from 'shared/components/CustomTable';
import InvoiceListItem from 'shared/components/InvoiceListItem';
import { useProfile } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { useGetInvoiceState } from 'shared/utils/selectors';
import { invoiceActions } from 'store/invoice/invoice.actions';

import { LoadingOverlay } from '@mantine/core';

import styleIdentifiers from './InvoiceListToConfirm.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(InvoiceListItem);

export default function InvoiceListToConfirm() {
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useProfile();
  const queryClient = useQueryClient();
  const { isLoading: isProcessing } = useGetInvoiceState();
  const [params, setParams] = useState<GetInvoicesToConfirmParams>({
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
  });

  useLayoutEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<GetInvoicesToConfirmParams>(
        Object.fromEntries(new URLSearchParams(location.search)),
        ['page', 'page_limit', 'sort_by', 'sort_order'],
      ),
    }));
  }, []);

  const {
    data: invoicesToConfirmListResponse,
    isLoading,
    isSuccess,
    isError,
  } = useGetInvoicesToConfirm(params);

  const { mutate: deleteUnvalidatedImports, isPending: isDeleting } = useDeleteUnvalidatedImports();

  if (isLoading && !invoicesToConfirmListResponse) {
    return <LoadingOverlay visible />;
  }

  if (isError || !invoicesToConfirmListResponse) {
    return <span>Error</span>;
  }

  const handleDeleteUnvalidatedImports = () => {
    deleteUnvalidatedImports(
      { data: {} },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
          history.push('/invoices/listing');
        },
      },
    );
  };

  const items = invoicesToConfirmListResponse.data ?? [];
  const isImportPending = invoicesToConfirmListResponse.pending_import;

  const INVOICES_TABLE_HEADERS = [
    {
      key: 'reference',
      title: t(i18nKeys.INVOICE_NUMBER),
      width: 140,
    },
    {
      key: 'issue_date',
      title: t(i18nKeys.ISSUE_DATE),
      width: 140,
    },
    {
      key: 'due_date',
      title: t(i18nKeys.DUE_DATE),
      width: 140,
    },
    {
      key: 'debtor_attributes.full_name',
      title: t(i18nKeys.COMMON.CLIENT),
      width: 120,
    },
    {
      key: 'days_late',
      title: t(i18nKeys.LATE),
    },
    {
      key: 'step_collection',
      title: t(i18nKeys.STATUS),
      width: 160,
    },
    {
      key: 'total_tvac',
      title: t(i18nKeys.TOTAL_VAT_PRICE),
    },
    {
      key: 'remaining_balance',
      title: t(i18nKeys.BALANCE),
    },

    {
      title: '',
      width: 120,
    },
  ];

  const handleFilters = (newValues) => {
    const optimisticResult = {
      ...params,
      ...pick(newValues, ['page_limit', 'page']),
    };
    setParams((current) => ({
      ...current,
      ...pick(newValues, ['page_limit', 'page']),
    }));
    return optimisticResult;
  };

  const onSortChange = (newParams: GetInvoicesToConfirmParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']),
    }));
  };

  const action = (actionToSend) => () =>
    actionToSend({
      callback: () => {
        history.push('/invoices/listing');
      },
    });

  return (
    <div className={styles('invoice-list-to-confirm')}>
      <EnhancedTable
        title={t(i18nKeys.INVOICING.INVOICES_TO_CONFIRM)}
        actions={
          isImportPending || items.length === 0 ? null : (
            <>
              <Button
                onClick={handleDeleteUnvalidatedImports}
                color={ButtonColor.GREY}
                label={t(i18nKeys.INVOICING.IMPORT.DELETE_ALL)}
                noMargin
                disabled={
                  isProcessing || items.filter((item) => !item.attributes.csv_updated).length === 0
                }
                isLoading={isDeleting}
              />
              <Button
                onClick={action(invoiceActions.validateAllImportedInvoices)}
                color={ButtonColor.GREEN}
                label={t(i18nKeys.INVOICING.IMPORT.VALIDATE_ALL)}
                noMargin
                disabled={isDeleting}
                isLoading={isProcessing}
              />
            </>
          )
        }
        noCheckbox
        onSortChange={onSortChange}
        loading={isLoading}
        loaded={isSuccess}
        pagination={isImportPending ? undefined : invoicesToConfirmListResponse.metadata.pagination}
        headers={INVOICES_TABLE_HEADERS}
        handleFilters={handleFilters}
        itemProps={{ isLoading: isDeleting }}
        items={isImportPending ? [] : items}
        pendingImport={isImportPending}
      />
    </div>
  );
}
