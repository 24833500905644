import dayjs from 'dayjs';
import { i18nKeys, TranslateFunction, useTranslation } from 'locales';
import { initial, last } from 'lodash-es';
import { invert, isNullish, omit } from 'remeda';
import { AgedBalanceOverTime, useAgedBalanceColors } from 'shared/hooks';
import { useAgedBalanceAccentColors } from 'shared/hooks/utils';
import {
  useLocalizedCurrencyFormatter,
  useSafeLocalizedCompactCurrencyFormatter,
} from 'shared/utils';

import {
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import { styled } from '@stitches/react';
import { IconTableExport } from '@tabler/icons-react';

const Dot = styled('div', {
  width: 12,
  height: 12,
  borderRadius: '50%',
});

interface AgedBalanceOverTimeChartProps {
  agedBalanceOverTime?: AgedBalanceOverTime;
  isBalanceLoading: boolean;
}

type LinearFilters = {
  id: string;
  color: string;
  data: Array<{ x: string; y: number; color: string }>;
};

const handleCsvExport = (
  { data }: AgedBalanceOverTime,
  filters: Array<LinearFilters>,
  t: TranslateFunction,
) => {
  const headers = ['Date', ...filters.map((filter) => filter.id), 'Total'];

  const rows = Object.entries(data).map(([date, { credits, debits }]) => {
    const keyMap = {
      [t(i18nKeys.ANALYTICS.AGED_BALANCE.notDue)]: 'notDue',
      [t(i18nKeys.ANALYTICS.AGED_BALANCE[0])]: '0',
      [t(i18nKeys.ANALYTICS.AGED_BALANCE[30])]: '30',
      [t(i18nKeys.ANALYTICS.AGED_BALANCE[60])]: '60',
      [t(i18nKeys.ANALYTICS.AGED_BALANCE[90])]: '90',
    };

    const values = filters.map((filter) => {
      const key = keyMap[filter.id];
      return `${(parseFloat(String(debits[key])) - parseFloat(String(credits[key]))).toFixed(2)}€`;
    });

    const total = values.reduce((sum, value) => sum + parseFloat(value), 0).toFixed(2);
    return `${date},${values.join(',')},${total}€`;
  });

  const csvContent = [headers.join(','), ...rows].join('\n');
  const blob = new Blob([`\uFEFF${csvContent}`], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', 'export.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CustomSlicedTooltip = ({ slice }: SliceTooltipProps) => {
  const formatter = useLocalizedCurrencyFormatter();
  const total = slice.points[0].data.yStacked;

  return (
    <Box
      p="md"
      bg="white"
      style={{
        borderRadius: 'var(--mantine-radius-xs)',
        boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.05)',
      }}
    >
      <Center>
        <Text mb="sm" fw="bold">
          {dayjs(slice.points[0].data.x).format('LL')}
        </Text>
      </Center>
      <Group>
        <Stack>
          {slice.points.map((point) => (
            <Group key={point.id}>
              <Dot style={{ backgroundColor: point.serieColor }} />
              <Text my="0">{point.serieId}</Text>
            </Group>
          ))}
          <Divider my="xs" w="115%" />
          <Group mt={0}>
            <Dot style={{ opacity: 0 }} />
            <Text my="0">Total</Text>
          </Group>
        </Stack>
        <Stack>
          {slice.points.map((point) => (
            <Text my="0" fw="bold" key={point.id}>
              {formatter.format(Number(point.data.y))}
            </Text>
          ))}
          <Divider my="xs" />
          <Text mt={0} fw="bold">
            {formatter.format(Number(total))}
          </Text>
        </Stack>
      </Group>
    </Box>
  );
};

export const AgedBalanceOverTimeChart = ({
  agedBalanceOverTime,
  isBalanceLoading,
}: AgedBalanceOverTimeChartProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const colors = useAgedBalanceColors();
  const formatter = useLocalizedCurrencyFormatter();
  const compactFormatter = useSafeLocalizedCompactCurrencyFormatter();
  const form = useForm({
    initialValues: {
      '0': true,
      '1': true,
      '2': true,
      '3': true,
      '4': true,
    },
  });

  if (isNullish(agedBalanceOverTime)) return null;

  const lines = Object.entries(omit(colors, ['netBalance', 'totalBalance'])).map(
    ([key, color]) => ({
      id: t(i18nKeys.ANALYTICS.AGED_BALANCE[key]),
      color,
      data: Object.entries(agedBalanceOverTime.data).map(([date, balance]) => ({
        x: date,
        y: balance.debits[key] - balance.credits[key],
        color,
      })),
    }),
  );

  const linesOrdered = [last(lines)!, ...initial(lines)];
  const indicesToHide = Object.entries(form.values).reduce((acc, [index, flag]) => {
    if (!flag) return [...acc, Number(index)];
    return acc;
  }, [] as Array<number>);

  const linesFiltered = linesOrdered.filter((_, index) => !indicesToHide.includes(index));
  const isOnlyOneLineEnabled = linesFiltered.length === 1;

  return (
    <Card radius="md" shadow="sm" style={{ overflow: 'visible' }} w="100%" h={550}>
      <Card.Section inheritPadding withBorder py="xs" mb="md">
        <LoadingOverlay visible={isBalanceLoading} />
        <Group justify="space-between">
          <Title order={4}>{t(i18nKeys.DETAILS)}</Title>

          <Button
            onClick={() => handleCsvExport(agedBalanceOverTime, linesFiltered, t)}
            variant="light"
            size="xs"
            leftSection={<IconTableExport stroke="1.5" size={14} />}
          >
            {t(i18nKeys.EXPORT)}
          </Button>
        </Group>
      </Card.Section>
      <Box w="100%" h={400}>
        <ResponsiveLine
          yScale={{
            stacked: true,
            type: 'linear',
          }}
          colors={(d) => d.color}
          margin={{ top: 10, left: 85, bottom: 50, right: 50 }}
          data={linesFiltered}
          isInteractive
          enableSlices="x"
          enableGridX
          lineWidth={4}
          pointSymbol={CustomPointSymbol}
          yFormat={(val) => formatter.format(Number(val))}
          axisBottom={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: -25,
            format: (date) => dayjs(date).format('DD MMM'),
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 20,
            format: (value) => compactFormatter.format(value),
          }}
          curve="monotoneX"
          enableArea
          areaOpacity={0.7}
          sliceTooltip={CustomSlicedTooltip}
          theme={{
            grid: {
              line: {
                stroke: theme.colors.gray[3],
                strokeDasharray: 7,
                strokeDashoffset: 15,
              },
            },
            labels: {
              text: {
                fontSize: 15,
                fontWeight: 500,
                fill: theme.colors.gray[6],
              },
            },
            text: {
              fontSize: 14,
              fill: theme.colors.gray[6],
            },
          }}
        />
        <Group w="100%" mt="xl" mb="md" justify="center" gap="xl">
          {linesOrdered.map((line, index) => (
            <Checkbox
              size="xs"
              style={{ cursor: 'pointer' }}
              key={index}
              {...form.getInputProps(String(index), { type: 'checkbox' })}
              label={line.id}
              color={line.color}
              disabled={isOnlyOneLineEnabled && form.values[String(index)]}
            />
          ))}
        </Group>
      </Box>
    </Card>
  );
};

const CustomPointSymbol = ({ color }) => {
  const colors = useAgedBalanceColors();
  const accentColors = useAgedBalanceAccentColors();

  const colorsReversed = invert(colors);
  const keyForThisLine = colorsReversed[color];
  const accentColor = accentColors[keyForThisLine];

  return (
    <g>
      <circle r={7} fill={accentColor} />
      <circle r={5} strokeWidth={2} stroke="white" fill={color} />
    </g>
  );
};
