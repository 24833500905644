import { useLayoutEffect, useState } from 'react';
import { useDeleteUnvalidatedImports, useGetDebtorsToConfirm } from 'api';
import { GetDebtorsToConfirmParams } from 'api/models/getDebtorsToConfirmParams';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import ClientListItem from 'shared/components/ClientListItem/ClientListItem';
import CustomTable from 'shared/components/CustomTable';
import { useProfile } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { clientActions } from 'store/client/client.actions';

import { LoadingOverlay } from '@mantine/core';

import styleIdentifiers from './ClientsToConfirmList.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(ClientListItem);

export const ClientsToConfirmList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const profile = useProfile();
  const queryClient = useQueryClient();

  const [params, setParams] = useState<GetDebtorsToConfirmParams>({
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
  });

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useLayoutEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<GetDebtorsToConfirmParams>(Object.fromEntries(new URLSearchParams(location.search)), [
        'page',
        'page_limit',
        'sort_by',
        'sort_order',
      ]),
    }));
  }, [location.search]);

  const {
    data: debtorsToConfirmListResponse,
    isLoading,
    isSuccess,
    isError,
  } = useGetDebtorsToConfirm(params);

  const { mutate: deleteUnvalidatedImports } = useDeleteUnvalidatedImports();

  if (isLoading && !debtorsToConfirmListResponse) {
    return <LoadingOverlay visible />;
  }

  if (isError || !debtorsToConfirmListResponse) {
    return <span>Error</span>;
  }

  const handleDeleteUnvalidatedImports = () => {
    setIsDeleting(true);
    deleteUnvalidatedImports(
      { data: { only_debtor: true } },
      {
        onError: () => {
          setIsDeleting(false);
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
          history.push('/clients');
        },
      },
    );
  };

  const items = debtorsToConfirmListResponse.data || [];
  const isPendingImportOngoing = debtorsToConfirmListResponse.pending_import;
  const CLIENTS_TABLE_HEADERS = [
    {
      key: 'full_name',
      title: t(i18nKeys.NAME),
      width: 160,
    },
    {
      key: 'vat_number',
      title: t(i18nKeys.VAT_NUMBER),
      width: 140,
    },
    {
      key: 'emails',
      title: t(i18nKeys.EMAIL),
      width: 160,
    },
    {
      key: 'main_phone',
      title: t(i18nKeys.PHONE),
      width: 140,
    },
    {
      key: 'city',
      title: t(i18nKeys.FORM.ADDRESS.CITY),
      width: 120,
    },
    {
      key: 'country',
      title: t(i18nKeys.FORM.ADDRESS.COUNTRY),
      width: 120,
    },

    {
      title: '',
      width: 120,
    },
  ];

  const handleFilters = (newValues) => {
    const optimisticResult = {
      ...params,
      ...pick(newValues, ['page_limit', 'page']),
    };
    setParams((current) => ({
      ...current,
      ...pick(newValues, ['page_limit', 'page']),
    }));
    return optimisticResult;
  };

  const onSortChange = (newParams: GetDebtorsToConfirmParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']),
    }));
  };

  const action = (actionToSend) => () =>
    actionToSend({
      callback: () => {
        history.push('/clients');
      },
    });

  return (
    <div className={styles('client-list-to-confirm')}>
      <EnhancedTable
        title={t(i18nKeys.CLIENT.IMPORT.CLIENTS_TO_CONFIRM)}
        actions={
          isPendingImportOngoing || items.length === 0 ? null : (
            <>
              <Button
                onClick={handleDeleteUnvalidatedImports}
                color={ButtonColor.GREY}
                label={t(i18nKeys.INVOICING.IMPORT.DELETE_ALL)}
                noMargin
                disabled={items.filter((item) => !item.attributes.csv_updated).length === 0}
                isLoading={isDeleting}
              />
              <Button
                onClick={action(clientActions.validateAllImportedClients)}
                color={ButtonColor.GREEN}
                label={t(i18nKeys.INVOICING.IMPORT.VALIDATE_ALL)}
                noMargin
                disabled={isDeleting}
              />
            </>
          )
        }
        noCheckbox
        onSortChange={onSortChange}
        loading={isLoading}
        loaded={isSuccess}
        pagination={
          isPendingImportOngoing ? undefined : debtorsToConfirmListResponse.metadata.pagination
        }
        headers={CLIENTS_TABLE_HEADERS}
        handleFilters={handleFilters}
        items={isPendingImportOngoing ? [] : items}
        itemProps={{ notValidate: true, isLoading: isDeleting }}
        pendingImport={isPendingImportOngoing}
      />
    </div>
  );
};
