import { i18nKeys, useTranslation } from 'locales';
import moment, { Moment } from 'moment';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Activity, EmailDeliveryStatus } from 'types/store/activity';

import { styled } from '@stitches/react';

import { Icon, IconName } from '../Icon';

const PRECEDENCE = [
  EmailDeliveryStatus.BOUNCE,
  EmailDeliveryStatus.DROPPED,
  EmailDeliveryStatus.CLICK,
  EmailDeliveryStatus.OPEN,
  EmailDeliveryStatus.DELIVERED,
  EmailDeliveryStatus.PROCESSED,
  EmailDeliveryStatus.DEFERRED,
];

/**
 * Returns the highest precedence status and the corresponding timestamp.
 */
function getHighestPrecedenceStatus(statuses: NonNullable<Activity['email_statuses']>): {
  status: EmailDeliveryStatus;
  timestamp: Moment;
} {
  const availableKeys = Object.keys(statuses) as Array<EmailDeliveryStatus>;
  const sortedKeys = availableKeys.sort((a, b) => PRECEDENCE.indexOf(a) - PRECEDENCE.indexOf(b));
  const highestPrecedenceKey = sortedKeys[0];
  const correspondingDates = statuses[highestPrecedenceKey]!;
  const correspondingDatesParsed = correspondingDates.map((date) => moment(date));

  return {
    status: highestPrecedenceKey,
    timestamp: moment.max(correspondingDatesParsed),
  };
}

const Status = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '5px;',
  fontWeight: 'bold',
  fontSize: '0.8rem',
  variants: {
    status: {
      [EmailDeliveryStatus.PROCESSED]: {
        color: '#FFA849',
      },
      [EmailDeliveryStatus.DELIVERED]: {
        color: '#FFA849',
      },
      [EmailDeliveryStatus.DEFERRED]: {
        color: '#FFA849',
      },
      [EmailDeliveryStatus.OPEN]: {
        color: '#8663E8',
      },
      [EmailDeliveryStatus.CLICK]: {
        color: '#8663E8',
      },
      [EmailDeliveryStatus.BOUNCE]: {
        color: '#EC5962',
      },
      [EmailDeliveryStatus.DROPPED]: {
        color: '#EC5962',
      },
    },
  },
});

const TimeStamp = styled('div', {
  fontSize: '0.7rem',
  color: '#bbb',
  fontStyle: 'italic',
  fontWeight: 'unset',
});

const ICON_FOR_STATUS: Record<EmailDeliveryStatus, IconName> = {
  [EmailDeliveryStatus.PROCESSED]: IconName.LATER,
  [EmailDeliveryStatus.DEFERRED]: IconName.LATER,
  [EmailDeliveryStatus.DELIVERED]: IconName.CHECK,
  [EmailDeliveryStatus.OPEN]: IconName.EYE,
  [EmailDeliveryStatus.CLICK]: IconName.CHECK,
  [EmailDeliveryStatus.BOUNCE]: IconName.ALERT_CIRCLE,
  [EmailDeliveryStatus.DROPPED]: IconName.ALERT_CIRCLE,
};

interface EmailDeliveryStatusCompactProps {
  statuses: NonNullable<Activity['email_statuses']>;
}

export const EmailDeliveryStatusCompact = ({ statuses }: EmailDeliveryStatusCompactProps) => {
  const { t } = useTranslation();
  const { status, timestamp } = getHighestPrecedenceStatus(statuses);
  const {
    company: {
      package: { can_use_emails_activity: canUseEmailStatus },
    },
  } = useLoadCompanyConfiguration();

  if (!canUseEmailStatus) return null;

  return (
    <Status status={status}>
      <Icon name={ICON_FOR_STATUS[status]} /> {t(i18nKeys.MAILS.STATUS[status.toUpperCase()])}
      {status !== EmailDeliveryStatus.BOUNCE && <TimeStamp>{timestamp.format('L')}</TimeStamp>}
    </Status>
  );
};
