import React, { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { partition } from 'remeda';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { useIsMobile } from 'shared/hooks/utils';
import { getEnvIcon } from 'shared/utils/environment';

import { Title } from '@mantine/core';
import { styled } from '@stitches/react';

const Actions = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
});

interface TitleActionsProps {
  children: React.ReactNode;
}

const TitleActions = ({ children }: TitleActionsProps) => {
  if (children == null) return null;
  return <Actions>{children}</Actions>;
};

TitleActions.displayName = 'TitleActions';

const Header = styled('div', {
  marginBottom: '20px',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',

  variants: {
    isMobile: {
      true: {
        height: 'unset',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
});

interface PageTitleProps {
  children: React.ReactNode;
}

export const PageTitle = ({ children }: PageTitleProps) => {
  const isMobile = useIsMobile();
  const { company } = useLoadCompanyConfiguration();
  const companyName = company.name ?? '';

  const childrenArray = React.Children.toArray(children);
  const [actions, title] = partition(
    childrenArray,
    (child) =>
      React.isValidElement(child) &&
      typeof child !== 'string' &&
      typeof child.type !== 'string' &&
      // @ts-ignore
      child.type.displayName === 'TitleActions',
  );

  useEffect(() => {
    // Remove any icons or other elements from the title we display in the tab
    const titleString = title.filter((e) => typeof e === 'string').join(' ');
    document.title = `${getEnvIcon()}${companyName} - ${titleString}`;

    return function cleanup() {
      document.title = `${getEnvIcon()}${companyName ?? 'Recovr'}`;
    };
  }, [title, companyName]);

  if (actions.length > 1) {
    console.error('PageTitle can only have one TitleActions child');
    return null;
  }

  return (
    <Header isMobile={isMobile}>
      <Title
        order={4}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          marginBottom: 'unset',
        }}
      >
        {title}
      </Title>
      {!isEmpty(actions) ? <Actions>{actions}</Actions> : null}
    </Header>
  );
};

PageTitle.Actions = TitleActions;
