import { ExternalMailStatus, Id, TaskStatus } from 'api/models';
import { i18nKeys, useTranslation } from 'locales';
import HTML from 'shared/components/HTML/HTML';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';
import { RecentActivities as RecentActivitiesType } from 'shared/hooks/use-load-dashboard';
import { Panel } from 'shared/layout';

import { css } from '@emotion/css';
import { MantineTheme, Stack, useMantineTheme } from '@mantine/core';
import {
  IconBuildingBank,
  IconCalendarExclamation,
  IconFileInvoice,
  IconFolder,
  IconListCheck,
  IconMailExclamation,
} from '@tabler/icons-react';

import { ActivityItem } from '../ActivityItem';

const boldStyles = (theme: MantineTheme) => css`
  color: ${theme.colors.gray[7]};

  b {
    font-weight: 500;
    color: ${theme.colors.gray[10]};
  }
`;

type Props = {
  data: RecentActivitiesType;
  accountManagerId?: Id;
};

export default function RecentActivities({ data, accountManagerId }: Props) {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const { company } = useLoadCompanyConfiguration();
  const profile = useProfile();

  const hasInvoicesToTreat = data.invoicesToTreat > 0;
  const shouldDisplayUnprocessedTransactions =
    company.codabox_integration.active || company.ponto_integration.active;

  return (
    <Panel title={t(i18nKeys.YOUR_ACCOUNT)}>
      <Stack>
        <ActivityItem
          url={`/invoices/to-handle${accountManagerId ? `?user_id=${accountManagerId}` : ''}`}
          Icon={IconFileInvoice}
          hasInvoicesToTreat={hasInvoicesToTreat}
        >
          <HTML
            html={t(
              data.invoicesToTreat > 1
                ? i18nKeys.ACTIVITIES.INVOICES_TO_PAY_ATTENTION
                : i18nKeys.ACTIVITIES.INVOICE_TO_PAY_ATTENTION,
              { count: data.invoicesToTreat },
            )}
            className={boldStyles(theme)}
          />
        </ActivityItem>

        {company.package.can_use_tasks && (
          <ActivityItem
            url={`/tasks?status=${TaskStatus.to_do}&user_id=${accountManagerId ?? profile.id}`}
            Icon={IconListCheck}
          >
            <HTML
              html={t(
                data.ongoingTasksCount > 1
                  ? i18nKeys.DASHBOARD.ONGOING_TASKS_COUNT
                  : i18nKeys.DASHBOARD.ONGOING_TASK_COUNT,
                { count: data.ongoingTasksCount },
              )}
              className={boldStyles(theme)}
            />
          </ActivityItem>
        )}

        {shouldDisplayUnprocessedTransactions && (
          <ActivityItem url="/transactions/reconciliation" Icon={IconBuildingBank}>
            <HTML
              html={t(
                data.paymentCount > 1
                  ? i18nKeys.ACTIVITIES.UNPROCESSED_TRANSACTIONS
                  : i18nKeys.ACTIVITIES.UNPROCESSED_TRANSACTION,
                { count: data.paymentCount },
              )}
              className={boldStyles(theme)}
            />
          </ActivityItem>
        )}

        {company.package.can_use_external_mails && (
          <ActivityItem
            url={`/mails?status=${ExternalMailStatus.unprocessed}${
              accountManagerId != null ? `&account_manager_id=${accountManagerId}` : ''
            }`}
            Icon={IconMailExclamation}
          >
            <HTML
              html={t(
                data.unprocessedEmailsCount > 1
                  ? i18nKeys.DASHBOARD.UNPROCESSED_EMAILS_COUNT
                  : i18nKeys.DASHBOARD.UNPROCESSED_EMAIL_COUNT,
                { count: data.unprocessedEmailsCount },
              )}
              className={boldStyles(theme)}
            />
          </ActivityItem>
        )}

        {data.unreadThirdPartyMessagesCount > 0 && (
          <ActivityItem url="/thirdparty_cases?unread_messages=true" Icon={IconFolder}>
            <HTML
              html={t(i18nKeys.DASHBOARD.UNREAD_THIRD_PARTY_MESSAGES_COUNT, {
                pluralizedCount: `${data.unreadThirdPartyMessagesCount} ${t(
                  data.unreadThirdPartyMessagesCount === 1
                    ? i18nKeys.THIRD_PARTY_CASES.ONE
                    : i18nKeys.THIRD_PARTY_CASES.MANY,
                )}`,
              })}
              className={boldStyles(theme)}
            />
          </ActivityItem>
        )}

        {data.failedRemindersCount > 0 && (
          <ActivityItem url="/reminders/failed" Icon={IconCalendarExclamation}>
            <HTML
              html={t(
                data.failedRemindersCount > 1
                  ? i18nKeys.DASHBOARD.FAILED_REMINDERS_COUNT
                  : i18nKeys.DASHBOARD.FAILED_REMINDER_COUNT,
                { count: data.failedRemindersCount },
              )}
              className={boldStyles(theme)}
            />
          </ActivityItem>
        )}
      </Stack>
    </Panel>
  );
}
