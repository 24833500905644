import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { OnClickFunction } from 'types/html-type';

import { Title } from '@mantine/core';

import styleIdentifiers from './card.module.scss';

const styles = classNames.bind(styleIdentifiers);
type Props = {
  title?: ReactNode;
  titleIcon?: ReactNode;
  classTitle?: string;
  classContent?: string;
  className?: string;
  infosRight?: ReactNode;
  bottom?: ReactNode;
  noHead?: boolean;
  subtitle?: ReactNode;
  noPadding?: boolean;
  actionHead?: OnClickFunction;
  noRadiusTop?: boolean;
  noBorder?: boolean;
};

export default function Card({
  title,
  titleIcon,
  className,
  children,
  classTitle,
  classContent,
  infosRight,
  bottom,
  noHead,
  noBorder,
  subtitle,
  actionHead,
  noRadiusTop,
  noPadding,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={styles(
        'Card',
        className,
        'card',
        noRadiusTop && 'no-radius-top',
        noBorder && 'no-border',
      )}
    >
      {!noHead && (
        <div className={styles('head', classTitle, actionHead && 'link')} onClick={actionHead}>
          <div className={styles(subtitle && 'head-content')}>
            <Title order={4}>
              {title} {titleIcon}
            </Title>
            {subtitle && <span className={styles('subtitle')}>{subtitle}</span>}
          </div>
          {infosRight && <span className={styles('infos-right')}>{infosRight}</span>}
        </div>
      )}
      <div className={styles('content', noPadding && 'no-padding', classContent)}>{children}</div>
      {bottom && <div className={styles('bottom')}>{bottom}</div>}
    </div>
  );
}
