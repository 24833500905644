import { useCreateContactPerson, useGetContactPersons } from 'api';
import { CreateContactPersonBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import { useMantineTheme } from '@mantine/core';

import { ContactPersonForm } from '../ContactPersonForm/ContactPersonForm';

import { ContactPersonsListItem } from './ContactPersonsListItem';

import styleIdentifiers from './ContactPersonsList.module.scss';

const EnhancedTable = CustomTable(ContactPersonsListItem);

const styles = classNames.bind(styleIdentifiers);

type ContactPersonsListProps = {
  debtor: Debtor;
};

export const ContactPersonsList = ({ debtor }: ContactPersonsListProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const { mutate: createContactPersonMutation } = useCreateContactPerson();

  const {
    data: contactPersonsResponse,
    isLoading,
    isFetched,
    refetch,
  } = useGetContactPersons(debtor.id);

  const TABLE_HEADERS = [
    {
      title: t(i18nKeys.FORM.STRIPE.FULL_NAME),
    },
    {
      title: t(i18nKeys.FORM.JOB_TITLE),
    },
    {
      title: t(i18nKeys.FORM.NOTIFICATIONS),
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  const addContactPerson = () =>
    sideMenuShow({
      unmount: true,
      content: (
        <ContactPersonForm
          onSubmit={(data: CreateContactPersonBody) =>
            createContactPersonMutation(
              { id: debtor.id, data },
              {
                onSuccess: () => {
                  refetch();
                  sideMenuHide();
                },
              },
            )
          }
        />
      ),
    });

  return (
    <div className={styles('listing')}>
      <EnhancedTable
        title={t(i18nKeys.CONTACT_PERSON.TITLE)}
        actions={
          <Button
            label={t(i18nKeys.ACTIONS.ADD)}
            style={{ backgroundColor: theme.colors.purple[4] }}
            onClick={addContactPerson}
            iconRight={IconName.MINIMAL_RIGHT}
            noMargin
          />
        }
        loading={isLoading}
        loaded={isFetched}
        headers={TABLE_HEADERS}
        items={contactPersonsResponse?.data || []}
        itemProps={{ debtor, refetch }}
        noCheckbox
        noShadow
        noFooter
        noSetTabTitle
      />
    </div>
  );
};
