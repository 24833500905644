import { PartialDeep } from 'type-fest';

import { MantineTheme, mergeThemeOverrides } from '@mantine/core';

import { ThemedMantineButton } from './Button';
import { ThemedMantineCard } from './Card';
import { ThemedMantineIndicator } from './Indicator';
import { ThemedMantineModal } from './Modal';
import { ThemedMantineNavLink } from './NavLink';
import { ThemedMantinePagination } from './Pagination';
import { ThemedMantineSelect } from './Select';
import { ThemedMantineTable } from './Table';
import { ThemedMantineText } from './Text';
import { ThemedMantineTitle } from './Title';

export const componentsTheme: PartialDeep<MantineTheme> = mergeThemeOverrides(
  ThemedMantineButton,
  ThemedMantineTable,
  ThemedMantineTitle,
  ThemedMantineModal,
  ThemedMantineIndicator,
  ThemedMantineNavLink,
  ThemedMantineCard,
  ThemedMantineText,
  ThemedMantineSelect,
  ThemedMantinePagination,
);
