import { RefObject, useEffect, useState } from 'react';

export const useIsWidthBelowThreshold = (ref: RefObject<HTMLElement>, threshold = 300) => {
  const [isSmaller, setIsSmaller] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(([entry]) => {
      const { width } = entry.contentRect;
      setIsSmaller(width < threshold);
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, threshold]);

  return isSmaller;
};
