import { Reducer, useEffect, useMemo, useReducer } from 'react';
import AnimateNumber from 'animated-number-react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import Amount from 'shared/components/Amount';
import CustomTable from 'shared/components/CustomTable';
import HTML from 'shared/components/HTML/HTML';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button } from 'shared/io';
import { treatBillingStatement, treatInvoiceActionData } from 'shared/serializer';
import { currencySymbol, formatAmount } from 'shared/utils';
import { reducerState } from 'shared/utils/view';
import { GroupedInvoiceItem, GroupedInvoicesType } from 'types/store/invoice-state';

import ChooseGroupedItem from './ChooseInvoiceItem/ChooseGroupedItem';

import styleIdentifiers from './ChooseInvoicesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

const GroupedTable = CustomTable(ChooseGroupedItem);

type Props = {
  onSubmit: (val: any) => any;
  type: GroupedInvoicesType;
  getData: Function;
};

type FormValuesItem = {
  add: boolean;
  id: number;
};

type State = Reducer<
  {
    data: null | {
      additional_invoices: GroupedInvoiceItem[];
      main_invoice: GroupedInvoiceItem;
      credit_notes: any[];
      accounting_payments: any[];
      tp_payments: any[];
    };
    formValues: {
      additional_invoices: FormValuesItem[];
      credit_notes: FormValuesItem[];
      accounting_payments: FormValuesItem[];
      tp_payments: FormValuesItem[];
    };
    loading: boolean;
  },
  any
>;

export default function ChooseInvoicesForm({ onSubmit, type, getData }: Props) {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const text = useMemo(
    () => ({
      summary: {
        lawyer: 'FORM.CHOOSE_INVOICES.BAILIFF_DESCRIPTION',
        debtCollector: 'FORM.CHOOSE_INVOICES.BAILIFF_DESCRIPTION',
        bailiff: 'FORM.CHOOSE_INVOICES.BAILIFF_DESCRIPTION',
      },
    }),
    [],
  );

  const form = useForm();
  const [{ formValues, loading, data }, _setFormValues] = useReducer<State>(reducerState, {
    formValues: {
      additional_invoices: [],
      credit_notes: [],
      tp_payments: [],
      accounting_payments: [],
    },
    data: null,
    loading: true,
  });

  useEffect(() => {
    getData((res) => {
      const values = treatBillingStatement(res.data);

      if (
        values.additional_invoices.length +
          values.tp_payments.length +
          values.credit_notes.length +
          values.accounting_payments.length ===
        0
      ) {
        onSubmit({
          invoiceActionData: treatInvoiceActionData(res.data),
        });
      } else {
        _setFormValues({
          formValues: {
            additional_invoices: values.additional_invoices.map((item) => ({
              id: item.id,
            })),
            tp_payments: values.tp_payments.map((item) => ({ id: item.id })),
            credit_notes: values.credit_notes.map((item) => ({ id: item.id })),
            accounting_payments: values.accounting_payments.map((item) => ({
              id: item.id,
            })),
          },
          data: values,
        });
      }
    });
    setTimeout(() => {
      _setFormValues({
        loading: false,
      });
    }, 300);
  }, []);

  const setFormValues = (newFormValue) => {
    _setFormValues({
      formValues: { ...formValues, ...newFormValue },
    });
  };
  const invoices = data
    ? [
        ...data!.additional_invoices,
        ...(data!.credit_notes || []),
        ...data!.accounting_payments,
        ...data!.tp_payments,
      ]
    : null;
  const mainInvoice = data?.main_invoice;

  const totalAmount = useMemo(
    (localized = true) => {
      if (!data) return 0;

      let invoiceAmount = 0;

      if (mainInvoice) {
        const priceObject = localized ? mainInvoice.localized_money_object : mainInvoice;
        invoiceAmount = priceObject.remaining_balance;
      }

      let invoiceAmountTotal = 0;
      invoices!.forEach((invoiceAcc: any) => {
        const itemValue = formValues[invoiceAcc.itemType].find(
          (item: any) => item.id === invoiceAcc.id,
        );
        const priceObject = localized ? invoiceAcc.localized_money_object : invoiceAcc;

        if (itemValue.add) {
          if (invoiceAcc.type === 'fees_preview_invoice') {
            const value = Number(priceObject.remaining_balance);
            invoiceAmountTotal += value;
          } else if (invoiceAcc.type === 'grouped_credit_note') {
            const value = priceObject.remaining_balance
              ? Number(priceObject.remaining_balance)
              : -(Number(priceObject.remaining_balance) || Number(priceObject.amount));
            invoiceAmountTotal += value;
          } else {
            const value = -(Number(priceObject.remaining_balance) || Number(priceObject.amount));
            invoiceAmountTotal += value;
          }
        }
      });

      return +invoiceAmount + invoiceAmountTotal;
    },
    [formValues, invoices],
  );

  const toggleCheckAll = (checked) => {
    const newFormValues = {};
    for (const item in formValues) {
      newFormValues[item] = formValues[item].map((item) => ({
        ...item,
        add: checked,
      }));
    }
    setFormValues(newFormValues);
  };

  const submit = () => {
    onSubmit({
      tp_payments: formValues.tp_payments.filter((value) => value.add).map((item) => item.id),
      accounting_payments: formValues.accounting_payments
        .filter((value) => value.add)
        .map((item) => item.id),
      credit_notes: formValues.credit_notes.filter((value) => value.add).map((item) => item.id),
      additional_invoices: formValues.additional_invoices
        .filter((value) => value.add)
        .map((item) => item.id),
      invoiceActionData: treatInvoiceActionData(data),
    });
  };

  const updatedAmountBalanceWithFees = (localized = false) => {
    const priceObject = localized ? mainInvoice!.localized_money_object : mainInvoice!;

    return priceObject!.remaining_balance;
  };

  const toggleAdd = (type: string, id: number) => (checked) => {
    const newFormValues = formValues[type].slice();
    const itemIndex = newFormValues.findIndex((item) => item.id === id);
    newFormValues[itemIndex].add = checked;
    setFormValues({
      [type]: newFormValues,
    });
  };

  return data && !loading ? (
    <div className={styles('choose-invoice-form')}>
      <div className={styles('description')}>
        <HTML html={t(text.summary[type] || i18nKeys.FORM.CHOOSE_INVOICES.DESCRIPTION)} />
      </div>
      <div className={styles('description')}>
        <HTML html={t(i18nKeys.FORM.CHOOSE_INVOICES.LATE_FEE_DISCLAIMER)} />
      </div>
      <div className={styles('main-invoice')}>
        <div className={styles('title')}>{t(i18nKeys.FORM.CHOOSE_INVOICES.MAIN_INVOICE)}</div>
        <div className={styles('info')}>
          <div>
            <span>{t(i18nKeys.FORM.REFERENCE)}:</span> {mainInvoice!.reference}
          </div>

          <div>
            <span>{t(i18nKeys.TOTAL_VAT_PRICE)}:</span>
            <Amount
              localizedValue={mainInvoice!.total_tvac}
              value={mainInvoice!.localized_money_object.total_tvac}
              suffix={mainInvoice?.currency}
              className={styles('value', 'bigger')}
            />
          </div>
          <div>
            <span>{t(i18nKeys.INVOICING.ALREADY_PAID)}:</span>
            <Amount
              localizedValue={mainInvoice!.already_paid_amount}
              value={mainInvoice!.localized_money_object.already_paid_amount}
              suffix={mainInvoice?.currency}
              className={styles('value', 'bigger')}
            />
          </div>
          <div>
            <span> {t(i18nKeys.BALANCE)}: </span>
            <Amount
              localizedValue={updatedAmountBalanceWithFees()}
              value={updatedAmountBalanceWithFees(true)}
              suffix={mainInvoice?.currency}
              className={styles('value', 'bigger')}
            />
          </div>
        </div>
      </div>

      <FormProvider {...form}>
        <div>
          <GroupedTable
            className={styles('invoice-table')}
            customKey={(item) => `${item.itemType}_${item.id}`}
            onToggleCheckAll={(check) => toggleCheckAll(check)}
            items={invoices!}
            noShadow
            noMargin
            itemProps={{ formValues, toggleAdd }}
            headers={[
              {
                title: t(i18nKeys.FORM.TYPE),
              },
              {
                title: t(i18nKeys.FORM.REFERENCE),
              },

              {
                title: t(i18nKeys.DATE),
              },
              {
                title: t(i18nKeys.AMOUNT),
              },
              {
                title: t(i18nKeys.INVOICING.ALREADY_PAID),
              },
              {
                title: t(i18nKeys.BALANCE),
              },
            ]}
          />
        </div>
      </FormProvider>
      <div className={styles('resume')}>
        <div>
          <div className={styles('title')}>
            <div>{t(i18nKeys.FORM.CHOOSE_INVOICES.AMOUNT_CLAIMED)} :</div>
            <div className={styles('late-fee-disclaimer')}>
              {t(i18nKeys.FORM.CHOOSE_INVOICES.NO_LATE_FEE_CLAIMED)}
            </div>
          </div>
          <AnimateNumber
            duration={400}
            value={totalAmount}
            formatValue={(value) =>
              `${formatAmount(value, ',', '.', 2, currencySymbol(undefined, company))}`
            }
          />
        </div>

        <Button onClick={submit} className={styles('button')} label={t(i18nKeys.CONTINUE)} />
      </div>
    </div>
  ) : null;
}
