import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLoadLightDebtors } from 'shared/hooks';
import { useSearchInvoices } from 'shared/utils/hooks';
import { LightInvoice } from 'types/store/invoice-state';

import { Box, Kbd, Space, Text, Title } from '@mantine/core';
import { Spotlight } from '@mantine/spotlight';

import { spotlightStylesOverride } from './NavigationSpotlight';

export const SearchSpotlight = () => {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [invoices, setInvoices] = useState<Array<LightInvoice>>([]);
  const { getInvoices } = useSearchInvoices();
  const { lightDebtors } = useLoadLightDebtors({
    query: query.startsWith('@') ? query.slice(1) : '',
  });

  useEffect(() => {
    if (query.startsWith('#')) {
      getInvoices({ reference: query.slice(1) }, ({ data }) => setInvoices(data));
    }
  }, [query]);

  const actions = (() => {
    if (query.startsWith('@')) {
      return (lightDebtors ?? []).map(({ id, fullName }) => ({
        id: String(id),
        label: fullName,
        onClick: () => history.push(`/clients/${id}`),
      }));
    }

    if (query.startsWith('#')) {
      console.log(invoices);
      return invoices.map((invoice) => ({
        id: String(invoice.id),
        // @ts-ignore attributes is poorly typed
        label: invoice.attributes.reference,
        onClick: () => history.push(`/invoices/${invoice.id}`),
      }));
    }

    return [];
  })();

  return (
    <Spotlight
      actions={actions}
      shortcut={[',']}
      centered
      limit={10}
      highlightQuery
      radius={10}
      filter={(_, actionsArray) => actionsArray}
      query={query}
      onQueryChange={setQuery}
      classNames={{
        action: spotlightStylesOverride.action,
      }}
      nothingFound={
        <Box>
          <Title order={3} mb="md">
            No results
          </Title>
          <Text>
            Type <Kbd>@</Kbd> to search though clients
            <Space h="xs" />
            Type <Kbd>#</Kbd> to search though invoices
          </Text>
        </Box>
      }
    />
  );
};
