import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import DownloadInvoice from 'shared/components/DownloadInvoice';
import GradientBorder from 'shared/components/GradientBorder';
import { Button } from 'shared/io';

import {
  InvoiceFormFieldValues,
  InvoiceFormProps,
  InvoiceFormSharedProps,
} from './InvoiceForm.types';

import styleIdentifiers from './InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InvoiceFormContainerProps = InvoiceFormProps & InvoiceFormSharedProps;

export const InvoiceFormContainer = ({
  submit,
  isCreditNote,
  invoice,
  creditNote,
  children,
}: PropsWithChildren<InvoiceFormContainerProps>) => {
  const { t } = useTranslation();

  const { handleSubmit } = useFormContext<InvoiceFormFieldValues>();

  const getTitle = () => {
    if (isCreditNote) {
      return t(creditNote ? i18nKeys.INVOICING.CREATE.EDIT_CREDIT_NOTE : i18nKeys.NEW_CREDIT_NOTE);
    }

    return t(invoice ? i18nKeys.INVOICING.EDIT_INVOICE : i18nKeys.NEW_INVOICE);
  };

  return (
    <form className={styles('InvoiceForm')} onSubmit={handleSubmit(submit)}>
      <div className={styles('head')}>
        <h1 className={styles('title')}>{getTitle()}</h1>
        <div className={styles('actions')}>
          {(invoice || creditNote) && (
            <DownloadInvoice className={styles('space')} invoice={invoice || creditNote} />
          )}
          <Button type="submit" label={t(i18nKeys.SAVE)} noMargin noShadow />
        </div>
      </div>
      <article className={styles('invoice')}>
        <GradientBorder />
        <div className={styles('content')}>{children}</div>
        <GradientBorder bottom />
      </article>
      <div className={styles('bottom-actions')}>
        <Button type="submit" label={t(i18nKeys.SAVE)} noMargin />
      </div>
    </form>
  );
};
