import { Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { isDefined } from 'remeda';
import Amount from 'shared/components/Amount';
import { LoadingPlaceholder } from 'shared/components/LoadingPlaceholder';
import MultiCurrencyData from 'shared/components/MultiCurrencyData';
import { useLoadDetailedDebtorBalance } from 'shared/hooks/use-load-debtors-detailed-balance';

import { Stack } from '@mantine/core';

import styleIdentifiers from './DetailedBalance.module.scss';

const styles = classNames.bind(styleIdentifiers);

type DetailedBalanceProps = {
  accountManagerId?: Id;
};

export function DetailedBalance({ accountManagerId }: DetailedBalanceProps) {
  const { t } = useTranslation();

  const { detailedDebtorBalance, isDetailedDebtorBalanceLoading } = useLoadDetailedDebtorBalance({
    accountManagerId,
  });

  if (isDetailedDebtorBalanceLoading || !isDefined(detailedDebtorBalance)) {
    return (
      <div className={styles('balance-detailed')}>
        <Stack gap={50} mt={20}>
          <LoadingPlaceholder isActive />
          {t(i18nKeys.TOO_LONG_LOADING)}
        </Stack>
      </div>
    );
  }
  const { detailedBalance } = detailedDebtorBalance.data;

  return (
    <div className={styles('balance-detailed')}>
      <div className={styles('item')}>
        <div className={styles('label')}>{t(i18nKeys.AMOUNT_BILLED)}</div>
        <div className={styles('value')}>
          <MultiCurrencyData data={detailedBalance.invoicesTotal} withTotal />
        </div>
      </div>
      <div className={styles('item')}>
        <div className={styles('label')}>{t(i18nKeys.CLAIMED_LATE_FEES)}</div>
        <div className={styles('value')}>
          <MultiCurrencyData data={detailedBalance.claimedFees} withTotal />
        </div>
      </div>
      <div className={styles('item', 'right')}>
        <div className={styles('label')}>{t(i18nKeys.NAV.CREDIT_NOTES)}</div>
        <div className={styles('value', 'color-grey')}>
          <MultiCurrencyData data={detailedBalance.creditNotesTotal} withTotal />
        </div>
      </div>
      <div className={styles('item', 'right')}>
        <div className={styles('label')}>{t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)}</div>
        <div className={styles('value', 'color-grey')}>
          <Amount value={-detailedBalance.paymentsTotal} />
        </div>
      </div>
      <div className={styles('item', 'right')}>
        <div className={styles('label')}>{t(i18nKeys.LOST_SUM)}</div>
        <div className={styles('value', 'color-grey')}>
          <Amount value={-detailedBalance.lostTotal} />
        </div>
      </div>
      <div className={styles('item')}>
        <div className={styles('label')}>
          {t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)} <span>({t(i18nKeys.WITH_FEES)})</span>
        </div>
        <div className={styles('value', 'main-color')}>
          <Amount value={detailedBalance.remainingBalanceWithFees} />
        </div>
      </div>
      <div className={styles('item')}>
        <div className={styles('label')}>{t(i18nKeys.REMAINING_LATE_FEES)}</div>
        <div className={styles('value', 'color-grey')}>
          <Amount value={-detailedBalance.remainingLateFees} />
        </div>
      </div>
      <div className={styles('item')}>
        <div className={styles('label')}>
          {t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)} <span>({t(i18nKeys.WITHOUT_FEES)})</span>
        </div>
        <div className={styles('value', 'main-color')}>
          <Amount value={detailedBalance.remainingBalance} />
        </div>
      </div>
    </div>
  );
}
