import { PropsWithChildren, useEffect } from 'react';
import { CreateWorkflowBody, PlanType, Workflow } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, Input } from 'shared/io';

import { Title } from '@mantine/core';

import { WorkflowStepsTarget } from '../Workflows.constants';

import { WorkflowFormSteps } from './Steps/WorkflowFormSteps';
import { getDefaultValues, getWorkflowRights } from './WorkflowForm.utils';

import styleIdentifiers from './WorkflowForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormProps = {
  planType: PlanType;
  onSubmit: SubmitHandler<CreateWorkflowBody>;
  onDuplicate?: () => void;
  onDelete?: () => void;
  workflow?: Workflow;
  isLoading: boolean;
};

export const WorkflowForm = ({
  planType,
  onSubmit,
  onDuplicate,
  onDelete,
  workflow,
  children,
  isLoading,
}: PropsWithChildren<WorkflowFormProps>) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { company } = useLoadCompanyConfiguration();

  const { canAdd, canEdit, canDuplicate, canDelete } = getWorkflowRights(company, planType);

  const form = useForm<CreateWorkflowBody>({
    defaultValues: getDefaultValues(planType, workflow),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitted },
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultValues(planType, workflow));
  }, [planType, workflow, reset]);

  const isDefault = workflow?.attributes?.default;
  const isUsed = !!workflow?.attributes?.payment_plans_using;

  const getCanDeleteTitle = () => {
    if (!canDelete) {
      return i18nKeys.NOT_INCLUDED;
    }

    if (isDefault) {
      return i18nKeys.USED_DEFAULT;
    }

    if (isUsed) {
      return i18nKeys.IS_USED;
    }

    return i18nKeys.DELETE;
  };

  const getCanDuplicate = () => {
    if (!canDuplicate) {
      return i18nKeys.NOT_INCLUDED;
    }

    return i18nKeys.DUPLICATE;
  };

  return (
    <div className={styles('workflows-form')}>
      <FormProvider {...form}>
        <NavigationPrompt when={isDirty && !isSubmitted} onSaveAndQuit={handleSubmit(onSubmit)} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <header className={styles('head')}>
            <Title order={3}>
              {t(
                workflow
                  ? i18nKeys.WORKFLOW.EDIT[planType.toUpperCase()]
                  : i18nKeys.WORKFLOW.ADD[planType.toUpperCase()],
              )}
            </Title>
            <div className={styles('actions')}>
              {children}
              {onDelete && (
                <Button
                  isLoading={isLoading}
                  label={t(i18nKeys.DELETE)}
                  color={ButtonColor.GREY}
                  onClick={onDelete}
                  disabled={!canDelete || isDefault || isUsed}
                  title={t(getCanDeleteTitle())}
                  noMargin
                />
              )}
              {onDuplicate && (
                <Button
                  isLoading={isLoading}
                  label={t(i18nKeys.DUPLICATE)}
                  onClick={onDuplicate}
                  disabled={!canDuplicate || isDirty}
                  title={getCanDuplicate()}
                  noMargin
                />
              )}
              <Button
                isLoading={isLoading}
                type="submit"
                label={t(i18nKeys.SAVE)}
                color={ButtonColor.MAIN}
                disabled={workflow ? !canEdit : !canAdd}
                title={
                  workflow
                    ? t(!canEdit ? i18nKeys.NOT_INCLUDED : i18nKeys.SAVE)
                    : t(!canAdd ? i18nKeys.NOT_INCLUDED : i18nKeys.SAVE)
                }
                noMargin
              />
            </div>
          </header>
          <div className={styles('container', 'card')}>
            <div className={styles('head')}>
              <div className={styles('title')}>
                <button
                  type="button"
                  className={styles('previous')}
                  onClick={() => {
                    history.push(`/settings/workflows/${planType}`);
                  }}
                >
                  <Icon color={IconColor.GREY} size="18px" name={IconName.MINIMAL_LEFT} />
                </button>
                <Input
                  register={register('name', {
                    required: true,
                  })}
                  errorMessage={get(errors, 'name')}
                  className={styles('input')}
                  placeholder={t(i18nKeys.PLAN.NAME)}
                  disabled={workflow ? !canEdit : !canAdd}
                  noMargin
                />
              </div>
              {workflow && (
                <div className={styles('info')}>
                  {isDefault && <div className={styles('label')}>{t(i18nKeys.DEFAULT)}</div>}
                  <div>{`${t(i18nKeys.FORM.REFERENCE)} : ${workflow.id}`}</div>
                  <div>
                    {`${t(i18nKeys.USED)} : ${workflow.attributes.payment_plans_using || 0}`}
                  </div>
                </div>
              )}
            </div>
            <WorkflowFormSteps planType={planType} name={WorkflowStepsTarget.BEFORE_DUE_DATE} />
            <h3 className={styles('due-date')}>
              <span>{t(i18nKeys.DUE_DATE)}</span>
            </h3>
            <WorkflowFormSteps planType={planType} name={WorkflowStepsTarget.AFTER_DUE_DATE} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
