import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isDefined, isEmpty } from 'remeda';
import { UpsellIcon } from 'shared/components';
import HTML from 'shared/components/HTML/HTML';
import { PdfDocument } from 'shared/components/PdfDocument';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { useIsFeatureFlagEnabled } from 'shared/utils';
import { formattedDate } from 'shared/utils/view';
import { EmailDeliveryStatus } from 'types/store/activity';

import {
  Accordion,
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  Select,
  Space,
  Stack,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { styled } from '@stitches/react';
import { IconArrowLeft, IconSend, IconSendOff } from '@tabler/icons-react';

import { useLoadReminder } from '../api';
import { StatusCard } from '../components';
import { ResendReminderModal, resendReminderModalAtom } from '../components/ResendReminderModal';

const EmailStatusHistory = styled('div', {});

const EmailStatusHistoryItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  padding: '10px 0px 0px 5px',
});

interface ReminderProps {}

export const Reminder = ({}: ReminderProps) => {
  const { t, currentLang } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { reminder, isReminderLoading } = useLoadReminder(id);
  const history = useHistory();

  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [currentRecipient, setCurrentRecipient] = useState<string | null>();
  const [_, dispatch] = useAtom(resendReminderModalAtom);

  const canUseEmailStatus = company.package.can_use_emails_activity;

  useEffect(() => {
    if (reminder != null) {
      const firstRecipient = reminder.emails[0];
      const firstPdf = reminder.letters[0];

      if (isDefined(firstPdf)) setCurrentPdfUrl(firstPdf.url);
      if (isDefined(firstRecipient)) setCurrentRecipient(firstRecipient.email);
    }
  }, [reminder == null, id]);

  const onClickBack = () => {
    if (location.pathname.includes('failed')) history.push('/reminders/failed');
    else history.push('/reminders');
  };

  const onClickResend = () => {
    if (!isDefined(reminder)) return;
    dispatch({ type: 'open', reminder });
  };

  const onClickIgnore = () => {
    dispatch({ type: 'ignore', reminder });
  };

  const handleNavigateToDebtor = (event: React.MouseEvent, debtorId: string) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(`/clients/${debtorId}`, '_blank');
    } else {
      history.push(`/clients/${debtorId}`);
    }
  };

  const selectedEmail = reminder?.emails.find((recipient) => recipient.email === currentRecipient);

  const currentPdf = reminder?.letters.find((letter) => letter.url === currentPdfUrl);

  const flattenedStatuses = Object.keys(selectedEmail?.statuses ?? {}).reduce(
    (memo, currentKey) => [
      ...memo,
      ...selectedEmail?.statuses![currentKey].map((date) => ({
        status: currentKey,
        timestamp: dayjs(date),
      })),
    ],
    [] as Array<{ status: EmailDeliveryStatus; timestamp: Dayjs }>,
  );

  const sortedStatuses = flattenedStatuses.sort((a, b) => b.timestamp.diff(a.timestamp));

  const hasEmail = reminder && !isEmpty(reminder.emails);
  const hasPost = reminder && !isEmpty(reminder.letters);
  const hasFailedReminder = reminder?.status === 'failed';

  if (isReminderLoading) {
    return (
      <Center h="100%">
        <Loader />
      </Center>
    );
  }

  if (!hasEmail && !hasPost) {
    return (
      <>
        <PageTitle>
          <ActionIcon onClick={onClickBack} size="lg" variant="light">
            <IconArrowLeft stroke={1.5} />
          </ActionIcon>
          {t(i18nKeys.REMINDER.REMINDER)}
        </PageTitle>
        <Text>{t(i18nKeys.REMINDER_LIST.BADGE.MISSING_INFORMATION)}</Text>
      </>
    );
  }

  return (
    <>
      <PageTitle>
        <Group>
          <ActionIcon onClick={onClickBack} size="lg" variant="light">
            <IconArrowLeft stroke={1.5} />
          </ActionIcon>
          <Title order={2}>{t(i18nKeys.REMINDER.REMINDER)}</Title>
        </Group>
        {hasFailedReminder && (
          <PageTitle.Actions>
            <Group>
              <Button
                color="gray"
                variant="light"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onClickIgnore();
                }}
                leftSection={<IconSendOff stroke={1.5} size={16} />}
              >
                {t(i18nKeys.FAILED_REMINDERS.ACTION_IGNORE)}
              </Button>

              <Button
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onClickResend();
                }}
                variant="light"
                leftSection={<IconSend stroke={1.5} size={16} />}
              >
                {t(i18nKeys.FAILED_REMINDERS.ACTION_RESEND)}
              </Button>
            </Group>
          </PageTitle.Actions>
        )}
      </PageTitle>
      <Tabs defaultValue={hasEmail ? 'email' : 'pdf'}>
        <Tabs.List my="lg">
          {hasEmail && <Tabs.Tab value="email">{t(i18nKeys.EMAIL)}</Tabs.Tab>}
          {hasPost && <Tabs.Tab value="pdf">{t(i18nKeys.POST)}</Tabs.Tab>}
        </Tabs.List>
        <Tabs.Panel value="email">
          <Card>
            <Group justify="space-between">
              <Box>
                <Stack gap="lg">
                  <Group>
                    <Text fw="bold" c="dimmed" fz="xs" w={120}>
                      {t(i18nKeys.REMINDER.CLIENT)}
                    </Text>
                    <Anchor onClick={(event) => handleNavigateToDebtor(event, reminder?.debtorId)}>
                      {reminder.debtorFullName}
                    </Anchor>
                  </Group>
                  <Group>
                    <Text fw="bold" c="dimmed" fz="xs" w={120}>
                      {t(i18nKeys.REMINDER.SENT_TO)}
                    </Text>
                    <Select
                      w={300}
                      size="sm"
                      data={reminder?.emails.map((recipient) => recipient.email)}
                      value={currentRecipient}
                      onChange={setCurrentRecipient}
                      allowDeselect={false}
                    />
                  </Group>
                  <Group>
                    <Text fw="bold" c="dimmed" fz="xs" w={120}>
                      {t(i18nKeys.REMINDER.DATE_SENT)}
                    </Text>
                    <Text>{dayjs(selectedEmail?.sentAt).format('dddd DD MMMM YYYY - HH:mm')}</Text>
                  </Group>
                </Stack>
                {sortedStatuses.length !== 0 && canUseEmailStatus && (
                  <Group pt="sm">
                    <Text fw="bold" c="dimmed" fz="xs" w={120}>
                      {t(i18nKeys.REMINDER.DELIVERY_STATUS)}
                    </Text>
                    <Accordion variant="separated" w={300}>
                      <Accordion.Item value="1">
                        <Accordion.Control>
                          {t(i18nKeys.REMINDER.SEE_DELIVERY_HISTORY)}
                        </Accordion.Control>
                        <Accordion.Panel>
                          <EmailStatusHistory>
                            {sortedStatuses.map((statusObject, index) => (
                              <EmailStatusHistoryItem key={index}>
                                <span>
                                  {t(i18nKeys.MAILS.STATUS[statusObject.status.toUpperCase()])}
                                </span>
                                <span style={{ fontStyle: 'italic', color: '#bbb' }}>
                                  {formattedDate(
                                    statusObject.timestamp.toDate(),
                                    currentLang,
                                    'DD/MM/YYYY - HH:mm',
                                  )}
                                </span>
                              </EmailStatusHistoryItem>
                            ))}
                          </EmailStatusHistory>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Group>
                )}
              </Box>
              <StatusCard reminder={reminder} />
            </Group>
            <Divider my="xl" />
            <HTML html={selectedEmail?.body} />
          </Card>
        </Tabs.Panel>
        <Tabs.Panel value="pdf">
          <Card>
            <Group>
              <Text fw="bold" c="dimmed" fz="xs" w={120}>
                {t(i18nKeys.REMINDER.CHOOSE_PDF)}
              </Text>
              <Select
                data={reminder?.letters.map((pdf, index) => ({
                  value: pdf.url ?? '',
                  label: `PDF ${index + 1}`,
                }))}
                onChange={setCurrentPdfUrl}
                value={currentPdfUrl}
              />
            </Group>
            <Space h="md" />
            <Group>
              <Text fw="bold" c="dimmed" fz="xs" w={120}>
                {t(i18nKeys.REMINDER.TRACKING_LINK)}
              </Text>
              {currentPdf && currentPdf.trackingLink ? (
                <Anchor href={currentPdf.trackingLink} target="_blank">
                  {currentPdf.trackingLink}
                </Anchor>
              ) : (
                <Text>{t(i18nKeys.REMINDER.NO_TRACKING_LINK)}</Text>
              )}
            </Group>
            <Space h="xl" />
            <Center>
              <PdfDocument pdfUrl={currentPdfUrl ?? ''} />
            </Center>
          </Card>
        </Tabs.Panel>
      </Tabs>
      <ResendReminderModal />
    </>
  );
};
