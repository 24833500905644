import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';

import { css } from '@emotion/css';
import { rem } from '@mantine/core';
import { createSpotlight, Spotlight as MantineSpotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';

import { useBuildMenuItems } from '../hooks/use-build-menu-items';
import { menuToSpotlightActions } from '../utils';

export const spotlightStylesOverride = {
  action: css`
    &[data-selected] {
      background-color: var(--mantine-color-purple-5);
    }
  `,
};

export const NavigationSpotlight = ({}) => {
  const { t } = useTranslation();
  const menu = useBuildMenuItems();
  const history = useHistory();
  const [store, spotlightControls] = createSpotlight();

  const spotlightActions = menuToSpotlightActions(menu, history.push);

  return (
    <MantineSpotlight
      store={store}
      limit={7}
      shortcut={['/', ':']}
      actions={spotlightActions}
      centered
      highlightQuery
      classNames={{
        action: spotlightStylesOverride.action,
      }}
      onQueryChange={(query) => {
        if (query === '/' || query === ':') spotlightControls.close();
      }}
      filter={(query, actions) =>
        actions.filter((action) => {
          if (!('label' in action)) return false;

          return action.label?.split(' ').some((word) => {
            const normalizedWord = word
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase();
            const normalizedQuery = query
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase();

            return normalizedWord.startsWith(normalizedQuery);
          });
        })
      }
      radius={10}
      searchProps={{
        leftSection: <IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />,
        placeholder: t(i18nKeys.SPOTLIGHT.PLACEHOLDER),
      }}
    />
  );
};
