import { useLayoutEffect, useState } from 'react';
import { useGetDebtorTimeline } from 'api';
import {
  CleanActivity,
  DebtorReaction,
  ExternalMail,
  GetDebtorTimelineParams,
  ObjectType,
} from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import TableFooter from 'shared/components/CustomTable/TableFooter';
import { useProfile } from 'shared/hooks';

import { LoadingOverlay } from '@mantine/core';

import { ClientDetailTabProps } from './ClientDetail.types';
import { ClientDetailTabsTimelineFilter } from './ClientDetailTabsTimelineFilter';
import { ClientDetailTabsTimelineItem } from './ClientDetailTabsTimelineItem';
import { ClientDetailTabsTimelineItemActivity } from './ClientDetailTabsTimelineItemActivity';
import { ClientDetailTabsTimelineItemEmail } from './ClientDetailTabsTimelineItemEmail';
import { ClientDetailTabsTimelineItemReaction } from './ClientDetailTabsTimelineItemReaction';

import styleIdentifiers from './ClientDetailTabsTimeline.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const ClientDetailTabsTimeline = ({ debtor }: ClientDetailTabProps) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [params, setParams] = useState<GetDebtorTimelineParams>({
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
  });

  useLayoutEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<GetDebtorTimelineParams>(Object.fromEntries(new URLSearchParams(location.search)), [
        'page',
        'page_limit',
        'types',
      ]),
    }));
  }, []);

  const {
    data: debtorTimelineResponse,
    isLoading,
    isError,
  } = useGetDebtorTimeline(debtor.id, params);

  if (isLoading && !debtorTimelineResponse) {
    return <LoadingOverlay visible />;
  }

  if (isError || !debtorTimelineResponse) {
    return <span>Error</span>;
  }

  const { data, metadata } = debtorTimelineResponse;

  const setPage = ({ selected }) => {
    setParams({ ...params, page: selected + 1 });
  };

  const setPageLimit = (page_limit) => () => {
    setParams({ ...params, page_limit });
  };

  return (
    <section className={styles('tab-item', 'timeline')}>
      <ClientDetailTabsTimelineFilter params={params} setParams={setParams} />
      <article className={styles('items')}>
        {data.length ? (
          <div>
            {data.map((e) => {
              switch (e.type) {
                case ObjectType.external_mail:
                  return (
                    <ClientDetailTabsTimelineItem type={e.type} key={`${e.type}-${e.id}`}>
                      <ClientDetailTabsTimelineItemEmail email={e as ExternalMail} />
                    </ClientDetailTabsTimelineItem>
                  );
                case ObjectType.debtor_reaction:
                  return (
                    <ClientDetailTabsTimelineItem type={e.type} key={`${e.type}-${e.id}`}>
                      <ClientDetailTabsTimelineItemReaction reaction={e as DebtorReaction} />
                    </ClientDetailTabsTimelineItem>
                  );
                default:
                  return (
                    <ClientDetailTabsTimelineItem type={e.type} key={`${e.type}-${e.id}`}>
                      <ClientDetailTabsTimelineItemActivity activity={e as CleanActivity} />
                    </ClientDetailTabsTimelineItem>
                  );
              }
            })}
          </div>
        ) : (
          <div className={styles('no-result')}>
            <p>{t(i18nKeys.NO_RESULT)}</p>
          </div>
        )}
      </article>
      <TableFooter
        pagination={metadata.pagination}
        setPage={setPage}
        itemsLength={metadata.total}
        setPageLimit={setPageLimit}
        className={styles('sticky')}
      />
    </section>
  );
};
