import { matchPath, useHistory, useLocation } from 'react-router';
import { isDefined, isNonNullish } from 'remeda';

import { css } from '@emotion/css';
import { NavLink, Text, useMantineTheme } from '@mantine/core';

import { MenuItem } from '../types';

import { MenuItemBadges } from './MenuItemBadges';

export type NavMenuItemProps = MenuItem;

export const NavMenuItem = ({
  label,
  Icon,
  path,
  pathSuffix,
  unreadCount,
  tagLabel,
  isHidden,
  showUpsell,
  onClick,
  onNavigate,
  isExactRouteMatch = true,
}: NavMenuItemProps) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useMantineTheme();

  if (isHidden) return null;

  if (isDefined(path)) {
    const doesMatchCurrentPath = isNonNullish(
      matchPath(location.pathname, { path, exact: isExactRouteMatch }),
    );

    return (
      <NavLink
        onClick={() => {
          history.push(path.concat(pathSuffix ?? ''));
          onNavigate?.();
        }}
        active={doesMatchCurrentPath}
        className={styles.navLink}
        classNames={{
          root: styles.navLinkRoot(showUpsell),
        }}
        label={<Text fw={doesMatchCurrentPath ? 500 : undefined}>{label}</Text>}
        leftSection={
          <Icon
            style={{ opacity: showUpsell ? '0.4' : '' }}
            color={theme.colors.purple[5]}
            stroke="1.7"
            size={20}
          />
        }
        rightSection={
          <MenuItemBadges unreadCount={unreadCount} tagLabel={tagLabel} showUpsell={showUpsell} />
        }
      />
    );
  }

  return (
    <NavLink
      onClick={onClick}
      label={label}
      leftSection={<Icon color={theme.colors.purple[5]} stroke="1.7" size={20} />}
    />
  );
};

export const styles = {
  navLinkRoot: (isDimmed = false) => css`
    box-sizing: border-box;
    border-radius: 8px;
    pointer-events: ${isDimmed ? 'none' : 'auto'};

    ${isDimmed
      ? `
      .mantine-NavLink-label {
        opacity: 0.4;
      }
      `
      : ''}
  `,
  navLink: css`
    font-weight: 400;
    margin-bottom: 4px;
  `,
};
