import { PartialDeep } from 'type-fest';

import { colorsTuple, createTheme, MantineTheme } from '@mantine/core';

export const colorsTheme: PartialDeep<MantineTheme> = createTheme({
  primaryColor: 'purple',
  primaryShade: 6,
  colors: {
    // No variants, used as a constants
    black: colorsTuple('#17082B'),
    text: colorsTuple('#4F516D'),
    success: colorsTuple('#00C472'),
    successLight: colorsTuple('#CBF6E2'),
    purple: [
      '#F9F7FC',
      '#F1EFFB',
      '#E4DDF8',
      '#D0C3F2',
      '#997BEC',
      '#8663E8',
      '#4B29A4',
      '#351669',
      '#1F0A43',
      '#0C011F',
    ],
    coral: [
      '#FFFAFB',
      '#FFF5F8',
      '#FEE7EE',
      '#FCD9E4',
      '#FBBBC0',
      '#F98C92',
      '#F35E68',
      '#D74151',
      '#A62532',
      '#82141F',
    ],
    gray: [
      '#FCFCFD',
      '#F6F6F9',
      '#E7E8EE',
      '#DCDCE5',
      '#C4C5D4',
      '#9899B4',
      '#6B6E94',
      '#4F516D',
      '#36374A',
      '#20212C',
      '#09090C',
    ],
    error: [
      '#FDF2F2',
      '#FDF2F2',
      '#F8D4D7',
      '#F8B0B4',
      '#F57F86',
      '#EC5962',
      '#D45058',
      '#C2434B',
      '#C2434B',
      '#C2434B',
      '#C2434B',
    ],
    validation: [
      '#EBFAE2',
      '#EBFAE2',
      '#D9F0CA',
      '#B3E494',
      '#85D355',
      '#68C132',
      '#57AD23',
      '#4F9C20',
      '#4F9C20',
      '#4F9C20',
      '#4F9C20',
    ],
    warning: [
      '#FFF2E4',
      '#FFF2E4',
      '#FFE2C2',
      '#FFC382',
      '#FFA849',
      '#FF921B',
      '#ED810C',
      '#D87407',
      '#D87407',
      '#D87407',
      '#D87407',
    ],
  },
});
