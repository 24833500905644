import { PartialDeep } from 'type-fest';

import { css } from '@emotion/css';
import { Button, createTheme, MantineTheme } from '@mantine/core';

const buttonStyles = {
  root: (theme: MantineTheme) => css`
    &[data-variant='default'] {
      background-color: ${theme.colors.gray[0]};
      color: ${theme.colors.gray[8]};
    }

    &[data-variant='filled'] {
      background: ${theme.colors.purple[6]};
    }
    &[data-disabled='true'] {
      background: ${theme.colors.gray[2]};
      color: ${theme.colors.gray[4]};
    }
  `,
};

export const ThemedMantineButton: PartialDeep<MantineTheme> = createTheme({
  components: {
    Button: Button.extend({
      defaultProps: {
        autoContrast: true,
      },
      styles: {
        root: {
          width: 'fit-content',
          fontWeight: 500,
          borderRadius: 8,
          fontFamily: 'Outfit',
        },
      },
      classNames: (theme) => ({
        root: buttonStyles.root(theme),
      }),
    }),
  },
});
