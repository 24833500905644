import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import ActivityDetail from 'shared/components/ActivityDetail';
import HTML from 'shared/components/HTML/HTML';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Activity } from 'types/store/activity';

import { Group, Indicator } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

import styleIdentifiers from './activitiesItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  item: Activity;
  noCheckbox: boolean;
};

export default function ActivitiesItem({ item, noCheckbox }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const isCreditNote = item.tracked_type === 'CreditNote';

  const openModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      children: <ActivityDetail activity={item} />,
    });
  };

  const detailModal = (e) => {
    e.stopPropagation();
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail isCreditNote={isCreditNote} id={item.tracked_id} modal />,
    });
  };

  const detailDebtor = (e) => {
    e.stopPropagation();
    history.push(`/clients/${item.tracked_id}`);
  };

  const detailPaymentPlan = (e) => {
    e.stopPropagation();
    history.push(`/payment-plan/${item.payment_plan_id}`);
  };

  const detailTask = (e) => {
    e.stopPropagation();
    history.push(`/tasks/${item.tracked_id}`);
  };

  const shouldShowPaymentPlan = item.payment_plan_id;
  const shouldShowClient = item.tracked_type === 'Debtor' && !shouldShowPaymentPlan;
  const date = dayjs(item.date).fromNow();

  return (
    <tr className={styles('ActivitiesItem')} key={item.id} onClick={openModal}>
      <td className={styles('checkbox', noCheckbox && 'small')}>{item.new && <Indicator />}</td>
      <td onClick={openModal}>{item.author}</td>
      <td onClick={openModal}>{date}</td>
      <td onClick={openModal} className={styles('description')}>
        <HTML html={item.description} />
      </td>
      <td className={styles('actions')}>
        {(item.tracked_type === 'Invoice' || isCreditNote) && (
          <Group gap={8} onClick={detailModal}>
            {t(
              isCreditNote ? i18nKeys.ACTIVITIES.SEE_CREDIT_NOTE : i18nKeys.ACTIVITIES.SEE_INVOICE,
            )}
            <IconChevronRight size={14} stroke={1.5} />
          </Group>
        )}
        {shouldShowClient && (
          <Group gap={8} onClick={detailDebtor}>
            {t(i18nKeys.ACTIVITIES.SEE_CLIENT)}
            <IconChevronRight size={14} stroke={1.5} />
          </Group>
        )}
        {shouldShowPaymentPlan && (
          <Group gap={8} onClick={detailPaymentPlan}>
            {t(i18nKeys.ACTIVITIES.SEE_PAYMENT_PLAN)}
            <IconChevronRight size={14} stroke={1.5} />
          </Group>
        )}
        {item.tracked_type === 'Task' && (
          <Group gap={8} onClick={detailTask}>
            {t(i18nKeys.ACTIVITIES.SEE_TASK)}
            <IconChevronRight size={14} stroke={1.5} />
          </Group>
        )}
      </td>
    </tr>
  );
}
