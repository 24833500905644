import { useEffect, useState } from 'react';
import { Id } from 'api/models';
import { motion } from 'framer-motion';
import { atom, useAtom } from 'jotai';
import { i18nKeys, useTranslation } from 'locales';
import { isDefined, isEmpty } from 'remeda';
import { useLoadAgedBalanceCompact, useLoadLightUsers, useProfile } from 'shared/hooks';
import { useLoadDashboard } from 'shared/hooks/use-load-dashboard';
import { useIsMobile } from 'shared/hooks/utils';
import { PageTitle } from 'shared/layout/PageTitle';

import { Grid, LoadingOverlay, Select } from '@mantine/core';
import { useDisclosure, usePrevious } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp, IconUser } from '@tabler/icons-react';

import { BalanceGraphic } from './BalanceGraphic';
import BankStatus from './BankStatus';
import { DashboardChartLoader } from './DashboardChartLoader';
import { OutstandingGraphic } from './OutstandingGraphic';
import RecentActivities from './RecentActivities';

const staggerContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.02,
    },
  },
};

const staggerItem = {
  hidden: { y: 20 },
  show: { y: 0 },
};

const wasDashboardVisitedAtom = atom(false);

export const Dashboard = () => {
  const { t } = useTranslation();
  const profile = useProfile();
  const isMobile = useIsMobile();

  const [accountManagerId, setAccountManagerId] = useState<Id>();
  const { dashboard, isDashboardLoading } = useLoadDashboard({
    accountManagerId,
  });

  const [wasDashboardVisited, setWasDashboardVisited] = useAtom(wasDashboardVisitedAtom);
  const previousDashboardValue = usePrevious(dashboard);

  const { users = [] } = useLoadLightUsers();
  const { agedBalanceCompact, isAgedBalanceCompactLoading } = useLoadAgedBalanceCompact();

  const isFirstRenderWithData = !isDefined(previousDashboardValue) && isDefined(dashboard);
  const shouldShowAnimation = isFirstRenderWithData && !wasDashboardVisited;

  useEffect(() => {
    if (shouldShowAnimation) setWasDashboardVisited(true);
  }, [shouldShowAnimation, setWasDashboardVisited]);

  const initial = shouldShowAnimation ? 'hidden' : 'show';

  if (!isDefined(dashboard)) return null;

  return (
    <>
      <LoadingOverlay visible={isDashboardLoading} />
      <motion.div variants={staggerContainer} initial={initial} animate="show">
        <motion.div variants={staggerItem}>
          <PageTitle>
            {t(i18nKeys.COMMON.DASHBOARD)}
            <PageTitle.Actions>
              {(profile.isAdmin || profile.roles.includes('all_invoices')) && (
                <Select
                  w={235}
                  comboboxProps={{ shadow: 'sm' }}
                  placeholder={t(i18nKeys.ACCOUNT_MANAGER)}
                  clearable
                  disabled={isEmpty(users)}
                  leftSection={<IconUser />}
                  rightSection={<IconChevronDown size={18} />}
                  onChange={(value) =>
                    setAccountManagerId(value == null ? undefined : Number(value))
                  }
                  data={users.map(({ name, id }) => ({ value: id.toString(), label: name.full }))}
                />
              )}
            </PageTitle.Actions>
          </PageTitle>
        </motion.div>
        <Grid style={{ overflow: 'visible' }}>
          <Grid.Col
            span={(() => {
              if (isMobile) return 12;
              if (profile.isAdmin) return 8;
              return 12;
            })()}
          >
            <motion.div style={{ height: '100%' }} variants={staggerItem}>
              <RecentActivities
                accountManagerId={accountManagerId}
                data={dashboard.data.recentActivities}
              />
            </motion.div>
          </Grid.Col>
          {profile.isAdmin && (
            <Grid.Col span={isMobile ? 12 : 4}>
              <motion.div style={{ height: '100%' }} variants={staggerItem}>
                <BankStatus data={dashboard.data.financialData} />
              </motion.div>
            </Grid.Col>
          )}
          <Grid.Col span={isMobile ? 12 : 5}>
            <motion.div style={{ height: '100%' }} variants={staggerItem}>
              {isAgedBalanceCompactLoading ? (
                <DashboardChartLoader titleKey={i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TITLE} />
              ) : (
                <OutstandingGraphic
                  agedBalanceCompact={agedBalanceCompact!}
                  accountManagerId={accountManagerId}
                />
              )}
            </motion.div>
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 7}>
            <motion.div style={{ height: '100%' }} variants={staggerItem}>
              {isAgedBalanceCompactLoading ? (
                <DashboardChartLoader titleKey={i18nKeys.DASHBOARD.DELAY_GRAPHIC.TITLE} />
              ) : (
                <BalanceGraphic agedBalanceCompact={agedBalanceCompact!} />
              )}
            </motion.div>
          </Grid.Col>
        </Grid>
      </motion.div>
    </>
  );
};
