import { useState } from 'react';
import { i18nKeys, TranslateFunction, useTranslation } from 'locales/index';
import { countActiveFilters } from 'modules/Kanban/utils/filters';
import { useHistory } from 'react-router';
import { useProfile } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { Id } from 'types/index';

import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Combobox,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
  useCombobox,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useHotkeys, useSessionStorage } from '@mantine/hooks';
import {
  IconChevronDown,
  IconFilter,
  IconFilterOff,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { LightReminder, LoadRemindersVariables, useLoadReminders } from '../api';
import { FilterDrawer, SentMediumBadge } from '../components';

const columnHelper = createColumnHelper<LightReminder>();

const columns = (order: 'asc' | 'desc', onToggleOrder: VoidFunction, t: TranslateFunction) => [
  columnHelper.accessor('reference', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.REFERENCE),
    cell: (reference) => {
      const row = reference.row.original;

      const shouldShowBadge = row.invoiceCount > 1;
      return (
        <Group>
          {reference.getValue()}
          {shouldShowBadge && <Badge variant="light">{row.invoiceCount}</Badge>}
        </Group>
      );
    },
  }),
  columnHelper.accessor('sentAt', {
    header: () => (
      <Group onClick={onToggleOrder} style={{ cursor: 'pointer', userSelect: 'none' }}>
        {t(i18nKeys.REMINDER_LIST.HEADERS.SENT_AT)}
        <ActionIcon variant="transparent" color="purple.5">
          {order === 'asc' ? <IconSortAscending size={14} /> : <IconSortDescending size={14} />}
        </ActionIcon>
      </Group>
    ),
    cell: (sentAt) => sentAt.getValue().format('DD/MM/YYYY'),
  }),
  columnHelper.accessor('debtorFullName', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.DEBTOR),
  }),
  columnHelper.accessor('reminderType', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.REMINDER_TYPE),
  }),
  columnHelper.accessor('sentMedia', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.SENT_MEDIA),
    cell: (sentMedia) =>
      sentMedia
        .getValue()
        .map((sentMedium, index) => <SentMediumBadge key={index} sentMedium={sentMedium} />),
  }),
];

export type RemindersFilter = Omit<LoadRemindersVariables, 'page' | 'pageLimit'>;

interface ReminderProps {}

export const ReminderList = ({}: ReminderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useProfile();
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [isDrawerOpen, { open: openDrawer, close: closeDrawer, toggle: toggleDrawerOpen }] =
    useDisclosure(false);
  useHotkeys([['f', toggleDrawerOpen]]);
  const filterForm = useForm<RemindersFilter>();

  const [pageLimit, setPageLimit] = useSessionStorage({
    key: 'remindersIndexPageLimit',
    defaultValue: profile.preferences.itemsPerPage,
  });

  const { reminderIndex } = useLoadReminders({
    page: currentPage,
    pageLimit,
    ...filterForm.values,
    order,
  });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleToggleOrder = () => {
    setOrder((currentOrder) => (currentOrder === 'asc' ? 'desc' : 'asc'));
  };

  const table = useReactTable({
    data: reminderIndex!.data,
    columns: columns(order, handleToggleOrder, t),
    getCoreRowModel: getCoreRowModel(),
  });

  const activeFiltersCount = countActiveFilters(filterForm.values);

  const options = [10, 20, 50, 100].map((item) => (
    <Combobox.Option value={item.toString()} key={item}>
      {item}
    </Combobox.Option>
  ));

  const firstItem = (currentPage - 1) * reminderIndex!.pagination.pageLimit + 1;
  const lastItem =
    (currentPage - 1) * reminderIndex!.pagination.pageLimit + reminderIndex!.data.length;

  const handleClickRow = (event: React.MouseEvent, rowId: Id) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(`/reminders/${rowId}`, '_blank');
    } else {
      history.push(`/reminders/${rowId}`);
    }
  };

  const handleMiddleClickRow = (event: React.MouseEvent, rowId: Id) => {
    if (event.button === 1) {
      window.open(`/reminders/${rowId}`, '_blank');
    }
  };

  return (
    <>
      <Stack h="100%" gap={0}>
        <PageTitle>
          {t(i18nKeys.REMINDER_LIST.TITLE)}
          <PageTitle.Actions>
            <Button.Group>
              <Button
                variant="default"
                leftSection={<IconFilter size={18} stroke={1.5} />}
                rightSection={
                  <Badge color={activeFiltersCount > 0 ? 'purple' : 'gray'}>
                    {activeFiltersCount}
                  </Badge>
                }
                onClick={openDrawer}
              >
                {t(i18nKeys.REMINDER_LIST.FILTERS.TITLE)}
              </Button>
              {activeFiltersCount > 0 && (
                <Button variant="default" onClick={filterForm.reset}>
                  <IconFilterOff stroke={1.5} size={18} />
                </Button>
              )}
            </Button.Group>
          </PageTitle.Actions>
        </PageTitle>
        <Card radius={8} p={0}>
          <Group style={{ overflowY: 'auto' }}>
            <Table
              highlightOnHover
              highlightOnHoverColor="purple.0"
              horizontalSpacing="md"
              verticalSpacing="space3"
              stickyHeader
              fz={12}
            >
              <Table.Thead
                bg="purple.1"
                style={(theme) => ({
                  border: `1px solid ${theme.colors.gray[2]}`,
                  fontSize: 14,
                })}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.Th key={header.id} c="purple.5" fw={400}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </Table.Th>
                    ))}
                  </Table.Tr>
                ))}
              </Table.Thead>
              <Table.Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Table.Tr
                    key={row.id}
                    onClick={(event) => handleClickRow(event, row.original.id)}
                    onAuxClick={(event) => handleMiddleClickRow(event, row.original.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Table.Td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Table.Td>
                    ))}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Group>
          <Group
            justify="space-between"
            p="sm"
            style={(theme) => ({
              border: `1px solid ${theme.colors.gray[2]}`,
            })}
          >
            <Group>
              <Text size="sm">
                {firstItem} - {lastItem} {t(i18nKeys.OF)} {reminderIndex!.pagination.totalObjects}
              </Text>
              <Combobox
                store={combobox}
                width={250}
                position="bottom-start"
                withArrow
                onOptionSubmit={(val) => {
                  setPageLimit(Number(val));
                  combobox.closeDropdown();
                }}
              >
                <Combobox.Target>
                  <Group
                    onClick={() => combobox.toggleDropdown()}
                    style={{ cursor: 'pointer' }}
                    gap={4}
                  >
                    <Text size="sm">
                      {pageLimit} {t(i18nKeys.PER_PAGE)}
                    </Text>
                    <IconChevronDown stroke={1.5} size={16} />
                  </Group>
                </Combobox.Target>

                <Combobox.Dropdown>
                  <Combobox.Options>{options}</Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>
            </Group>
            <Pagination
              value={currentPage}
              onChange={(page) => setCurrentPage(page)}
              total={reminderIndex!.pagination.totalPages}
              size="xs"
            />
          </Group>
        </Card>
      </Stack>
      <FilterDrawer isOpen={isDrawerOpen} close={closeDrawer} form={filterForm} />
    </>
  );
};
