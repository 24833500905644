import React from 'react';

import { NavLink, useMantineTheme } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

import { Menu } from '../types';

interface NavMenuProps {
  menu: Menu;
  setMenu: (menu: Menu) => void;
}

export const NavMenu = ({ menu, setMenu }: NavMenuProps) => {
  const theme = useMantineTheme();

  if (menu.isHidden) return null;

  return (
    <NavLink
      onClick={() => setMenu(menu)}
      label={menu.label}
      leftSection={<menu.Icon color={theme.colors.purple[5]} stroke="1.7" size={20} />}
      rightSection={<IconChevronRight color={theme.colors.gray[8]} size={16} />}
    />
  );
};
