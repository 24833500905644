import { useMantineTheme } from '@mantine/core';

export function useAgedBalanceColors() {
  const theme = useMantineTheme();

  return {
    notDue: '#CCD7DE',
    totalBalance: theme.colors.warning[2],
    netBalance: theme.colors.warning[2],
    0: theme.colors.warning[3],
    30: theme.colors.warning[4],
    60: theme.colors.warning[5],
    90: theme.colors.warning[6],
  } as const;
}

export function useAgedBalanceAccentColors() {
  const theme = useMantineTheme();

  return {
    notDue: theme.colors.purple[4],
    totalBalance: theme.colors.coral[3],
    netBalance: theme.colors.coral[5],
    0: theme.colors.coral[2],
    30: theme.colors.coral[2],
    60: theme.colors.coral[3],
    90: theme.colors.coral[4],
  } as const;
}
