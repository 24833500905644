import { PartialDeep } from 'type-fest';

import { createTheme, MantineTheme, Title } from '@mantine/core';

import { colorsTheme } from '../colors';

export const ThemedMantineTitle: PartialDeep<MantineTheme> = createTheme({
  components: {
    Title: Title.extend({
      defaultProps: {
        c: colorsTheme.colors?.black![0],
      },
    }),
  },
});
