import { useCreateTask } from 'api/index';
import { CreateTaskBody } from 'api/models/createTaskBody';
import { TaskForm } from 'app/Private/Tasks';
import { i18nKeys, useTranslation } from 'locales';
import { useChangeLocale } from 'shared/hooks';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';

import { useAuth0 } from '@auth0/auth0-react';
import { createSpotlight, Spotlight } from '@mantine/spotlight';
import { SpotlightActions } from '@mantine/spotlight/lib/Spotlight';

import { spotlightStylesOverride } from './NavigationSpotlight';

export const ActionsSpotlight = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const [store, spotlightControls] = createSpotlight();

  const { mutate: createTask } = useCreateTask();
  const changeLocale = useChangeLocale();

  const actions: Array<SpotlightActions> = [
    {
      group: 'Language',
      actions: [
        {
          id: 'setLangEn',
          label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'EN' }),
          onClick: () => changeLocale('en'),
        },
        {
          id: 'setLangFr',
          label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'FR' }),
          onClick: () => changeLocale('fr'),
        },
        {
          id: 'setLangNl',
          label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'NL' }),
          onClick: () => changeLocale('nl'),
        },
      ],
    },
    {
      group: 'Quick actions',
      actions: [
        {
          id: 'task',
          label: 'Create new task',
          onClick: () => {
            sideMenuShow({
              unmount: true,
              content: (
                <TaskForm
                  onSubmit={(data: CreateTaskBody) =>
                    createTask({ data }, { onSuccess: sideMenuHide })
                  }
                />
              ),
            });
          },
        },
      ],
    },
    {
      group: 'Other',
      actions: [
        {
          id: 'logout',
          label: 'Logout',
          onClick: () => logout({ logoutParams: { returnTo: `${window.location.origin}/login` } }),
          description: 'Profile',
        },
      ],
    },
  ];

  return (
    <Spotlight
      actions={actions}
      store={store}
      shortcut={['.', ';']}
      centered
      limit={7}
      radius={10}
      highlightQuery
      onQueryChange={(query) => {
        if (query === '.' || query === ';') spotlightControls.close();
      }}
      classNames={{
        action: spotlightStylesOverride.action,
      }}
    />
  );
};
