import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { isDefined } from 'remeda';

import {
  ActionIcon,
  Badge,
  Box,
  Group,
  MantineTheme,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IconCircleArrowUpFilled } from '@tabler/icons-react';

const badgeStyles = (theme: MantineTheme) => ({
  notification: {
    root: {
      padding: '11px 6px',
    },
  },
  tag: {
    root: {
      padding: '11px 6px',
      border: `1px solid ${theme.colors.coral[4]}`,
    },
  },
});

interface MenuItemBadgesProps {
  unreadCount?: number;
  tagLabel?: string;
  showUpsell?: boolean;
}

export const MenuItemBadges = ({ unreadCount, tagLabel, showUpsell }: MenuItemBadgesProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  if (!isDefined(unreadCount) && !isDefined(tagLabel) && !isDefined(showUpsell)) return null;

  return (
    <Group gap={5}>
      {isDefined(unreadCount) && unreadCount > 0 && !showUpsell && (
        <Badge styles={badgeStyles(theme).notification} size="sm" color="coral.2" radius={6}>
          <Text fw={500} fz={12} c="coral.6">
            {unreadCount}
          </Text>
        </Badge>
      )}
      {isDefined(tagLabel) && (
        <Badge
          styles={{ ...badgeStyles(theme).tag, label: { textTransform: 'capitalize' } }}
          color="coral.2"
          size="sm"
          radius={6}
        >
          <Text fw={500} fz={12} c="coral.6">
            {tagLabel}
          </Text>
        </Badge>
      )}
      {showUpsell && (
        <Tooltip
          label={t(i18nKeys.NAV.UPSELL)}
          position="right"
          offset={30}
          withArrow
          arrowSize={8}
          radius={6}
        >
          <Box
            h={20}
            style={{ pointerEvents: 'auto', filter: 'opacity(1)', cursor: 'default' }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
            variant="transparent"
          >
            <IconCircleArrowUpFilled stroke={1.5} size={20} color={theme.colors.coral[5]} />
          </Box>
        </Tooltip>
      )}
    </Group>
  );
};
