import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { CustomSelect, DateSelector, SwitchInput } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { DescVal, StoreState } from 'types/storeTypes';

import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName, IntegrationFormProps } from '../Integration.constants';
import { getExactConnectUrl, getIntegrationRights } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum ExactFormFieldsName {
  IMPORT_INTEGRATION = 'use_exact_import_integration',
  PAYMENT_MATCHING = 'use_exact_payment_matching',
  DIVISION_ID = 'division_id',
  REFERENCE_FIELD = 'reference_field',
}

export type ExactFormFields = {
  [ExactFormFieldsName.DIVISION_ID]?: string;
  [ExactFormFieldsName.IMPORT_INTEGRATION]: boolean;
  [ExactFormFieldsName.PAYMENT_MATCHING]: boolean;
  [ExactFormFieldsName.REFERENCE_FIELD]: string;
  [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: Date | null;
  [IntegrationFormFieldsName.DEBTOR_UPDATE]: boolean;
  [IntegrationFormFieldsName.AUTOMATIC_SYNC]: boolean;
};

export type ExactFormProps = IntegrationFormProps<ExactFormFields>;

export const ExactForm = ({ onSubmit, initialValues, onboarding }: ExactFormProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const { isConfigure, isConnected } = getIntegrationRights(Integration.EXACT, company);

  const [companies, setCompanies] = useState<DescVal[]>([]);
  const [preventSubmit, setPreventSubmit] = useState<boolean>(false);

  const form = useForm<ExactFormFields>({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (isConnected && !isConfigure) {
      if (onboarding) {
        onBoardingActions.exactFetchCompanies({
          callback: (data: DescVal[]) => setCompanies(data),
        });
      } else {
        accountActions.exactFetchCompanies({
          callback: (data: DescVal[]) => setCompanies(data),
        });
      }
    }
  }, [isConnected, isConfigure, onboarding]);

  const { exact_reference_fields } = useSelector((state: StoreState) => state.app.constants);

  const { handleSubmit, control, setValue } = form;

  const submit = (values: ExactFormFields) => {
    if (preventSubmit) {
      showDialog({
        id: DialogShowId.CONFIRM,
        title: t(i18nKeys.CONFIRM),
        children: (
          <AYSModal
            text={t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE)}
            onConfirm={() => {
              setPreventSubmit(false);
              onSubmit(values);
            }}
          />
        ),
      });
    } else {
      onSubmit(values);
    }
  };

  const debtorUpdate = form.watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
  const importIntegration = form.watch(ExactFormFieldsName.IMPORT_INTEGRATION);

  useEffect(() => {
    if (importIntegration) {
      setValue(
        IntegrationFormFieldsName.ISSUE_DATE_LIMIT,
        initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          : new Date(),
      );
    }
  }, [importIntegration, initialValues, setValue]);

  if (!isConnected) {
    return (
      <IntegrationForm<ExactFormFields>
        integration={Integration.EXACT}
        onSubmit={handleSubmit(submit)}
        summary={i18nKeys.FORM.EXACT.SUMMARY}
        connectUrl={getExactConnectUrl()}
        form={form}
      />
    );
  }

  return (
    <IntegrationForm<ExactFormFields>
      integration={Integration.EXACT}
      onSubmit={handleSubmit(submit)}
      summary={i18nKeys.FORM.EXACT.SUMMARY}
      form={form}
    >
      {!isConfigure && (
        <div className={styles('select-company')}>
          <p>{t(i18nKeys.FORM.EXACT.IRREVERSIBLE_ACTION)}</p>
          <p className={styles('warning')}>{t(i18nKeys.FORM.WARNING_IRREVERSIBLE_ACTION)}</p>
          <div className={styles('grid-row')}>
            <div className={styles(onboarding ? 'col-12' : 'col-6')}>
              <Controller
                defaultValue=""
                name={ExactFormFieldsName.DIVISION_ID}
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name={ExactFormFieldsName.DIVISION_ID}
                    selectClassName={styles('company-select')}
                    errorClassName={styles('invoice-select-error')}
                    items={companies}
                    keyText="name"
                    keyValue="id"
                    label={t(i18nKeys.FORM.EXACT.SELECT_COMPANY, {
                      text: Integration.EXACT,
                    })}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles('grid-row')}>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={ExactFormFieldsName.IMPORT_INTEGRATION}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                  text: Integration.EXACT,
                })}
              />
            )}
          />
        </div>
        {importIntegration && (
          <>
            <div className={styles('col-12')}>
              <DateSelector
                name={IntegrationFormFieldsName.ISSUE_DATE_LIMIT}
                className={styles('issue-date')}
                noMinDate
                label={t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT)}
                placeholder={t(i18nKeys.ISSUE_DATE)}
                endPlaceholder={t(i18nKeys.FORM.TO)}
                required
                handleChange={(date: Date) => {
                  if (!onboarding) {
                    setPreventSubmit(true);
                  }
                  setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                }}
              />
            </div>
            <div className={styles('col-12')}>
              <Controller
                name={ExactFormFieldsName.REFERENCE_FIELD}
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name={ExactFormFieldsName.REFERENCE_FIELD}
                    items={exact_reference_fields}
                    keyText="description"
                    keyValue="value"
                    selectClassName={styles('issue-date')}
                    label={
                      <span>
                        {t(i18nKeys.SETTINGS.INTEGRATIONS.EXACT.SELECT_COLUMN)}{' '}
                        <Tooltip contentClassName={styles('tooltip')} icon={IconName.INFO}>
                          {t(i18nKeys.SETTINGS.INTEGRATIONS.EXACT.COLUMN_DESCRIPTION)}
                        </Tooltip>
                      </span>
                    }
                    placeholder={t(i18nKeys.SETTINGS.INTEGRATIONS.EXACT.COLUMN_REFERENCE)}
                  />
                )}
              />
            </div>
            <div className={styles('col-12')}>
              <Controller
                control={control}
                name={IntegrationFormFieldsName.DEBTOR_UPDATE}
                render={({ field: { ref, ...values } }) => (
                  <SwitchInput
                    {...values}
                    className={styles('switch-button', 'issue-date')}
                    label={t(
                      debtorUpdate
                        ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                        : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR,
                    )}
                  />
                )}
              />
            </div>
          </>
        )}
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={ExactFormFieldsName.PAYMENT_MATCHING}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                  text: Integration.EXACT,
                })}
              />
            )}
          />
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={IntegrationFormFieldsName.AUTOMATIC_SYNC}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC)}
              />
            )}
          />
        </div>
      </div>
    </IntegrationForm>
  );
};
