import { isDefined, isNullish } from 'remeda';
import { Locale, LOCALES } from 'types';

import { LocaleTree, TranslateFunction } from './types';
import { captureMessage } from '@sentry/react';

export function getTranslationForKey(
  key: Parameters<TranslateFunction>[0],
  params: Parameters<TranslateFunction>[1],
  localeTree: LocaleTree,
): string {
  const rawText = getTextForKey(key, localeTree);

  if (isNullish(params)) return rawText;

  return replaceParamsInText(rawText, params);
}

function getTextForKey(
  key: Parameters<TranslateFunction>[0] | undefined,
  localeTree: LocaleTree,
): string {
  if (!isDefined(key)) {
    captureMessage('Key provided to getTranslationForKey was `undefined`');
    return '';
  }

  const fragments = key.toString().split('.');
  let currentElement = localeTree;
  for (const fragment of fragments) currentElement = currentElement[fragment];

  return currentElement;
}

export function replaceParamsInText(
  text: string,
  params: Required<Parameters<TranslateFunction>>[1],
): string {
  let newText = text;

  Object.keys(params).forEach((paramKey) => {
    newText = newText.replace(`$\{${paramKey}}`, String(params[paramKey]));
  });

  return newText;
}

export function getPreferredBrowserLanguageOrDefault(): Locale {
  const navigatorLanguage = navigator.language;

  let parsedNavigatorLocale = '';
  if (navigatorLanguage.includes('-')) [parsedNavigatorLocale] = navigatorLanguage.split('-');
  if (navigatorLanguage.length === 2) parsedNavigatorLocale = navigatorLanguage;

  if (parsedNavigatorLocale in LOCALES) return parsedNavigatorLocale as Locale;

  return 'fr';
}
