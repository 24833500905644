import React from 'react';

import { Button, MantineTheme, Text, useMantineTheme } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

const styles = (theme: MantineTheme) => ({
  root: {
    backgroundColor: 'transparent',
    height: theme.spacing.space10,
    width: '100%',
    borderColor: theme.colors.purple[2],
  },
  inner: {
    justifyContent: 'flex-start',
  },
  label: {
    fontFamily: 'inherit',
  },
});

export type NavBackButtonProps = {
  label: string;
  onClick: () => void;
};

export const NavBackButton = ({ label, onClick }: NavBackButtonProps) => {
  const theme = useMantineTheme();
  return (
    <Button
      variant="default"
      fullWidth
      mb="space1"
      styles={styles(theme)}
      radius={8}
      leftSection={<IconArrowLeft color={theme.colors.gray[8]} size={16} />}
      onClick={onClick}
    >
      <Text c="grey.8">{label}</Text>
    </Button>
  );
};
