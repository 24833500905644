import { useState } from 'react';
import { ExternalMailStatus, Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useParams } from 'react-router';
import { isNonNullish, isNullish } from 'remeda';
import { AccountManagerSelector } from 'shared/components';
import AYSModal from 'shared/components/AYSModal';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import { RichTextArea } from 'shared/components/Inputs';
import {
  useDeleteExternalMail,
  useLoadCompanyConfiguration,
  useLoadExternalMail,
  useLoadExternalMailboxes,
  useUpdateExternalMail,
} from 'shared/hooks';
import { useSendExternalMail } from 'shared/hooks/use-send-external-mails';
import { Button, ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { LoadingOverlay } from '@mantine/core';

import { ExternalMailAction } from '../ExternalMailActions/ExternalMailAction';
import { ExternalMailActionDebtor } from '../ExternalMailActions/ExternalMailActionDebtor';

import { ExternalMailDetailHead } from './ExternalMailDetailHead';
import { ExternalMailDetailInfoRight } from './ExternalMailDetailInfoRight';
import { ExternalMailReplyAction } from './ExternalMailReplyAction';
import { ExternalMailReplyHead } from './ExternalMailReplyHead';

import styleIdentifiers from './ExternalMailDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailDetailProps = {
  emailId: Id;
};

export const ExternalMailDetail = ({ emailId: emailIdFromProps }: ExternalMailDetailProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { company } = useLoadCompanyConfiguration();

  const { id: emailIdFromParams } = useParams<{ id: string }>();
  const emailId = emailIdFromParams ?? emailIdFromProps;

  const [isFullDetailOpen, setIsFullDetailOpen] = useState(false);
  const [isReplying, setReply] = useState(false);
  const [replyBody, setReplyBody] = useState<string>();
  const [replySubject, setReplySubject] = useState<string>();
  const [attachments, setAttachments] = useState<File[]>([]);

  const { externalMail, externalMailError, isExternalMailLoading, refetchExternalMail } =
    useLoadExternalMail(emailId);
  const { externalMailboxes } = useLoadExternalMailboxes();

  const { sendExternalMail, isSendExternalMailLoading } = useSendExternalMail();
  const { updateExternalMail } = useUpdateExternalMail();
  const { deleteExternalMail } = useDeleteExternalMail();

  if (isExternalMailLoading && !externalMail) return <LoadingOverlay visible />;

  if (externalMailError || !externalMail) return <span>{t(i18nKeys.ERROR.ERROR)}</span>;

  const goToList = () => history.goBack();

  const {
    id,
    attributes: { subject, body, status, from_mail, reply_mails, to_mail },
  } = externalMail;

  const { data: replyMails } = reply_mails;

  const handleAssign = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.TASK.ACTIONS.ASSIGN),
      children: (
        <ExternalMailAction
          externalMail={externalMail}
          onSubmit={(data) => {
            updateExternalMail(
              { id, debtor_id: data.debtor_id },
              {
                onSuccess: () => dialogHide(DialogShowId.CUSTOM),
              },
            );
          }}
        >
          <ExternalMailActionDebtor />
          <div style={{ height: '10px' }} />
          <AccountManagerSelector loadInactive name="user_id" />
        </ExternalMailAction>
      ),
    });

  const handleChangeStatus = () => {
    const newStatus =
      status === ExternalMailStatus.processed
        ? ExternalMailStatus.unprocessed
        : ExternalMailStatus.processed;
    updateExternalMail({ id, status: newStatus });
  };

  const handleDelete = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_EXTERNAL_MAIL)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteExternalMail(
              { id },
              {
                onSuccess: () => {
                  dialogHide(DialogShowId.CONFIRM);
                  history.push('/mails');
                },
              },
            )
          }
        />
      ),
    });

  const replyToMail = () => {
    sendExternalMail(
      {
        original_mail_id: id as string,
        body: replyBody!,
        subject: replySubject!,
        to_mail: [from_mail],
        attachments,
      },
      {
        onSuccess: () => {
          refetchExternalMail();
          setReply(false);
        },
      },
    );
  };

  const userSignature = externalMailboxes?.data[0].attributes.signature;

  const customDomain = company.custom_domain;
  const isSameDomain = isNullish(customDomain)
    ? false
    : to_mail.some((mail) => mail.includes(customDomain));
  const shouldShowHeaderBar = emailIdFromParams; // This is used to hide the header bar when email is opened in a pop up/modal

  const replyButtonTitle = () => {
    if (customDomain && isSameDomain) return '';
    return t(customDomain ? i18nKeys.CUSTOM_DOMAIN.NO_MATCH : i18nKeys.CUSTOM_DOMAIN.NO_DOMAIN);
  };

  return (
    <article className={styles('external-mail-detail')}>
      {shouldShowHeaderBar && (
        <nav className={styles('title-line')}>
          <div>
            <Icon name={IconName.MINIMAL_LEFT} onClick={goToList} />
            {t(i18nKeys.MAILS.NAV)}
          </div>
          <div className={styles('buttons-wrapper')}>
            <Button
              label={t(i18nKeys.TASK.ACTIONS.ASSIGN)}
              onClick={handleAssign}
              iconLeft={IconName.USER}
              noMargin
            />
            <Button
              label={t(
                i18nKeys.EXTERNAL_MAIL.ACTIONS[
                  `${status === ExternalMailStatus.processed ? 'UN' : ''}PROCESS`
                ],
              )}
              onClick={handleChangeStatus}
              iconLeft={
                IconName[status === ExternalMailStatus.processed ? 'SIMPLE_REMOVE' : 'CHECK']
              }
              noMargin
            />
            <Button
              label={(<Icon name={IconName.TRASH_SIMPLE} />) as any}
              onClick={handleDelete}
              color={ButtonColor.RED}
              noMargin
            />
            <a href="#reply-to-mail">
              <Button
                label={t(i18nKeys.REPLY)}
                onClick={() => {
                  setReply(true);
                  setReplySubject(`RE: ${subject}`);
                }}
                color={ButtonColor.GREEN}
                iconLeft={IconName.SEND}
                // disabled={!customDomain || !isSameDomain}
                title={replyButtonTitle()}
                noMargin
              />
            </a>
          </div>
        </nav>
      )}

      <div>
        <Card
          className={styles('mail')}
          title={subject}
          subtitle={
            <ExternalMailDetailHead externalMail={externalMail} fullDetail={isFullDetailOpen} />
          }
          infosRight={
            <ExternalMailDetailInfoRight
              externalMail={externalMail}
              setFullDetail={() => setIsFullDetailOpen(!isFullDetailOpen)}
            />
          }
        >
          {isNonNullish(body) && <iframe title="email" srcDoc={body} />}
        </Card>
        {replyMails.length > 0 && (
          <>
            <h3 className={styles('centered-text-with-lines')}>
              <span>{t(i18nKeys.REPLIES)}</span>
            </h3>
            {replyMails.map((replyMail) => (
              <Card
                className={styles('mail', 'mail-reply')}
                key={replyMail.id}
                title={replyMail.attributes.subject}
                subtitle={<ExternalMailDetailHead externalMail={replyMail} />}
                infosRight={<ExternalMailDetailInfoRight externalMail={replyMail} isReply />}
              >
                {isNonNullish(replyMail.attributes.body) && (
                  <iframe title="email" srcDoc={replyMail.attributes.body} />
                )}
              </Card>
            ))}
          </>
        )}
        {isReplying && (
          <div id="reply-to-mail" className={styles('reply-to-mail')}>
            <Card
              className={styles('mail', 'reply-to-card')}
              title={t(i18nKeys.REPLY)}
              subtitle={
                <ExternalMailReplyHead
                  externalMail={externalMail}
                  originalSubject={subject}
                  subject={replySubject}
                  setSubject={setReplySubject}
                />
              }
              infosRight={
                <ExternalMailReplyAction
                  isLoading={isSendExternalMailLoading}
                  disabled={!(replyBody && replySubject)}
                  onSubmit={replyToMail}
                />
              }
            >
              <RichTextArea
                content={`${replyBody ?? ''}${userSignature ? `<br/><br/>${userSignature}` : ''}`}
                onChange={setReplyBody}
                onFileChange={setAttachments}
              />
            </Card>
          </div>
        )}
      </div>
    </article>
  );
};
