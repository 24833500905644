import { filter, isTruthy, partition, prop } from 'remeda';

import { SpotlightActionData, SpotlightActionGroupData } from '@mantine/spotlight';

import { Menu, MenuItem } from './types';

export const isMenu = (el: MenuItem | Menu): el is Menu => 'parent' in el && 'items' in el;

/**
 * Finds the relevant menu or submenu based on the given location path.
 * Used to display the correct menu when the app loads on a specific page other than the dashboard
 */
export const findMenuForLocation = (menu: Menu, locationPath: string): Menu | null => {
  const [submenus, navItems] = partition(menu.items, isMenu);

  const pathsOfItemsInMenu = filter(navItems.map(prop('path')), isTruthy);
  if (pathsOfItemsInMenu.some((path) => locationPath.includes(path))) return menu;

  for (const submenu of submenus) {
    const parentMenu = findMenuForLocation(submenu, locationPath);
    if (parentMenu) return parentMenu;
  }

  return null;
};

export function menuToSpotlightActions(
  menu: Menu,
  navigate: (url: string) => void,
  menuLabels: Array<string> = [],
): Array<SpotlightActionData | SpotlightActionGroupData> {
  const newMenuLabels = [...menuLabels, menu.label];
  return menu.items.reduce(
    (acc, item) => {
      if (isMenu(item)) return [...acc, ...menuToSpotlightActions(item, navigate, newMenuLabels)];

      return [
        ...acc,
        {
          id: `${menu.label}-${item.label}`,
          label: item.label,
          description: newMenuLabels
            .map((label) => (label === 'root' ? 'Main menu' : label))
            .join(' > '),
          onClick: () => navigate(item.path ?? ''),
        },
      ];
    },
    [] as Array<SpotlightActionData | SpotlightActionGroupData>,
  );
}
