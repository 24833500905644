import classNames from 'classnames/bind';
import { Icon, IconName } from 'shared/components/Icon';
import Logo from 'shared/components/Logo';
import { OnClickFunction } from 'types/html-type';

import styleIdentifiers from './onboardingItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  title: string;
  className?: string;
  icon?: IconName;
  onClick: OnClickFunction;
  paddingBottom?: boolean;
  logo?: boolean;
  description?: string;
};

export default function OnboardingItem({
  title,
  className,
  description,
  icon,
  onClick,
  paddingBottom,
  logo,
}: Props) {
  return (
    <div className={styles('OnboardingItem')}>
      <div className={styles('wrapper', className, 'actionable')} onClick={onClick}>
        <div className={styles('content')}>
          {icon && <Icon name={icon} className={styles('dark')} size="25px" />}
          {logo && <Logo variant="logo" height="25px" />}
          <span className={styles('title')}>{title}</span>

          {description && (
            <div className={styles('description', paddingBottom && 'padding-bottom')}>
              {description}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
