import { PartialDeep } from 'type-fest';

import { createTheme, MantineTheme, Pagination } from '@mantine/core';

export const ThemedMantinePagination: PartialDeep<MantineTheme> = createTheme({
  components: {
    Pagination: Pagination.extend({
      defaultProps: {
        color: 'purple.5',
      },
    }),
  },
});
