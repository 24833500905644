import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';
import { useLoadNotificationsCount } from 'shared/hooks/use-load-notifications-count';

import {
  IconAlarm,
  IconBell,
  IconChartAreaLine,
  IconDashboard,
  IconReport,
  IconUsers,
} from '@tabler/icons-react';

import { Menu, MenuItem } from '../types';
import { isMenu } from '../utils';

import { useBuildBankAccountMenu } from './use-build-bank-account-menu';
import { useBuildFollowupMenu } from './use-build-followup-menu';
import { useBuildInvoiceMenu } from './use-build-invoice-menu';
import { useBuildRemindersMenu } from './use-build-reminders-menu';
import { useBuildSettingMenu } from './use-build-setting-menu';

export const populateParent = (menu: Menu, parent: Menu | null): Menu => {
  menu.parent = parent;
  menu.items.map((item) => {
    if (!isMenu(item)) return null;
    return populateParent(item, menu);
  });

  return menu;
};

export function useBuildMenuItems(): Menu {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const { notificationsCount } = useLoadNotificationsCount();

  const invoiceMenu = useBuildInvoiceMenu();
  const followUpMenu = useBuildFollowupMenu();
  const remindersMenu = useBuildRemindersMenu();
  const bankAccountMenu = useBuildBankAccountMenu();
  const settingMenu = useBuildSettingMenu();

  return useMemo(() => {
    const items: Array<MenuItem | Menu> = [
      {
        path: '/dashboard',
        label: t(i18nKeys.COMMON.DASHBOARD),
        Icon: IconDashboard,
      },
      followUpMenu,
      remindersMenu,
      {
        path: '/clients',
        label: t(i18nKeys.CLIENTS),
        Icon: IconUsers,
      },
      invoiceMenu,
      bankAccountMenu,
      {
        path: '/analytics',
        label: 'Analytics',
        Icon: IconChartAreaLine,
        showUpsell: !company?.package?.can_use_analytics,
        isExactRouteMatch: false,
      },
      {
        path: '/reports',
        label: t(i18nKeys.REPORTS.INDEX.TITLE),
        Icon: IconReport,
        showUpsell: !company?.package?.can_use_reports,
      },
      {
        path: '/activities',
        label: t(i18nKeys.NAV.ACTIVITY_BOOK),
        Icon: IconBell,
        unreadCount: notificationsCount,
      },
      settingMenu,
    ];

    const menu: Menu = {
      parent: null,
      label: 'root',
      Icon: IconAlarm,
      items,
    };

    return populateParent(menu, null);
  }, [company, followUpMenu, bankAccountMenu, invoiceMenu, notificationsCount, settingMenu, t]);
}
