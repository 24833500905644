import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { upperFirst } from 'lodash-es';
import { useHistory } from 'react-router';
import { isEmpty, times } from 'remeda';
import { useArchiveReport, useLoadReports } from 'shared/hooks';
import { PageTitle } from 'shared/layout';

import {
  ActionIcon,
  Button,
  Card,
  Center,
  Group,
  Skeleton,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import { PeriodicityLabel } from '../components';

export const ReportsIndex = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { reports } = useLoadReports();
  const { archiveReport, isArchiveReportLoading } = useArchiveReport();

  const handleArchiveReport = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();

    modals.openConfirmModal({
      centered: true,
      title: t(i18nKeys.REPORTS.SHOW.DELETE_REPORT),
      children: <Text>{t(i18nKeys.REPORTS.SHOW.AYS_DELETE)}</Text>,
      labels: { cancel: t(i18nKeys.CANCEL), confirm: t(i18nKeys.CONFIRM) },
      confirmProps: { color: 'red', loading: isArchiveReportLoading },
      onConfirm: () => archiveReport(id),
      onCancel: modals.closeAll,
    });
  };

  const tableBody = reports
    ? reports.map((task, index) => (
        <Table.Tr
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/reports/${task.id}`)}
        >
          <Table.Td>
            <Text c="gray.8" fz={14}>
              {upperFirst(task.name)}
            </Text>
          </Table.Td>
          <Table.Td>
            <PeriodicityLabel type={task.periodicity.type} />
          </Table.Td>
          <Table.Td>
            {task.lastExecutedAt ? (
              task.lastExecutedAt.format('dddd MMMM D, YYYY [at] hh:mm')
            ) : (
              <Text fz={14} fs="italic" c="dimmed">
                {t(i18nKeys.REPORTS.INDEX.EXECUTION_DATE.NOT_EXECUTED)}
              </Text>
            )}
          </Table.Td>
          <Table.Td>
            <Group>
              <ActionIcon
                onClick={(e) => handleArchiveReport(e, task.id!)}
                size="input-xs"
                variant="light"
                color="red"
                h="100%"
              >
                <IconTrash stroke={1.5} size={16} />
              </ActionIcon>
            </Group>
          </Table.Td>
        </Table.Tr>
      ))
    : times(5, (index) => (
        <Table.Tr key={index}>
          <Table.Td>
            <Skeleton height={20} w="80%" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={20} w="80%" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={20} w="80%" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={20} w={20} mr={15} />
            <Skeleton height={20} w={20} />
          </Table.Td>
        </Table.Tr>
      ));

  return (
    <>
      <PageTitle>
        {t(i18nKeys.REPORTS.INDEX.TITLE)}
        <PageTitle.Actions>
          <Button
            onClick={() => {
              localStorage.removeItem('persist-report-creation');
              history.push('/reports/new');
            }}
            rightSection={<IconPlus stroke={1.5} />}
          >
            {t(i18nKeys.REPORTS.NEW.ACTION_BUTTON)}
          </Button>
        </PageTitle.Actions>
      </PageTitle>
      <Card radius="md" mt="md" p={0}>
        {isEmpty(tableBody) ? (
          <Center h={200}>
            <Text>{t(i18nKeys.REPORTS.SHOW.NO_REPORTS_YET)}</Text>
          </Center>
        ) : (
          <Table
            highlightOnHover
            highlightOnHoverColor="purple.0"
            horizontalSpacing="md"
            verticalSpacing="sm"
          >
            <Table.Thead>
              <Table.Tr
                bg="purple.1"
                style={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}
              >
                <Table.Th>
                  <Text c="purple.5" fz={14} fw={400}>
                    {t(i18nKeys.REPORTS.INDEX.ROWS.NAME)}
                  </Text>
                </Table.Th>
                <Table.Th>
                  <Text c="purple.5" fz={14} fw={400}>
                    {t(i18nKeys.REPORTS.INDEX.ROWS.PERIODICITY)}
                  </Text>
                </Table.Th>
                <Table.Th>
                  <Text c="purple.5" fz={14} fw={400}>
                    {t(i18nKeys.REPORTS.INDEX.ROWS.EXECUTION_DATE)}
                  </Text>
                </Table.Th>
                <Table.Th>
                  <Text c="purple.5" fz={14} fw={400}>
                    {t(i18nKeys.REPORTS.INDEX.ROWS.ACTIONS)}
                  </Text>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {isEmpty(tableBody) ? (
                <Center>
                  <Text m="lg">Nope</Text>
                </Center>
              ) : (
                tableBody
              )}
            </Table.Tbody>
          </Table>
        )}
      </Card>
    </>
  );
};
