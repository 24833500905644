import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import { RecoveryPlan } from 'shared/components/RecoveryPlan';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, Dropdown, Input } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { recoveryPlanSerializer, treatRecoveryPlan } from 'shared/serializer';
import { usePrevious } from 'shared/utils/hooks';
import { settingsActions } from 'store/settings/settings.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { RecoverActionType, RecoveryPlan as RecoveryPlanT } from 'types/recovery-plan';

import { Box, Card, Group, Text } from '@mantine/core';

import RecoveryStepForm from './RecoveryStepForm';

import styleIdentifiers from './EditDebtCollectionCustomization.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum RECOVER_ACTION_TYPE {
  INVOICE = 'invoice',
  CREDIT_NOTE = 'credit_note',
  PREVENTIVE_REMINDER = 'preventive_reminder',
  FIRST_REMINDER = 'first_reminder',
  INTERMEDIATE_REMINDER = 'intermediate_reminder',
  LAST_REMINDER = 'last_reminder',
  ADDITIONAL_REMINDER = 'additional_reminder',
  FORMAL_NOTICE = 'formal_notice',
  PLAN_CREATION = 'plan_creation',
  PLAN_UPDATE = 'plan_update',
  PLAN_CANCEL = 'plan_cancel',
}
type RecoveryPlanType = RecoveryPlanT & {
  step_deleted?: boolean;
  isDirty: boolean;
};

export default function EditDebtCollectionCustomization() {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [dbc, setDbc] = useState<RecoveryPlanType>();
  const [recoveryPlan, setRecoveryPlan] = useState<RecoveryPlanType>();
  const [feeStartingStep, setFeeStartingStep] = useState<number | null>(null);
  const form = useForm({ shouldUnregister: true });
  const {
    register,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = form;
  const { params } = useRouteMatch();
  const previousStepLength = usePrevious(dbc?.recovery_steps);

  useEffect(() => {
    if (dbc) {
      const newDbc = { ...dbc } as any;

      newDbc.recovery_steps = dbc!.recovery_steps.slice();
      let shouldAddFees = false;
      newDbc.recovery_steps = newDbc.recovery_steps.map((step) => {
        if (feeStartingStep === step.order) {
          shouldAddFees = true;
        }
        return {
          ...step,
          fee_starting_step: feeStartingStep === step.order,
          fee_step_reached: shouldAddFees,
        };
      });
      if (previousStepLength !== undefined) {
        newDbc.isDirty = true;
      }
      setDbc(newDbc);
    }
  }, [feeStartingStep, dbc?.recovery_steps.length]);

  useEffect(() => {
    if (match.path.indexOf('create') !== -1) {
      setDbc({
        id: undefined as any,
        recovery_steps: [],
        name: '',
        isDirty: true,
        invoices_using: 0,
        debtors_using: 0,
      });
    } else {
      settingsActions.getRecoveryPlan({
        id: (params as any).id,
        callback: ({ data }) => {
          const recoveryPlan = treatRecoveryPlan(data) as RecoveryPlanType;
          reset({ name: recoveryPlan.name });
          setFeeStartingStep(
            recoveryPlan.recovery_steps.find((step) => step.fee_step_reached)?.order || null,
          );
          setDbc(recoveryPlan);
          setRecoveryPlan(recoveryPlan);
        },
      });
    }
  }, [location]);

  const editStep = (stepIndex) => () => {
    const index = stepIndex === -1 ? 0 : stepIndex;
    sideMenuShow({
      unmount: true,
      content: (
        <RecoveryStepForm
          step={dbc!.recovery_steps[index]}
          availableTemplateTypes={[dbc!.recovery_steps[index].step_type]}
          onSelectTemplate={(step) => {
            const newDbc = { ...dbc!, isDirty: true };
            newDbc.recovery_steps[index] = {
              ...step,
              order: index,
            } as any;
            setDbc(newDbc);
            sideMenuHide();
          }}
        />
      ),
    });
  };

  const goToList = () => {
    history.push('/settings/workflows/recovery-plans');
  };

  const addStep = (index) => (onlyPreventiveReminder: boolean, before_due_date: boolean) => {
    const preventiveReminder = dbc!.recovery_steps.find(
      (e) => e.step_type === 'preventive_reminder',
    );

    const firstReminder = dbc!.recovery_steps.find((e) => e.step_type === 'first_reminder');

    const lastReminder = dbc!.recovery_steps.find((e) => e.step_type === 'last_reminder');

    let availableTemplateTypes: RecoverActionType[] = [];

    if (before_due_date && !preventiveReminder) {
      availableTemplateTypes = ['preventive_reminder'];
    } else if (!before_due_date && !firstReminder) {
      availableTemplateTypes = ['first_reminder'];
    } else if (
      !before_due_date &&
      firstReminder &&
      !lastReminder &&
      index > dbc!.recovery_steps.indexOf(firstReminder)
    ) {
      availableTemplateTypes = ['intermediate_reminder', 'last_reminder'];
    } else if (
      !before_due_date &&
      firstReminder &&
      lastReminder &&
      index > dbc!.recovery_steps.indexOf(firstReminder) &&
      index <= dbc!.recovery_steps.indexOf(lastReminder)
    ) {
      availableTemplateTypes = ['intermediate_reminder'];
    }

    sideMenuShow({
      unmount: true,
      content: (
        <RecoveryStepForm
          availableTemplateTypes={availableTemplateTypes}
          onlyTask={!availableTemplateTypes.length}
          onSelectTemplate={(step) => {
            step.before_due_date = before_due_date;
            const newDbc = { ...dbc!, isDirty: true };
            const recoverySteps = newDbc.recovery_steps.slice();
            recoverySteps.splice(index, 0, step);
            let shouldAddFees = false;
            let newOrder: any = null;
            newDbc.recovery_steps = recoverySteps.map((step, index) => {
              if (step.fee_starting_step) {
                shouldAddFees = true;
                newOrder = index;
              }
              return {
                ...step,
                fee_starting_step:
                  step.fee_starting_step && step.fee_starting_step === step.fee_step_reached,
                fee_step_reached: shouldAddFees,
                order: index,
              };
            });

            setDbc(newDbc);
            setFeeStartingStep(newOrder);
            sideMenuHide();
          }}
        />
      ),
    });
  };

  const updatePlan = (callback, steps_switch?: any) => {
    settingsActions[dbc!.id ? 'setRecoveryPlan' : 'addRecoveryPlan']({
      id: dbc!.id,
      data: recoveryPlanSerializer(
        { ...dbc, name: getValues().name, steps_switch },
        (dbc!.id && recoveryPlan) || undefined,
      ),
      callback: ({ data }) => {
        dialogHide(DialogShowId.CUSTOM);
        if (callback) {
          callback();
        } else if (dbc!.id) {
          const plan = treatRecoveryPlan(data);
          setFeeStartingStep(
            plan.recovery_steps.find((step) => step.fee_step_reached)?.order || null,
          );
          setRecoveryPlan(plan);
          setDbc({ ...plan, isDirty: false });
        } else {
          setDbc({ ...dbc, isDirty: false } as any);
          setTimeout(() => {
            history.push(`/settings/workflows/recovery-plans/edit/${data.id}`);
          }, 50);
        }
      },
    });
  };

  const save = (isSaveAndQuit) => async (callback) => {
    const isValid = await trigger();

    if (isValid) {
      if (recoveryPlan && recoveryPlan!.invoices_using > 0 && dbc!.step_deleted) {
        MoveRecoveryPlanInvoices({
          title: t(i18nKeys.PLAN.INVOICES),
          newPlan: dbc,
          oldPlan: recoveryPlan,
          allStepRequired: true,
          onSubmit: (steps_switch) => {
            updatePlan(isSaveAndQuit && callback, steps_switch);
          },
        });
      } else {
        updatePlan(isSaveAndQuit && callback);
      }
    }
  };

  const deletePlan = () => {
    setDbc({
      ...dbc,
      isDirty: false,
    } as any);
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      keepMountOnExit: true,
      title: t(i18nKeys.CONFIRM),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_RECOVERY_PLAN)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            settingsActions.deleteRecoveryPlan({
              id: dbc!.id,
              callback: () => {
                history.push('/settings/workflows/recovery-plans');
              },
            })
          }
        />
      ),
    });
  };

  const deleteStep = (index: number) => () => {
    const newDbc = { ...dbc!, isDirty: true };
    newDbc.recovery_steps = newDbc.recovery_steps!.slice();
    if (newDbc.recovery_steps[index].fee_starting_step) {
      if (newDbc.recovery_steps[index + 1]) {
        newDbc.recovery_steps[index + 1] = {
          ...newDbc.recovery_steps[index + 1],
          fee_starting_step: true,
          fee_step_reached: true,
        };
        setFeeStartingStep(newDbc.recovery_steps[index + 1].order);
      } else {
        setFeeStartingStep(null);
      }
    }
    if (newDbc.recovery_steps[index].invoices_count > 0) {
      newDbc.step_deleted = true;
    }
    newDbc.recovery_steps.splice(index, 1);

    setDbc(newDbc);
  };

  const duplicate = () => {
    settingsActions.duplicateRecoveryPlan({
      id: dbc!.id,
      callback: ({ data }) => {
        history.push(`/settings/workflows/recovery-plans/edit/${data.id}`);
      },
    });
  };
  const setDirty = () => {
    setDbc({ ...dbc, isDirty: true } as any);
  };

  if (!dbc) return null;

  return (
    <div className={styles('edit-debt-collection-customization')}>
      <PageTitle>
        {t(i18nKeys.AUTOMATED_PLANS)}
        <PageTitle.Actions>
          <Dropdown
            sideMenuInMobile
            sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
            className={styles('actions-dropdown')}
            toggleContent
            selectorContent={
              <Button
                className={styles('action')}
                noMargin
                label={t(i18nKeys.FORM.ACTIONS)}
                iconRight={IconName.ARROW_BOTTOM_ROUNDED}
              />
            }
          >
            {recoveryPlan?.id && (
              <>
                <div className={styles('dropdown-item')} onClick={deletePlan}>
                  {t(i18nKeys.DELETE)}
                </div>
                <div className={styles('dropdown-item')} onClick={duplicate}>
                  {t(i18nKeys.DUPLICATE)}
                </div>
              </>
            )}
            <div className={styles('dropdown-item')} onClick={save(false)}>
              {t(i18nKeys.SAVE)}
            </div>
          </Dropdown>
          {recoveryPlan?.id && (
            <>
              <Button
                label={t(i18nKeys.DELETE)}
                noMargin
                color={ButtonColor.GREY}
                onClick={deletePlan}
              />
              <Button label={t(i18nKeys.DUPLICATE)} noMargin onClick={duplicate} />
            </>
          )}
          <Button
            label={t(i18nKeys.SAVE)}
            noMargin
            color={ButtonColor.MAIN}
            onClick={save(false)}
          />
        </PageTitle.Actions>
      </PageTitle>
      <NavigationPrompt when={dbc.isDirty} onSaveAndQuit={save(true)} />
      <Card radius={16}>
        <Group justify="space-between">
          <Group>
            <Icon name={IconName.MINIMAL_LEFT} onClick={goToList} />{' '}
            <Input
              register={register('name', { required: true })}
              style={{ width: '400px' }}
              placeholder={t(i18nKeys.PLAN.NAME)}
              errorMessage={errors.name}
              onValueChanged={setDirty}
              noMargin
            />
          </Group>
          <Box>
            {recoveryPlan && (
              <Text>
                {t(i18nKeys.FORM.REFERENCE)} : {recoveryPlan.id}
              </Text>
            )}
          </Box>
        </Group>

        <FormProvider {...form}>
          {!company.package.can_edit_plan ? (
            <RecoveryPlan
              onClickOnStep={editStep}
              setLateFees={setFeeStartingStep}
              recoverPlan={dbc}
              editStep={editStep}
              lateFeesStep={feeStartingStep}
            />
          ) : (
            <RecoveryPlan
              onClickOnStep={editStep}
              setLateFees={setFeeStartingStep}
              recoverPlan={dbc}
              addStep={addStep}
              editStep={editStep}
              deleteStep={deleteStep}
              lateFeesStep={feeStartingStep}
              editMode
            />
          )}
        </FormProvider>
      </Card>
    </div>
  );
}
