import { App } from 'app';
import BadCompany from 'app/Public/BadCompany';
import { MaintenancePage } from 'app/Public/MaintenancePage';
import { ServerError } from 'app/Public/ServerError';
import { TenantValidator } from 'app/TenantValidator';
import { Providers } from 'init/Providers';
import { createRoot } from 'react-dom/client';
import { Route, Switch } from 'react-router';

const IS_MAINTENANCE_MODE = false;

const render = async () => {
  const rootHtmlElement = document.getElementById('root');
  if (!rootHtmlElement) return;

  const root = createRoot(rootHtmlElement);

  if (IS_MAINTENANCE_MODE) {
    root.render(
      <Providers>
        <MaintenancePage />
      </Providers>,
    );
    return;
  }

  root.render(
    <Providers>
      <Switch>
        <Route path="/server-error" component={ServerError} />
        <Route exact path="/bad-company" component={BadCompany} />
        <TenantValidator>
          <App />
        </TenantValidator>
      </Switch>
    </Providers>,
  );
};

render();
