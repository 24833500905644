import { useEffect } from 'react';
import { MantineSettingsCard } from 'app/Private/Settings/MantineSettingsCard';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { useLoadCompanyConfiguration } from 'shared/hooks';

import { Box, Title } from '@mantine/core';

import { GeneralConditionsPartialSendTos } from './GeneralConditionsPartialForm/GeneralConditionsPartialSendTos';
import { PaymentPlanPartialForm } from './PaymentPlanPartialForm/PaymentPlanPartialForm';
import { SalePreferencesPartialForm } from './SalePreferencesPartialForm/SalePreferencesPartialForm';
import { CompanyEdit } from './PreferencesForm.constants';

import styleIdentifiers from './PreferencesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: SubmitHandler<CompanyEdit>;
};

export const PreferencesForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const { company, isCompanyFetching } = useLoadCompanyConfiguration();

  const form = useForm<CompanyEdit>({
    defaultValues: company,
  });

  const {
    formState: { isDirty },
    reset,
    handleSubmit,
  } = form;

  useEffect(() => {
    if (!isCompanyFetching) {
      reset({
        ...company,
      });
    }
  }, [company, reset, isCompanyFetching]);

  return (
    <FormProvider {...form}>
      <MantineSettingsCard
        title={t(i18nKeys.SALE_CONDITION)}
        onSave={isDirty ? handleSubmit(onSubmit) : undefined}
      >
        <Box mb="xl" mx="sm">
          <NavigationPrompt when={isDirty} onSaveAndQuit={handleSubmit(onSubmit)} />
          <form className={styles('PreferencesForm')} onSubmit={handleSubmit(onSubmit)}>
            <SalePreferencesPartialForm />
            <GeneralConditionsPartialSendTos />
            <Title order={5} mt="xl" className={styles('margin')}>
              {t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)}
            </Title>
            <PaymentPlanPartialForm />
          </form>
        </Box>
      </MantineSettingsCard>
    </FormProvider>
  );
};
