import { AxiosError, AxiosInstance } from 'axios';
import { isNullish } from 'remeda';
import { CalculationType, InterestCalculationType } from 'shared/forms/PreferencesForm';
import { CurrencyCodeSchema } from 'types/currency';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { Tenant } from './use-validate-tenant';
import {
  addResourceNameToQueryResult,
  useLegacyAxiosInstance,
  UseResourceQueryResult,
} from './utils';

const BankAccountSchema = z
  .object({
    id: z.string(),
    attributes: z.object({
      iban: z.string(),
      bic: z.string().nullable(),
      currency: CurrencyCodeSchema.nullable(),
      type: z.string(),
    }),
  })
  .transform((item) => ({
    id: item.id,
    ...item.attributes,
  }));

const DefaultTemplateSchema = z.object({
  invoice: z.object({
    id: z.number().nullable(),
    name: z.string().nullable(),
  }),
  credit_note: z.object({
    id: z.number().nullable(),
    name: z.string().nullable(),
  }),
  formal_notice: z.object({
    id: z.number().nullable(),
    name: z.string().nullable(),
  }),
  additional_reminder: z.object({
    id: z.number().nullable(),
    name: z.string().nullable(),
  }),
});

const InvoiceCustomizationSchema = z.object({
  billing_logs: z
    .array(
      z
        .object({
          id: z.string(),
          attributes: z.object({
            prefix: z.string(),
            counter: z.number(),
            deletable: z.boolean(),
          }),
        })
        .transform((data) => ({
          id: data.id,
          ...data.attributes,
        })),
    )
    .optional(),
  color: z.string().nullable(),
  logo_url: z.string().url().nullable(),
});

const RecoveryPlanSchema = z
  .object({
    data: z
      .object({
        id: z.string(),
        attributes: z.object({
          name: z.string(),
          recovery_plan_id: z.number().optional(),
          recovery_plan_name: z.string().optional(),
          company_default: z.boolean(),
          debtors_using: z.number(),
          invoices_using: z.number(),
          recovery_steps_count: z.number(),
        }),
      })
      .nullable(),
  })
  .transform(({ data }) => ({
    id: data?.id,
    ...data?.attributes,
  }));

const CompanyConfigurationSchema = z
  .object({
    data: z.object({
      id: z.string(),
      attributes: z.object({
        vat_number: z.string(),
        codabox_token: z.string().nullable(),
        print_mode: z.string(),
        print_color: z.string(),
        fee_starting_step: z.string(),
        first_reminder_delay: z.number(),
        last_reminder_delay: z.number(),
        locale: z.string(),
        payment_method: z.string().nullable(),
        company_email: z.string().email(),
        reminder_threshold_type: z.string().nullable(),
        send_copy: z.boolean(),
        send_copy_emails: z.array(z.string().email().optional()),
        send_tos: z.boolean(),
        threshold_amount: z.string().nullable(),
        banking_method: z.string().nullable(),
        default_bank_account_id: z.number().nullable(),
        default_bank_account_type: z.string().nullable(),
        automatic_reminders: z.boolean(),
        days_between_reminder: z.number(),
        minimum_saldo_sending: z.number(),
        send_to_all_emails: z.boolean(),
        currency: CurrencyCodeSchema,
        currency_conversion_date: z.string(),
        allow_payment_plans: z.boolean(),
        default_from: z.string().nullable(),
        ignore_late_fees: z.boolean(),
        ape_code: z.string().nullable(),
        siren: z.string().nullable(),
        autoclose_tasks: z.boolean(),
        onboarding_done: z.boolean(),
        onboarding_step: z.number().nullable(),
        custom_domain: z.string().nullable(),
        name: z.string(),
        sending_media: z.array(
          z.object({
            name: z.string(),
            order: z.number().optional(),
            force_sending: z.any().transform((item) => {
              if (typeof item === 'boolean') return item;
              if (item === 'true') return true;
              if (item === 'false') return false;
              return false;
            }),
          }),
        ),
        default_templates: DefaultTemplateSchema,
        address: z
          .object({
            id: z.string(),
            attributes: z.object({
              street_name: z.string(),
              street_number: z.string(),
              street_box: z.string().nullable(),
              zip_code: z.string(),
              city: z.string(),
              country_code: z.string(),
              additional_line_1: z.string().nullable(),
              additional_line_2: z.string().nullable(),
              country: z.string(),
            }),
          })
          .nullable()
          .transform((item) => {
            if (isNullish(item)) return null;
            return {
              id: item.id,
              ...item.attributes,
            };
          }),
        recovery_plan: RecoveryPlanSchema,
        active_plans_count: z.number(),
        phones: z
          .array(
            z
              .object({
                id: z.string(),
                attributes: z.object({
                  number: z.string(),
                }),
              })
              .transform((item) => ({
                id: item.id,
                ...item.attributes,
              })),
          )
          .optional(),
        terms_and_conditions: z.array(
          z
            .object({
              id: z.string(),
              attributes: z.object({
                name: z.string(),
                default: z.boolean(),
                minimum_payment_terms_delay: z.number(),
                use_belgian_law: z.boolean(),
                interest: z
                  .object({
                    value: z.coerce.number(),
                    period: z.nativeEnum(CalculationType),
                  })
                  .optional(),
                fix_fee: z
                  .object({
                    value: z.coerce.number(),
                  })
                  .optional(),
                penalty_clause: z
                  .object({
                    type: z.nativeEnum(InterestCalculationType),
                    value: z.coerce.number(),
                    minimum: z.coerce.number(),
                  })
                  .optional(),
                has_pdf: z.boolean(),
                deletable: z.boolean(),
              }),
            })
            .transform((item) => ({
              id: item.id,
              ...item.attributes,
            })),
        ),
        bank_accounts: z.array(BankAccountSchema),
        company_bank_accounts: z.array(BankAccountSchema),
        can_edit_iban: z.boolean(),
        api: z.object({
          use_public_api: z.boolean(),
          direct_billing_by_integrator: z.boolean().nullable(),
          client_id: z.string().nullable(),
        }),
        package: z.object({
          included_callcenter_count: z.number().nullable(),
          included_legal_credits_count: z.number().nullable(),
          included_plan_count: z.number().nullable(),
          can_edit_plan: z.boolean(),
          can_create_template: z.boolean(),
          can_edit_template: z.boolean(),
          allowed_users_count: z.number().nullable().nullable(),
          allowed_invoices_count: z.number().nullable(),
          allowed_views_count: z.number().nullable(),
          can_use_all_filters: z.boolean(),
          can_use_online_payment: z.boolean(),
          can_use_tasks: z.boolean(),
          can_use_custom_variables: z.boolean(),
          can_use_automatic_assignations: z.boolean(),
          can_use_payment_plans: z.boolean(),
          can_use_advanced_payment_plans: z.boolean(),
          can_use_external_mails: z.boolean(),
          allowed_mailboxes_count: z.number().nullable(),
          can_use_custom_domain: z.boolean(),
          can_use_one_schema: z.boolean(),
          can_add_mailbox: z.boolean(),
          can_use_emails_activity: z.boolean(),
          can_use_reports: z.boolean(),
          can_use_monitoring: z.boolean(),
          can_use_analytics: z.boolean(),
          billing_cycle: z.number().nullable(),
          subscription_fee: z.string(),
        }),
        unvalidated_invoices_count: z.number(),
        unvalidated_debtors_count: z.number(),
        custom_variables: z.array(
          z
            .object({
              id: z.string(),
              attributes: z.object({
                name: z.string(),
                column_name: z.string(),
                model_type: z.string(),
                var_type: z.enum(['string', 'number', 'boolean', 'date']),
                api: z.string().nullable(),
                webhook: z.string().nullable(),
                display: z.string().nullable(),
                default_value: z.string().nullable(),
              }),
            })
            .transform((item) => ({
              id: item.id,
              ...item.attributes,
            })),
        ),
        card_information: z.object({
          can_edit_card: z.boolean(),
          last4_digits: z.string().nullable(),
          expiry_month: z.string().nullable(),
          expiry_year: z.string().nullable(),
          holder_name: z.string().nullable(),
          card_brand: z.string().nullable(),
        }),
        tp_accounting: z.object({
          integration_in_use: z.array(z.string()),
          issue_date_limit: z.coerce.date().nullable(),
          debtor_update: z.boolean(),
          automatic_reminders: z.boolean(),
          automatic_sync: z.boolean(),
          can_synchronise_import: z.boolean().nullable(),
          last_import_date: z.coerce.date().nullable(),
          manual_import_possible_from: z.coerce.date().nullable(),
          use_import: z.boolean(),
          use_export: z.boolean(),
          use_payment_matching: z.boolean(),
          use_payment_export: z.boolean(),
          company_selected: z.string().nullable(),
          additional_parameters: z.any(), // Not possible to be type because the structure can be anything
          active: z.array(z.string().optional()),
          can_configure: z.array(z.string().optional()),
        }),
        digiteal_integration: z.object({
          status: z.string(),
          use_integration: z.boolean(),
        }),
        codabox_integration: z.object({
          can_activate: z.boolean(),
          active: z.boolean(),
          codabox_token: z.string().nullable(),
        }),
        ponto_integration: z.object({
          can_activate: z.boolean(),
          active: z.boolean(),
          synced: z.boolean(),
          ponto_client_id: z.string().nullable(),
          ponto_client_secret: z.string().nullable(),
        }),
        testing_period: z.boolean(),
        invoice_customization: InvoiceCustomizationSchema,
        display_qr_code: z.string(),
        generate_invoice_in_debtor_locale: z.string(),
        company_web_credentials: z.object({
          login: z.string().nullable(),
          password: z.string().nullable(),
        }),
        reminders_to_send_count: z
          .object({
            next_five_days: z.number().optional(),
            next_work_day: z.number().optional(),
          })
          .optional(),
      }),
    }),
  })
  .transform(({ data }) => ({
    ...data.attributes,
    id: data.id,
    terms_and_conditions_attributes: data.attributes.terms_and_conditions,
    custom_variables_attributes: data.attributes.custom_variables,
    address_attributes: data.attributes.address,
    bank_accounts_attributes: data.attributes.bank_accounts,
    phones_attributes: data.attributes.phones,
    sending_media_attributes: data.attributes.sending_media,
    isFrenchClient: data.attributes.address?.country_code === 'FR',
  }));

export type CompanyConfiguration = z.infer<typeof CompanyConfigurationSchema>;
export type BankAccount = z.infer<typeof BankAccountSchema>;
export type InvoiceCustomization = z.infer<typeof InvoiceCustomizationSchema>;
export type RecoveryPlanV2 = z.infer<typeof RecoveryPlanSchema>;
export type DefaultTemplate = z.infer<typeof DefaultTemplateSchema>;

export async function loadCompanyConfigurationQueryFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;
  const { data } = await instance.get('/company_configurations');

  return CompanyConfigurationSchema.parse(data);
}

type CompanyError = AxiosError<{
  error_message?: string;
  next_step?: Tenant['onboarding']['currentStep'];
}>;

export function useLoadCompanyConfiguration() {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<CompanyConfiguration, CompanyError>({
    queryKey: ['company-configuration'],
    queryFn: () => loadCompanyConfigurationQueryFn(axiosInstance),
  });

  return addResourceNameToQueryResult<CompanyConfiguration, CompanyError, 'company'>(
    'company',
    queryResult,
  ) as Omit<UseResourceQueryResult<CompanyConfiguration, CompanyError, 'company'>, 'company'> & {
    company: NonNullable<
      UseResourceQueryResult<CompanyConfiguration, CompanyError, 'company'>['company']
    >;
  };
}
