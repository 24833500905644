import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { FinancialData } from 'shared/hooks/use-load-dashboard';
import { Panel } from 'shared/layout';
import { formatBankAccount } from 'shared/utils';

import { Select, Stack, Text, Title } from '@mantine/core';
import { IconBuildingBank } from '@tabler/icons-react';

import BankStatusAmount from './BankStatusAmount';

type Props = {
  data: FinancialData;
};

export default function BankStatus({ data }: Props) {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const form = useForm({ shouldUnregister: true });

  const { saldo, saldoOneWeek, saldoOneMonth } = data;

  const balance = saldo[form.getValues().bank_accounts] ?? saldo.total;
  const { currency } = company;

  const formatIban = (iban: string) =>
    `${iban.substring(0, 4)}****${iban.substring(iban.length - 4)}`;

  return (
    <Panel
      topRight={
        company.bank_accounts_attributes.length > 1 && (
          <Select
            mb="space3"
            w={235}
            placeholder="Tous les comptes"
            clearable
            leftSection={<IconBuildingBank />}
            onChange={(value) => form.setValue('bank_accounts', value)}
            data={company.bank_accounts_attributes.map(({ iban, id }) => ({
              value: id.toString(),
              label: formatBankAccount(formatIban(iban)),
            }))}
          />
        )
      }
      h="100%"
      title={t(i18nKeys.DASHBOARD.BANK_STATUS.TITLE)}
    >
      <Stack>
        <Stack gap={0}>
          <Text>{t(i18nKeys.BALANCE)}</Text>
          <Title c="validation.7" order={4}>
            <BankStatusAmount data={balance} currency={currency} />
          </Title>
        </Stack>
        <Stack gap="0">
          <Text>{t(i18nKeys.DASHBOARD.BANK_STATUS.WEEK_FORECAST)}</Text>
          <Title c="validation.7" order={4}>
            <BankStatusAmount data={saldoOneWeek} currency={currency} />
          </Title>
        </Stack>
        <Stack gap="0">
          <Text>{t(i18nKeys.DASHBOARD.BANK_STATUS.MONTH_FORECAST)}</Text>
          <Title c="validation.7" order={4}>
            <BankStatusAmount data={saldoOneMonth} currency={currency} />
          </Title>
        </Stack>
      </Stack>
    </Panel>
  );
}
