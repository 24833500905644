import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import AYSModal from 'shared/components/AYSModal';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { DateSelector, SwitchInput } from 'shared/io';
import { DialogShowId, showDialog } from 'store/view/view.actions';

import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName, IntegrationFormProps } from '../Integration.constants';
import { getBillitConnectUrl, getIntegrationRights } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum BillitFormFieldsName {
  IMPORT_INTEGRATION = 'use_billit_import_integration',
  PAYMENT_MATCHING = 'use_billit_payment_matching',
}

export type BillitFormFields = {
  [BillitFormFieldsName.IMPORT_INTEGRATION]: boolean;
  [BillitFormFieldsName.PAYMENT_MATCHING]: boolean;
  [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: Date | null;
  [IntegrationFormFieldsName.DEBTOR_UPDATE]: boolean;
  [IntegrationFormFieldsName.AUTOMATIC_SYNC]: boolean;
};

export type BillitFormProps = IntegrationFormProps<BillitFormFields>;

export const BillitForm = ({ onSubmit, onboarding, initialValues }: BillitFormProps) => {
  const { t, currentLang } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const [preventSubmit, setPreventSubmit] = useState<boolean>(false);

  const { isConnected } = getIntegrationRights(Integration.BILLIT, company);

  const form = useForm<BillitFormFields>({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const { handleSubmit, control, watch, setValue } = form;

  const submit = (values) => {
    if (preventSubmit) {
      showDialog({
        id: DialogShowId.CONFIRM,
        title: t(i18nKeys.CONFIRM),
        children: (
          <AYSModal
            text={t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE)}
            onConfirm={() => {
              setPreventSubmit(false);
              onSubmit(values);
            }}
          />
        ),
      });
    } else {
      onSubmit(values);
    }
  };

  const debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
  const importIntegration = watch(BillitFormFieldsName.IMPORT_INTEGRATION);

  useEffect(() => {
    if (importIntegration) {
      setValue(
        IntegrationFormFieldsName.ISSUE_DATE_LIMIT,
        initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          : new Date(),
      );
    }
  }, [importIntegration, initialValues, setValue]);

  if (!isConnected) {
    return (
      <IntegrationForm<BillitFormFields>
        integration={Integration.BILLIT}
        onSubmit={handleSubmit(submit)}
        summary={i18nKeys.SETTINGS.INTEGRATIONS.BILLIT.SUMMARY}
        connectUrl={getBillitConnectUrl(currentLang as string)}
        form={form}
      />
    );
  }

  return (
    <IntegrationForm<BillitFormFields>
      integration={Integration.BILLIT}
      onSubmit={handleSubmit(submit)}
      summary={i18nKeys.SETTINGS.INTEGRATIONS.BILLIT.SUMMARY}
      form={form}
    >
      <div className={styles('grid-row')}>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={BillitFormFieldsName.IMPORT_INTEGRATION}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                  text: Integration.BILLIT,
                })}
              />
            )}
          />
          {importIntegration && (
            <>
              <div className={styles('col-12')}>
                <DateSelector
                  name={IntegrationFormFieldsName.ISSUE_DATE_LIMIT}
                  className={styles('issue-date')}
                  noMinDate
                  label={t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT)}
                  placeholder={t(i18nKeys.ISSUE_DATE)}
                  endPlaceholder={t(i18nKeys.FORM.TO)}
                  required
                  handleChange={(date: Date) => {
                    setPreventSubmit(true);
                    setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                  }}
                />
              </div>
              <div className={styles('col-12')}>
                <Controller
                  control={control}
                  name={IntegrationFormFieldsName.DEBTOR_UPDATE}
                  render={({ field: { ref, ...values } }) => (
                    <SwitchInput
                      {...values}
                      className={styles('switch-button', 'issue-date')}
                      label={t(
                        debtorUpdate
                          ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                          : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR,
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={BillitFormFieldsName.PAYMENT_MATCHING}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                  text: Integration.BILLIT,
                })}
              />
            )}
          />
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={IntegrationFormFieldsName.AUTOMATIC_SYNC}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC)}
              />
            )}
          />
        </div>
      </div>
    </IntegrationForm>
  );
};
