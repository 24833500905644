import { useDeleteContactPerson, useUpdateContactPerson } from 'api';
import { ContactPerson, CreateContactPersonBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';
import { TableItemProps } from 'types/table';

import { Badge } from '@mantine/core';

import { ContactPersonDetails } from '../ContactPersonDetails/ContactPersonDetails';
import { ContactPersonForm } from '../ContactPersonForm/ContactPersonForm';

import styleIdentifiers from './ContactPersonsList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ContactPersonsListItemProps = TableItemProps<ContactPerson> & {
  debtor: Debtor;
  refetch: () => void;
};

export const ContactPersonsListItem = ({
  item: contactPerson,
  debtor,
  refetch,
}: ContactPersonsListItemProps) => {
  const { t } = useTranslation();

  const { mutate: updateContactPersonMutation } = useUpdateContactPerson();
  const { mutate: deleteContactPersonMutation } = useDeleteContactPerson();

  const {
    id,
    attributes: { main_contact, first_name, last_name, notifications, job_title },
  } = contactPerson;

  const showContactPerson = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      children: <ContactPersonDetails contactPerson={contactPerson} />,
      title: `${first_name} ${last_name}`,
    });
  };

  const updateContactPerson = (e) => {
    e.stopPropagation();

    sideMenuShow({
      unmount: true,
      content: (
        <ContactPersonForm
          contactPerson={contactPerson}
          onSubmit={(data: CreateContactPersonBody) => {
            updateContactPersonMutation(
              { id: debtor.id, contactPersonId: id, data },
              {
                onSuccess: () => {
                  refetch();
                  sideMenuHide();
                },
              },
            );
          }}
        />
      ),
    });
  };

  const deleteContactPerson = (e) => {
    e.stopPropagation();

    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_CONTACT_PERSON)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteContactPersonMutation(
              { id: debtor.id, contactPersonId: id },
              {
                onSuccess: () => {
                  dialogHide(DialogShowId.CONFIRM);
                  refetch();
                },
              },
            )
          }
        />
      ),
    });
  };

  return (
    <tr className={styles('item')} onClick={showContactPerson}>
      <td>{main_contact && <Badge>{t(i18nKeys.MAIN)}</Badge>}</td>
      <td>
        {first_name} {last_name}
      </td>
      <td>{job_title}</td>
      <td>
        {notifications?.length
          ? notifications.map((notification) => (
              <Badge key={notification}>
                {t(i18nKeys.CONTACT_PERSON.NOTIFICATIONS[notification.toUpperCase()])}
              </Badge>
            ))
          : '-'}
      </td>
      <td className={styles('actions')}>
        <span onClick={updateContactPerson}>
          <Icon name={IconName.SETTINGS_GEAR} size="18px" className={styles('action')} />
        </span>
        <span onClick={deleteContactPerson}>
          <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
        </span>
      </td>
    </tr>
  );
};
