import React from 'react';

import { Card, CardProps, Group, Title } from '@mantine/core';

interface PanelProps
  extends CardProps,
    React.PropsWithChildren<{
      title: string;
      topRight?: React.ReactNode;
    }> {}

export const Panel = ({ title, topRight, children, ...rest }: PanelProps) => (
  <Card p="space6" {...rest} style={{ ...rest.style, borderRadius: 16 }}>
    <Card.Section inheritPadding py="space6">
      <Group justify="space-between">
        <Title fw={400} order={6}>
          {title}
        </Title>
        {topRight != null && topRight}
      </Group>
    </Card.Section>
    {children}
  </Card>
);
