import { useProfile } from 'shared/hooks';
import apiService from 'shared/service/api.service';

import { Avatar, Center, Menu, Text, useMantineTheme } from '@mantine/core';

export const TenantSwitcher = () => {
  const profile = useProfile();
  const theme = useMantineTheme();

  return (
    <>
      {profile.accessibleTenants.map((tenant) => {
        const isSelected = tenant.subdomain === apiService.tenant;

        return (
          <Menu.Item
            key={tenant.id}
            onClick={() => window.location.assign(tenant.url)}
            leftSection={
              <Avatar radius={6} size={24} src={tenant.logo} name={tenant.companyName} />
            }
            styles={isSelected ? { item: { backgroundColor: theme.colors.purple[1] } } : undefined}
          >
            {isSelected ? (
              <Center inline>
                <Text>{tenant.companyName}</Text>
                <Text ml="space1" c="purple.4">
                  · Connecté
                </Text>
              </Center>
            ) : (
              <Text>{tenant.companyName}</Text>
            )}
          </Menu.Item>
        );
      })}
    </>
  );
};
