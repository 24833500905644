import React from 'react';
import { NavBar } from 'modules/Navigation';
import { useHistory } from 'react-router';
import { useIsMobile } from 'shared/hooks/utils';

import { AppShell } from '@mantine/core';
import { useDisclosure, useHotkeys, useLocalStorage } from '@mantine/hooks';

import { useTenant } from '../TenantValidator';

import { WarningBanners } from './WarningBanners';

interface PrivateRoutesWrappedProps extends React.PropsWithChildren<{}> {}

export const PrivateRoutesWrapper = ({ children }: PrivateRoutesWrappedProps) => {
  const tenant = useTenant();

  const history = useHistory();
  const isMobile = useIsMobile();
  const [isMobileOpened, { close: closeMobile, open: openMobile }] = useDisclosure();
  const [isDesktopOpened, setIsDesktopOpened] = useLocalStorage({
    key: 'open-navbar',
    defaultValue: true,
  });

  const toggleDesktop = () => {
    setIsDesktopOpened((state) => !state);
  };

  useHotkeys([
    ['mod+B', toggleDesktop],
    ['mod+H', () => history.push('/dashboard')],
  ]);

  return (
    <AppShell
      padding="xl"
      navbar={{
        width: 258,
        breakpoint: 'mobile',
        collapsed: { mobile: !isMobileOpened, desktop: !isDesktopOpened },
      }}
      styles={{
        navbar: { zIndex: 10 },
        main: { zIndex: 0 },
      }}
    >
      {tenant.onboarding.isComplete && (
        <AppShell.Navbar>
          <NavBar
            onClickOpenNavbar={openMobile}
            closeNavbarOnMobile={isMobile ? closeMobile : () => {}}
          />
        </AppShell.Navbar>
      )}
      <AppShell.Main style={{ overflowY: 'auto', height: '100vh', paddingTop: '20px' }} bg="gray.0">
        <WarningBanners />
        {children}
      </AppShell.Main>
    </AppShell>
  );
};
