import { useEffect } from 'react';
import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import { BankAccount, useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, CustomSelect, Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import { accountActions } from 'store/account/account.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { AVAILABLE_CURRENCIES } from 'types/currency';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './CompanySettingsForm.module.scss';
import { Title } from '@mantine/core';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
};

export default function CompanySettingsForm({ onSubmit }: Props) {
  const { t, currentLang } = useTranslation();
  const { company, isCompanyFetching } = useLoadCompanyConfiguration();

  useEffect(() => {
    if (!isCompanyFetching) reset(company);
  }, [isCompanyFetching]);

  const form = useForm({
    defaultValues: { ...company, dirty: 0 },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
    watch,
    getValues,
    setValue,
  } = form;

  const submit = (callback) => {
    handleSubmit(onSubmit(callback))();
  };

  const submitReset = (value) => {
    onSubmit()(value);
  };

  const resetSecretId = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.SETTINGS.COMPANY.RESET_SECRET_ID),
      children: (
        <AYSModal
          text={t(i18nKeys.SETTINGS.COMPANY.AYS_RESET_SECRET_ID)}
          onConfirm={() =>
            accountActions.resetSecretId({
              callback: ({ secret }) => {
                showDialog({
                  id: DialogShowId.CUSTOM,
                  size: DialogShowSize.SMALL,
                  title: t(i18nKeys.SETTINGS.COMPANY.RESET_SECRET_ID),
                  children: (
                    <div className={styles('new-secret-id')}>
                      <div className={styles('message')}>
                        <Icon name={IconName.ALERT_CIRCLE} />{' '}
                        {t(i18nKeys.SETTINGS.COMPANY.NOT_SHOW_SECRET_ID_ANYMORE)}
                      </div>
                      <div>
                        {t(i18nKeys.SETTINGS.COMPANY.SECRET_ID)} : {secret}
                      </div>
                    </div>
                  ),
                });
              },
            })
          }
        />
      ),
    });
  };

  const {
    bank_accounts_attributes,
    company_bank_accounts,
    default_bank_account_id,
    default_bank_account_type,
    dirty,
  } = watch();
  const setFormDirty = () => {
    setTimeout(() => {
      setValue('dirty', dirty ? dirty + 1 : 1, { shouldDirty: true });
    });
  };

  const addBankAccount = () => {
    const values = getValues();
    reset({
      ...values,
      company_bank_accounts: [
        ...(values.company_bank_accounts || []),
        { iban: '', bic: '' },
      ] as any,
    });
    setFormDirty();
  };

  const deleteBankAccount = (index) => () => {
    const bankAccounts = company_bank_accounts?.slice();
    bankAccounts?.splice(index, 1);
    reset(
      {
        ...getValues(),
        company_bank_accounts: bankAccounts,
      },
      { keepTouched: true, keepDirty: true },
    );
    setFormDirty();
  };
  const changeDefaultBankAccount = (bankAccount: BankAccount) => () => {
    if (
      default_bank_account_id !== Number(bankAccount.id) ||
      default_bank_account_type !== bankAccount.type
    ) {
      setValue('default_bank_account_id', Number(bankAccount.id), {
        shouldDirty: true,
      });
      setValue('default_bank_account_type', bankAccount.type, {
        shouldDirty: true,
      });
    }
  };

  register('default_bank_account_id');
  register('dirty');

  const descriptionText = t(i18nKeys.SETTINGS.COMPANY.BANK_ACCOUNT_DESCRIPTION);

  const countriesForSelect = Object.entries(countries.getNames(currentLang)).map(
    ([value, description]) => ({ value, description }),
  );

  return (
    <FormProvider {...form}>
      <NavigationPrompt when={isDirty} onSaveAndQuit={submit} />
      <form className={styles('company-settings-form')} onSubmit={handleSubmit(submitReset)}>
        <div className={styles('wrapper')}>
          <div className={styles('content')}>
            <div className={styles('grid-row', 'data-inputs')}>
              <div className={styles('col-6')}>
                <Input
                  register={register('name', { required: true })}
                  errorMessage={errors.name}
                  label={t(i18nKeys.FORM.COMPANY.LEGAL_NAME)}
                  className={styles('input')}
                  noMargin
                />
              </div>
              <div className={styles('col-6')}>
                <Input
                  register={register('company_email', {
                    required: true,
                    validate: { email },
                  })}
                  errorMessage={errors.company_email}
                  className={styles('input')}
                  type="email"
                  label={t(i18nKeys.EMAIL)}
                  noMargin
                />
              </div>
              <div className={styles('col-6')}>
                <Input
                  register={register('address_attributes.street_name', {
                    required: true,
                  })}
                  errorMessage={(errors.address_attributes as any)?.street_name}
                  type="text"
                  label={t(i18nKeys.FORM.ADDRESS.STREET)}
                  className={styles('input')}
                />
              </div>
              <div className={styles('col-1_5')}>
                <Input
                  register={register('address_attributes.street_number', {
                    required: true,
                  })}
                  errorMessage={(errors.address_attributes as any)?.street_number}
                  label={t(i18nKeys.NUMBER)}
                  noErrorText
                  type="text"
                  className={styles('input')}
                />
              </div>
              <div className={styles('col-1_5')}>
                <Input
                  register={register('address_attributes.street_box')}
                  label={t(i18nKeys.FORM.ADDRESS.BOX)}
                  className={styles('input')}
                  type="text"
                />
              </div>
              <div className={styles('col-3')}>
                <Input
                  register={register('address_attributes.zip_code')}
                  className={styles('input')}
                  type="text"
                  label={t(i18nKeys.FORM.ADDRESS.POSTAL_CODE)}
                />
              </div>
              <div className={styles('col-6', 'col-city')}>
                <Input
                  register={register('address_attributes.city', {
                    required: true,
                  })}
                  errorMessage={(errors.address_attributes as any)?.city}
                  label={t(i18nKeys.FORM.ADDRESS.CITY)}
                  className={styles('input')}
                  type="text"
                />
              </div>
              <div className={styles('col-6')}>
                <Controller
                  name="address_attributes.country_code"
                  rules={{ required: true }}
                  render={() => (
                    <CustomSelect
                      name="address_attributes.country_code"
                      keyText="description"
                      keyValue="value"
                      filter
                      items={countriesForSelect}
                      label={t(i18nKeys.FORM.ADDRESS.COUNTRY)}
                    />
                  )}
                />
              </div>
              <div className={styles('col-6')}>
                <Input
                  register={register('phones_attributes.0.number')}
                  type="text"
                  label={t(i18nKeys.PHONES)}
                />
              </div>
              <div className={styles('col-6')}>
                <Controller
                  rules={{ required: true }}
                  name="locale"
                  render={() => (
                    <CustomSelect
                      name="locale"
                      items={AVAILABLE_LANGUAGES}
                      keyText="description"
                      keyValue="value"
                      label={t(i18nKeys.FORM.COMPANY.LANG)}
                    />
                  )}
                />
              </div>
              <div className={styles('col-6')}>
                <Controller
                  rules={{ required: true }}
                  name="currency"
                  render={() => (
                    <CustomSelect
                      name="currency"
                      items={AVAILABLE_CURRENCIES as any}
                      keyText="description"
                      keyValue="value"
                      label={t(i18nKeys.FORM.COMPANY.CURRENCY)}
                    />
                  )}
                />
              </div>
              <div className={styles('col-6')}>
                <Controller
                  rules={{ required: true }}
                  name="currency_conversion_date"
                  render={() => (
                    <CustomSelect
                      name="currency_conversion_date"
                      items={[
                        {
                          description: "Date d'émission",
                          value: 'ISSUE_DATE',
                        },
                        {
                          description: 'Date du jour',
                          value: 'CURRENT_DATE',
                        },
                      ]}
                      keyText="description"
                      keyValue="value"
                      label={t(i18nKeys.FORM.COMPANY.CURRENCY_CONVERSION_DATE)}
                    />
                  )}
                />
              </div>
              <div className={styles('col-6')}>
                <Input
                  register={register('vat_number', { required: true })}
                  errorMessage={errors.vat_number}
                  label={t(i18nKeys.VAT_NUMBER)}
                  disabled={company.vat_number !== null}
                  type="text"
                  className={styles('input')}
                />
              </div>
              <div className={styles('subtitle')} style={{ paddingLeft: '10px' }}>
                <Title mr="space2" order={4}>{t(i18nKeys.BANK_ACCOUNTS)}</Title>
                <div>
                  <Icon name={IconName.PLUS} size="14px" onClick={addBankAccount} />
                </div>
              </div>
              <div className={styles('info')} style={{ paddingLeft: '10px' }}>
                {descriptionText.substring(0, descriptionText.indexOf('$'))}
                <Icon name={IconName.STAR_FULL} />
                {descriptionText.substring(descriptionText.indexOf('}') + 1)}
              </div>
              <div className={styles('col-12', 'bank-accounts')}>
                {bank_accounts_attributes?.map((bankAccount, index) => (
                  <div key={bankAccount.id} className={styles('bank-account')}>
                    <Input
                      label="IBAN"
                      disabled
                      register={register(`bank_accounts_attributes.${index}.iban`)}
                    />{' '}
                    <Input
                      label="BIC"
                      disabled
                      register={register(`bank_accounts_attributes.${index}.bic`)}
                    />
                    <input
                      {...register(`bank_accounts_attributes.${index}.id`)}
                      className={styles('invisible-input')}
                      disabled
                    />
                    <div className={styles('bank-account-actions')}>
                      {default_bank_account_id === Number(bankAccount.id) &&
                      default_bank_account_type === bankAccount.type ? (
                        <Icon name={IconName.STAR_FULL} />
                      ) : (
                        <Icon
                          name={IconName.STAR_EMPTY}
                          onClick={changeDefaultBankAccount(bankAccount)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles('col-12', 'bank-accounts')}>
                {company_bank_accounts?.map((bankAccount, index) => (
                  <div key={index} className={styles('bank-account')}>
                    <input
                      {...register(`company_bank_accounts.${index}.id`)}
                      className={styles('invisible-input')}
                      readOnly
                    />
                    <Input
                      label="IBAN"
                      errorMessage={errors?.company_bank_accounts?.[index]?.iban}
                      register={register(`company_bank_accounts.${index}.iban`, {
                        required: true,
                      })}
                    />

                    <Input label="BIC" register={register(`company_bank_accounts.${index}.bic`)} />
                    <div className={styles('bank-account-actions')}>
                      {(() => {
                        if (company.banking_method === 'no_bank') {
                          return Number(bankAccount.id) === default_bank_account_id ? (
                            <Icon name={IconName.STAR_FULL} />
                          ) : (
                            <>
                              {company_bank_accounts[index].id && (
                                <Icon
                                  name={IconName.STAR_EMPTY}
                                  onClick={changeDefaultBankAccount(bankAccount)}
                                />
                              )}
                              <Icon
                                name={IconName.TRASH_SIMPLE}
                                onClick={deleteBankAccount(index)}
                              />
                            </>
                          );
                        }

                        return (
                          <Icon name={IconName.TRASH_SIMPLE} onClick={deleteBankAccount(index)} />
                        );
                      })()}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {company.api.client_id && (
            <div className={styles('content', 'api')}>
              <div className={styles('grid-row', 'data-inputs')}>
                <Title mt="space6" order={4} style={{ paddingLeft: '10px' }}>API</Title>
                <div className={styles('col-6')}>
                  <div className={styles('label')}>
                    <Input
                      register={undefined}
                      disabled
                      label={t(i18nKeys.SETTINGS.CLIENT_ID)}
                      className={styles('input')}
                      value={company.api.client_id}
                    />
                  </div>
                </div>
                <div className={styles('col-6')}>
                  <div className={styles('label')}>
                    <Input
                      register={undefined}
                      disabled
                      label={t(i18nKeys.SETTINGS.COMPANY.SECRET_ID)}
                      className={styles('input')}
                      value="*************"
                    />
                    <Button
                      small
                      color={ButtonColor.GREY}
                      noShadow
                      noMargin
                      label={t(i18nKeys.RESET)}
                      onClick={resetSecretId}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles('button-wrapper')}>
            <Button disabled={!isDirty} label={t(i18nKeys.SAVE)} type="submit" />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
