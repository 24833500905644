import React from 'react';
import { motion } from 'framer-motion';

import { Center } from '@mantine/core';

import Logo from './Logo';

type LoadingPlaceholderProps = {
  isActive: boolean;
  size?: 'medium' | 'small';
};

export function LoadingPlaceholder({ isActive, size = 'medium' }: LoadingPlaceholderProps) {
  if (!isActive) return null;

  const dimensions: Record<'medium' | 'small', number> = {
    small: 30,
    medium: 75,
  };

  return (
    <Center>
      <motion.div
        style={{ height: dimensions[size], width: dimensions[size] }}
        animate={{
          rotate: 360 * 3,
          transition: {
            repeat: Infinity,
            duration: 4,
            ease: [0.38, 0.89, 0.83, 0.37],
          },
        }}
      >
        <Logo width={dimensions[size]} height={dimensions[size]} />
      </motion.div>
    </Center>
  );
}
